/**
 * @author mmt8760
 * Factory function for creating Publisher/Subscriber
 * @return {Object} Object containing pulish and subscribe methods
 */
const pubSub = () => {
	const subscribers = {};

	/**
	 * Calls all the subscribe method for a specific event
	 * @param  {string} eventName
	 * @param  {any} data
	 */
	const publish = (eventName, data) => {
		if (!Array.isArray(subscribers[eventName])) {
			return;
		}
		subscribers[eventName].forEach((callback) => {
			callback(data);
		});
	};
	/**
	 * Register callback for eventName
	 * @param  {string} eventName
	 * @param  {function} callback
	 * @return {object} Object containing unsubscribe method
	 */
	const subscribe = (eventName, callback) => {
		if (!Array.isArray(subscribers[eventName])) {
			subscribers[eventName] = [];
		}
		subscribers[eventName].push(callback);
		const index = subscribers[eventName].length - 1;

		return {
			unsubscribe() {
				subscribers[eventName].splice(index, 1);
			}
		};
	};

	return {
		publish,
		subscribe
	};
};

export default pubSub;
