import React, { Component } from 'react';
import { Button, IconSpan, ButtonIcon } from './ButtonStyles';
import { Loader } from '../Styles';

class buttonComp extends Component {
	constructor(props) {
		super(props);
		this.state = {
		};
		this.handleClick = this.handleClick.bind(this);
		this.defaultPrevent = this.defaultPrevent.bind(this);
	}

	handleBtnStyle(name) {
		switch (name) {
			case 'Primary':
				return ' primaryGradientBtnStyle primaryBtnStyle';
			case 'Secondary':
				return ' secondaryBtnStyle';
			case 'Transparent':
				return ' transparentBtnStyle';
			default:
				return ' primaryBtnStyle';
		}
	}

	handleBtnTypeStyle(type) {
		switch (type) {
			case 'Capsule':
				return ' CapsuleBtnStyle';
				break;
			case 'Ellipse':
				return ' EllipseBtnStyle';
				break;
			case 'Flat':
				return ' FlatBtnStyle';
				break;
			default:
				return ' FlatBtnStyle';
		}
	}

	handleClick(e) {
		if (this.props.handleClick) {
			this.props.handleClick(e);
		}
	}

	defaultPrevent(e) {
		e.preventDefault();
	}

	render() {
		const { btnName, btnType, isLoader, height, disableClass, btnTxt, icon, btnClass, type = 'button', isSeoAnchor, seoUrl } = this.props;
		const disableBtnStyle = this.props.disabled ? ( ` disableBtnStyle ${disableClass} `) : '';
		const btnStyle = this.handleBtnStyle(btnName);
		const btnTypeStyle = this.handleBtnTypeStyle(btnType);
		return (
			<Button
				height={height}
				{...this.props}
				type={type}
				onClick={this.handleClick}
				className={( btnStyle + btnTypeStyle + disableBtnStyle + ' ripple  ' + btnClass)}>
				{icon && <IconSpan icon={icon} />}
				{isSeoAnchor ? <a onClick={this.defaultPrevent} href={seoUrl}>{btnTxt}</a> : btnTxt}
				{isLoader && <Loader />}
			</Button>
		);
	}
}
export default buttonComp;
