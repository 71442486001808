import React, { memo, useState, useEffect, useCallback } from 'react';
import { Switch, Route } from 'react-router-dom';

import BizApp from './landingApp';
import BizBenefitDetail from './benefitsDetail';
import { APPLICATION_CONSTANTS, PATHS } from '../AppConstants';
import { getMyBizDetails } from './landingApi';
import MetaTags from 'common/metaTags';

const LandingResource = ({ isScollView,
	updateShowComp,
	visibleComps,
	navClick,
	updateCurrElem,
	params,
	children,
	pageStripName,
	isCorpSeo,
	isSME = false,
	disableMeta = false,
	isSsrData = false,
	additionalSeoData,
	navModalRender,
	isPreLoad }) => {
	const [businessDetails, setbusinessDetails] = useState({});

	const getDetails = useCallback(async () => {
		const response = await getMyBizDetails(isSME ? 'sme' : 'landing');
		setbusinessDetails(response.bizDetails);
	}, [isSME]);

	useEffect(()=>{
		if (!isSsrData) {
			getDetails();
		}
	}, [getDetails]);

	const bizApp = (
		<BizApp
			isScollView={isScollView}
			updateShowComp={updateShowComp}
			visibleComps={visibleComps}
			navClick={navClick}
			isSME={isSME}
			updateCurrElem={updateCurrElem}
			pageStripName={pageStripName}
			isCorpSeo={isCorpSeo}
			businessDetails={businessDetails}
			isPreLoad={isPreLoad}
			additionalSeoData={additionalSeoData}>
			{children}
		</BizApp>
	);

	return (
		<>
			{!disableMeta && <MetaTags titleTxt={APPLICATION_CONSTANTS.pageTitle} />}
			<Switch>

				<Route exact path={PATHS.MYBIZ_BENEFIT}>
					<BizBenefitDetail
						params={params}
					/>
				</Route>
				<Route>
					{bizApp}
				</Route>
			</Switch>
			{navModalRender && navModalRender(businessDetails)}
		</>
	);
};

export default memo(LandingResource);
