import React from 'react';
import loadable from 'react-loadable';

import Loader from '../../../common/Loader/Loader';

export default loadable({
	loader: () => import('./requestDemo' /* webpackChunkName: 'requestDemo' */),
	loading: () => <Loader color='#723b63' />,
	modules: ['requestDemo']
});
