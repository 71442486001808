import React, { useState } from 'react';

import * as S from './FooterStyle';
import { trackClickEvents } from '../../bizLanding/utils';
import { LandingSpriteIcon } from '../../bizLanding/Styles';

const FooterList = ({ showList, index, isMobile, list, trackVal, head, icon, showDot, linkMargin, imgDim, mobDir, borderBottom }) => {
	const [toggle, setToggle] = useState(showList);

	const updateToggle = () => {
		setToggle((toggle) => !toggle);
	};
	return (
		<S.FooterColWrap borderBottom={borderBottom} $width='auto' key={head} directionInMobile='column'>
			{head &&
			<S.FooterHeadWrap
				onClick={updateToggle}
				justify='space-between'>
				<S.FooterLabel><LandingSpriteIcon icon={icon} /><S.HeadSpan>{head}</S.HeadSpan></S.FooterLabel>
				{isMobile && <S.MobileArrow className={toggle ? 'up' : ''} $top='30px' right='30px' />}
			</S.FooterHeadWrap>}
			{(toggle) &&
			<S.LinkWrap mobDir={mobDir} onClick={() => trackClickEvents(trackVal)}>
				{list.map((lnk, i) => (
					<S.FooterItems margin={i == 0 ? 0 : linkMargin} key={lnk.url + lnk.text + i}>
						{!isMobile && showDot && i != 0 && <S.Dot />}
						<a
							href={lnk.url}>{lnk.text}{lnk.img &&
							<img
								$width={(imgDim && imgDim.width) || '128'}
								height={(imgDim && imgDim.height) || '36'}
								src={isMobile && lnk.mobileImg ? lnk.mobileImg : lnk.img}
								alt={lnk.alt}
							/>}
						</a>
					</S.FooterItems>
				))}
			</S.LinkWrap>}
		</S.FooterColWrap>
	);
};

export default FooterList;
