import React from 'react';
import loadable from 'react-loadable';

import Loader from '../Loader/Loader';

export default loadable({
	loader: () => import('./reduxChatbot' /* webpackChunkName: 'reduxChatbot' */),
	loading: () => <Loader color='#723b63' />,
	modules: ['reduxChatbot']
});
