import styled, { css } from 'styled-components';

import { FadeInOut } from 'commonStyles';

export const BuisnessPartnerContainerWrap = styled.section(
	({ theme: { down, breakpoints } }) => `
      background-color: #fff;
			position: relative;
    `
);

export const PartneredCompaniesWrap = styled.div(
	({ theme: { down, breakpoints } }) => `
		width:42%;
		height: 24px;
		.partnerImg {
			padding: 0 20px;
		}
    ${down(breakpoints.md)} {
      width: 100%;
      margin-top: 16px;
    }
    &.slick-initialized &.slick-slide{
      width:120px;
    }
    & .slick-arrow{
      display: none !important;
    }
  `
);

export const BuisnessPartnerAnnouncement = styled.div`
	${(p)=> p.isAnimate && css`animation: ${FadeInOut} ${(p)=>p.time || '2'}s ease-in-out infinite forwards ;`}
	${({ theme: { down, breakpoints } }) => css`
					${down(breakpoints.md)} {
							width: 100%;
							align-items: flex-start;
					}
					display: flex;
					align-items: center;
					width: 50%;
					color: #0c3b39;
					font-size: 16px;
					padding-top: 30px;
					* {
						flex-shrink: 0;
					}
					>p{
						width: 85%
					}
			
				`
}
`;
