import React from 'react';

export const Devices = {
	mobile: 'mobile',
	desktop: 'desktop'
};
const contextName = 'MyScreenView';
const DisplayContext = React.createContext(Devices.desktop);
DisplayContext.displayName = contextName;

export const DisplayProvider = DisplayContext.Provider;
export default DisplayContext;
