import React, { useContext, useState, useEffect, useCallback } from 'react';

import * as S from './FooterStyle';
import { ResponsiveContainer } from 'commonStyles';
import { followUsConfig, downloadConfig, FooterLinks } from '../../bizLanding/config';
import DisplayContext from 'UtilityComponents/displayContext';
import { isMobileContext } from 'UtilityComponents/Util';
import { GenericDiv, FlexDiv } from 'commonStyles';
import FooterList from './FooterList';
import landingContext from '../../bizLanding/landingContext';
import { getConfigData } from '../../bizLanding/landingApi';
import { APPLICATION_CONSTANTS } from 'AppConstants';

const Footer = ({ isPreLoad }) => {
	const { appSsrData } = useContext(landingContext) || {};
	const [footerData, setFooterData] = useState(appSsrData?.footerData || FooterLinks);
	const displayName = useContext(DisplayContext).isMobile;
	const isMobile = isMobileContext(displayName);

	useEffect(() => {
		getDetails();
	}, []);

	const getDetails = useCallback(async () => {
		if (!appSsrData?.footerData?.length) {
			const response = await getConfigData(APPLICATION_CONSTANTS.OPEN_CONFIG_KEYS.SEO);
			const footerData = response?.data;
			const footer = Array.isArray(footerData) ? footerData : FooterLinks;
			setFooterData(footer);
		}
	}, [appSsrData]);

	return (
		<S.FooterWrapper>
			<GenericDiv $bg='#1a1a1a'>
				<ResponsiveContainer
					isNoBTPadd
					wrap='wrap'
					mobileDir='column'
					justify='space-between'
					isFlex='true'>
					{footerData?.length && footerData.map((section, i) => (
						<FooterList
							key={section.head}
							isMobile={isMobile}
							list={section.list}
							trackVal={section.trackVal}
							head={section.head}
							icon={section.icon}
							showDot
							linkMargin='0 2px'
							borderBottom={i < footerData.length - 1 ? '1px solid #4A4A4A' : ''}
							index={i}
							showList={(isPreLoad || !isMobile)}
						/>
					))}
				</ResponsiveContainer>
			</GenericDiv>
			<GenericDiv $bg='#000000'>
				<ResponsiveContainer
					bottom_P='90'
					top_P={isMobile ? '8' : '75'}
					wrap='wrap'
					justify={isMobile ? 'center' : 'space-between'}
					isFlex='true'>
					<FlexDiv $width='auto' directionInMobile='column'>
						<GenericDiv right_M={isMobile ? '' : '100'}>
							<FooterList
								key={followUsConfig.head}
								isMobile={isMobile}
								list={followUsConfig.list}
								trackVal={followUsConfig.trackVal}
								showDot={false}
								linkMargin='0 2px'
								imgDim={followUsConfig.imgDim}
								showList
							/>
						</GenericDiv>
						<FooterList
							key={downloadConfig.head}
							isMobile={isMobile}
							list={downloadConfig.list}
							trackVal={downloadConfig.trackVal}
							showDot={false}
							mobDir='row'
							linkMargin='0 12px'
							showList
						/>
					</FlexDiv>
					<S.FooterSubHead>
						<p>© 2022 MAKEMYTRIP PVT. LTD.</p>
						<p>Country <span>India</span></p>
					</S.FooterSubHead>
				</ResponsiveContainer>
			</GenericDiv>
		</S.FooterWrapper>
	);
};

export default Footer;
