import React, { useCallback, useMemo } from 'react';

import * as S from './HeaderStyles';
import Button from '../Button/Button';
import Modal from '../Modal/Modal';
import { GenericDiv, GenericSpan } from '../Styles';
import { LinkArrow, HeaderLink } from './HeaderStyles';

const MobileMenuHTML = ({ handleModal, headerConfig, headerLinks }) => {
	const ModalData = useMemo(()=>({
		title: '',
		img: null,
		imgstyle: null,
		handleClick: handleModal,
		btnTxt: null,
		customClass: 'mobile-header'
	}), [handleModal]);

	const { onNavClick, menus = [] } = headerConfig;

	const navClick = useCallback((e)=>{
		e.persist();
		handleModal && handleModal();
		setTimeout(() => {
			onNavClick(e);
		});
	}, [handleModal, onNavClick]);

	return (
		<Modal
			Modaldata={ModalData}
			handleClick={handleModal}
			handleModal={handleModal}
			close
			$width='65%'>
			<S.NavigationListMobile directionInMobile='column'>
				{headerLinks.map((navlink) => (
					<HeaderLink
						key={navlink.link}
						data-pageurl={navlink.pageurl}
						data-link={navlink.link}
						justify='space-between'
						align='center'
						onClick={navClick}>
						<GenericSpan fsize='14px' f_weight='700' color='#000'>{navlink.text}
						</GenericSpan>
						<LinkArrow />
					</HeaderLink>
				))}
				<GenericDiv top_M='20'>
					{menus.map((item) => {
						const { btnTxt, btnType, btnName, btnShadow, btnWidth, height, handleClick, bottom_M = 0, show } = item;
						return (
							show ?
								<GenericDiv bottom_M={bottom_M} key={btnName}>
									<Button
										btnTxt={btnTxt}
										btnType={btnType}
										btnName={btnName}
										btnShadow={btnShadow}
										btnWidth={btnWidth}
										height={height}
										handleClick={handleClick}
									/>
								</GenericDiv> :
								null
						);
					})}
				</GenericDiv>
			</S.NavigationListMobile>
		</Modal>
	);
};

export default MobileMenuHTML;
