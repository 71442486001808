export const headerRadioField = [
	{
		name: 'callBackNeedHelpInOptions',
		label: 'Setting up myBiz for the Organisation.',
		value: 'organisation',
		checked: true
	},
	{
		name: 'callBackNeedHelpInOptions',
		label: 'Understanding myBiz features for corporates.',
		value: 'corporates',
		checked: false
	},
	{
		name: 'callBackNeedHelpInOptions',
		label: 'Getting the GST invoices for the bookings.',
		value: 'bookings',
		checked: false
	},
	{
		name: 'callBackNeedHelpInOptions',
		label: 'Others',
		value: 'others',
		checked: false
	}
];

export const expenseRadioFields = [
	{
		name: 'callBackNeedHelpInOptions',
		label: 'Defining categories.',
		value: 'categories',
		checked: true
	},
	{
		name: 'callBackNeedHelpInOptions',
		label: 'Setting up expense policies for my organisation ',
		value: 'policies',
		checked: false
	},
	{
		name: 'callBackNeedHelpInOptions',
		label: 'Understanding how to set up Expense management tool for my organisation.',
		value: 'management',
		checked: false
	},
	{
		name: 'callBackNeedHelpInOptions',
		label: 'Others',
		value: 'others',
		checked: false
	}
];

export const landingRadioFields = [
	{
		name: 'callBackNeedHelpInOptions',
		label: 'Setting up myBiz for the organisation',
		value: 'setorganisation',
		checked: true
	},
	{
		name: 'callBackNeedHelpInOptions',
		label: 'Understanding myBiz features for corporates ',
		value: 'features',
		checked: false
	},
	{
		name: 'callBackNeedHelpInOptions',
		label: 'Getting the GST invoices for the bookings  ',
		value: 'gst',
		checked: false
	},
	{
		name: 'callBackNeedHelpInOptions',
		label: 'Others',
		value: 'others',
		checked: false
	}
];

export const employeeRadioFields = [
	{
		name: 'callBackNeedHelpInOptions',
		label: `Integrating with my organisation's HRMS tool`,
		value: 'integratehrms',
		checked: true
	},
	{
		name: 'callBackNeedHelpInOptions',
		label: 'understanding what an HRMS tool is',
		value: 'hrmsunderstanding',
		checked: false
	},
	{
		name: 'callBackNeedHelpInOptions',
		label: 'Others',
		value: 'others',
		checked: false
	}
];

export const whatsappDetails = {
	phone: '+91 9717227149',
	qrCode: 'https://promos.makemytrip.com/images/myBiz/mybiz_whatsapp_QR.png'
};

export const defaultRadioFields = [
	{
		name: 'callBackNeedHelpInOptions',
		label: 'Others',
		value: 'others',
		className: 'elem-hidden',
		checked: true
	}
];
