import React, { useCallback, useContext } from 'react';

import Button from '../../../common/Button/Button';
import { isMobileContext } from '../../../UtilityComponents/Util';
import DisplayContext from '../../../UtilityComponents/displayContext';
import { GET_STARTED_LINK, SIGNUP_BTN_TEXT } from '../../config';
import ActionButtons from '../actionButtons/actionButtons';
import { LANDING_CONSTS } from '../../../AppConstants';

const GetStartedButton = ({ navClick, zIndex }) => {
	const displayName = useContext(DisplayContext).isMobile;
	const isMobile = isMobileContext(displayName);

	const clickGetStartedUp = useCallback((e)=>{
		e.preventDefault();
		const event = {
			target: {
				dataset: {
					...GET_STARTED_LINK
				}
			}
		};
		navClick && navClick(event);
	}, [navClick]);

	return (
		<ActionButtons zIndex={zIndex} $width='42%' show={LANDING_CONSTS.SIGNUP} signupTxt={SIGNUP_BTN_TEXT.loggedin} />
	);
};

export default GetStartedButton;
