const globalData = require('./globalDataFactory');

const getConsulData = (key) => {
	if (typeof global !== 'undefined') {
		const consulDataJSON = (global.consulData) || {};
		return (consulDataJSON[key] || {});
	}
	if (typeof window !== 'undefined') {
		const consulData = globalData.getProperty('consulData') || globalData.setProperty('consulData', window.consulData) || window.consulData;
		if (consulData) {
			return key ? consulData[key] : consulData;
		}
	}
	return {};
};

module.exports = { getConsulData };
