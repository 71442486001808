import {
	ASSET_PATH
} from '../../assetsPath';
import theme from '../common/Theme';
import { PATHS } from '../AppConstants';
import {
	validEmail,
	validPhone
} from '../UtilityComponents/Util';

export const landingImgPath = ASSET_PATH.IMAGES + 'landing/';

export const mobileLandingImgPath = ASSET_PATH.IMAGES + 'landing/mobile/';

export const CaseStudiesTempData = {
	heading: 'Case Studies',
	subHeading: 'myBiz powers many of the small, medium & large size organisations to help them save on their travel budget and achieve more from their business travels. Read interesting stories from these organisations.',
	list: [{
		company: 'Sattva Consulting, Delhi',
		title: 'Helping Sattva Consulting Deliver High Impact Everyday',
		desp: 'myBiz enabled visibility into the booking process for Sattva at every touchpoint and helped save extra costs incurred.',
		userName: 'PR Murli'
	},
	{
		company: 'Bajaj Finserv, Bangalore',
		title: 'Saving travel budget was a nightmare before we met myBiz.',
		desp: 'myBiz enabled visibility into the booking process for Sattva at every touchpoint and helped save extra costs incurred.',
		userName: 'Ayaj Immanuel'
	},
	{
		company: 'Bajaj Finserv, Bangalore',
		title: 'Saving travel budget was a nightmare before we met myBiz.',
		desp: 'myBiz enabled visibility into the booking process for Sattva at every touchpoint and helped save extra costs incurred.',
		userName: 'Ayaj Immanuel'
	}
	]
};

export const TestimonialData = [{
	imgUrl: 'https://www.tripmoney.com/ext/static/PL/pl.png',
	desp: 'I have been using myBiz for our company’s air ticket requirement for a while now. It is useful, beneficial and convinient. We will be using it in future also.',
	userName: 'PR Murli',
	userCompany: 'High Tech System & Services'
}];

export const GettingStartedData = {
	heading1: 'Don\'t Wait Anymore.',
	heading2: 'Get Started in Just 3 Steps',
	list: [{
		id: '1',
		heading: 'Create Your Account Now',
		descp: 'Get started by providing minimal details like employee size, organisation name, etc.'
	},
	{
		id: '2',
		heading: 'Set Up Employee-friendly Policy Guidelines ',
		descp: 'Gain most of the benefits by setting up employee-friendly policies (only Admin users). '
	},
	{
		id: '3',
		heading: 'Invite Your Employees & Start Booking',
		descp: 'Invite your employees to myBiz so that they could enjoy all the corporate benefits. '
	}
	]
};

export const offerSectionData = {
	'SPECIAL OFFER': {
		bgColor: theme.colors.pastelGreen,
		bgGradient: theme.gradientColor.lighGreen,
		iconName: 'greenGift'
	},
	'OFFER': {
		bgColor: theme.colors.pastelGreen,
		bgGradient: theme.gradientColor.lighGreen,
		iconName: 'greenGift'
	},
	'NEW PRODUCT UPDATE': {
		bgColor: theme.colors.pastelYellow,
		bgGradient: theme.gradientColor.lightYellow,
		iconName: 'exclaim'
	},
	'UPCOMING FEATURE': {
		bgColor: theme.colors.pastelBlue,
		bgGradient: theme.gradientColor.lightBlue,
		iconName: 'exclaim'
	},
	'NEW PRODUCT': {
		bgColor: theme.colors.pastelYellow,
		bgGradient: theme.gradientColor.lightYellow,
		iconName: 'exclaim'
	},
	'DEFAULT': {
		bgColor: theme.colors.pastelBlue,
		bgGradient: theme.gradientColor.lightBlue,
		iconName: 'exclaim'
	}

};

export const bannerCarouselData = [{
	heading: 'Business Travel Super Simplified',
	subHeading: 'Designed for businesses seeking flexibility and a wide range of choices'
},
{
	heading: 'Built to Ensure Maximum Convenience',
	subHeading: 'Multi-platform booking flow, wide range of myBiz Assured hotel options and easy modifications to meet your last-minute needs'

},
{
	heading: 'Tax Savings with GST Invoices',
	subHeading: 'Assured GST Invoices on flights and myBiz Assured hotels, using which you can easily claim your tax refunds'

},
{
	heading: 'Save on Company Travel Expenses',
	subHeading: 'With special corporate rates on flights & hotels, save your travel budget by paying less for more features'

}
];

export const GET_STARTED_LINK = {
	text: 'Getting Started',
	type: 'getStart',
	link: 'GettingStarted',
	trackVal: 'getting started_clicked'
};
export const NavigationLinks = [
	{
		text: 'Why myBiz?',
		type: 'whyBiz',
		pageurl: PATHS.RESOURCE_PAGE,
		link: '',
		trackVal: 'whymyBiz_clicked',
		modalType: 'whyMyBiz',
		subLinks: [
			{
				text: 'Product Videos',
				link: `${ PATHS.RESOURCE_PAGE}#ProductVideo`
			},
			{
				text: 'myBiz Blogs',
				link: `${PATHS.RESOURCE_PAGE}#MyBizBlog`
			},
			{
				text: 'Designed for Every Business',
				link: `${PATHS.RESOURCE_PAGE}#BusinessDesign`
			},
			{
				text: 'Customer Services',
				link: `${PATHS.RESOURCE_PAGE}/#CustomerStories`
			}
		]
	},
	{
		text: 'Expense Management',
		type: 'expns',
		link: '',
		trackVal: 'expense_clicked',
		pageurl: PATHS.EXPENSE_SEO
	},
	{
		text: 'Case Studies',
		type: 'case',
		link: `CaseStudies`,
		trackVal: 'case studies_clicked'
	}
];

export const serverBenefitsListData = {
	heading: 'Designed for Everyone in the Organisation',
	subHeading: 'Our features and benefits are curated to suit the needs of every employee using myBiz.',
	list: [{
		'heading': 'Travel Managers',
		'type': 'TM',
		'subHeading': 'Who manages company\'s travel expense',
		'benefits': [
			'Simplified Admin Panel to manage all employees and their expenses',
			'Detailed reports on regular basis to keep a track on your spends',
			'Dedicated Support Manager from myBiz to take care of your queries'
		]
	},
	{
		'heading': 'Central Bookers',
		'type': 'CB',
		'subHeading': 'Who books for employees',
		'benefits': [
			'Book using the traveller\'s Policy to ensure the right options are booked',
			'Shortlist & share travel options to employees directly from myBiz',
			'Track your requests & get the job done from one single place'
		]
	},
	{
		'heading': 'Employees',
		'type': 'EM',
		'subHeading': 'Who travel for business',
		'benefits': [
			'Special Corporate Fares in flights to ensure you get more benefits at reduced price',
			'myBiz Assured Hotels for top-rated stay options',
			'Sanitized Cabs to safely pick & drop you to your destinations'
		]
	}
	]
};

export const DownloadSectionData = {
	heading: 'Seamless Access from Everywhere',
	subHeading: 'With our super intuitive and connected web application & mobile app, myBiz is always available to address all your business travel needs.',
	actionContent: 'Scan QR code to download myBiz app'
};

export const FooterLinks = [
	{
		'head': 'Business Cities',
		'trackVal': 'business_cities_clicked',
		'icon': 'icBizCities',
		'list': [
			{
				'text': 'Mumbai',
				'url': 'https://mybiz.makemytrip.com/corporate-hotels/mumbai-hotels.html'
			},
			{
				'text': 'Bangalore',
				'url': 'https://mybiz.makemytrip.com/corporate-hotels/bengaluru-hotels.html'
			},
			{
				'text': 'Chennai',
				'url': 'https://mybiz.makemytrip.com/corporate-hotels/chennai-hotels.html'
			},
			{
				'text': 'Pune',
				'url': 'https://mybiz.makemytrip.com/corporate-hotels/pune-hotels.html'
			},
			{
				'text': 'Hyderabad',
				'url': 'https://mybiz.makemytrip.com/corporate-hotels/hyderabad-hotels.html'
			},
			{
				'text': 'Kolkata',
				'url': 'https://mybiz.makemytrip.com/corporate-hotels/kolkata-hotels.html'
			},
			{
				'text': 'Lucknow',
				'url': 'https://mybiz.makemytrip.com/corporate-hotels/lucknow-hotels.html'
			},
			{
				'text': 'Jaipur',
				'url': 'https://mybiz.makemytrip.com/corporate-hotels/jaipur-hotels.html'
			},
			{
				'text': 'Surat',
				'url': 'https://mybiz.makemytrip.com/corporate-hotels/surat-hotels.html'
			},
			{
				'text': 'Bhopal',
				'url': 'https://mybiz.makemytrip.com/corporate-hotels/bhopal-hotels.html'
			},
			{
				'text': 'Goa',
				'url': 'https://mybiz.makemytrip.com/corporate-hotels/goa-hotels.html'
			},
			{
				'text': 'Noida',
				'url': 'https://mybiz.makemytrip.com/corporate-hotels/noida-hotels.html'
			},
			{
				'text': 'Thane',
				'url': 'https://mybiz.makemytrip.com/corporate-hotels/thane-hotels.html'
			},
			{
				'text': 'Varanasi',
				'url': 'https://mybiz.makemytrip.com/corporate-hotels/varanasi-hotels.html'
			}
		]
	},
	{
		'head': 'Blog',
		'trackVal': 'blog_clicked',
		'icon': 'icBlog',
		'list': [
			{
				'text': 'myBiz Reviews',
				'url': 'https://www.makemytrip.com/blog/business-travel-mybiz-testimonials'
			},
			{
				'text': 'GST on Business Travel',
				'url': 'https://www.makemytrip.com/blog/gst-on-business-travel'
			},
			{
				'text': 'Save on Business Travel',
				'url': 'https://www.makemytrip.com/blog/ways-to-save-on-business-travel'
			},
			{
				'text': 'Corporate Travel Platform',
				'url': 'https://www.makemytrip.com/blog/things-to-consider-planning-corporate-travel'
			},
			{
				'text': 'Things to do on Business Trip',
				'url': 'https://www.makemytrip.com/blog/things-to-do-on-business-trips'
			},
			{
				'text': 'Why myBiz ?',
				'url': 'https://www.makemytrip.com/blog/business-travel-mybiz'
			},
			{
				'text': 'Why myBiz Assured ?',
				'url': 'https://www.makemytrip.com/blog/mybiz-assured-hotels-for-business-travel'
			},
			{
				'text': 'Why MSME\'s need myBiz ?',
				'url': 'https://www.makemytrip.com/blog/why-your-msme-needs-mybiz'
			},
			{
				'text': 'Business Travel Planning',
				'url': 'https://www.makemytrip.com/blog/business-travel-planning'
			},
			{
				'text': 'Technology Revolutionizing Business Travel',
				'url': 'https://www.makemytrip.com/blog/technology-revolutionizing-business-travel'
			},
			{
				'text': 'Best Business Hotels in Bangalore',
				'url': 'https://www.makemytrip.com/blog/top-5-business-hotels-in-bangalore'
			},
			{
				'text': 'Best Business Hotels in Delhi',
				'url': 'https://www.makemytrip.com/blog/business-hotels-in-delhi'
			},
			{
				'text': 'Best Business Hotels in Mumbai',
				'url': 'https://www.makemytrip.com/blog/best-business-hotels-near-mumbai-airport'
			},
			{
				'text': 'India\'s Best Business Hotels',
				'url': 'https://www.makemytrip.com/blog/best-luxury-business-hotels-in-india'
			},
			{
				'text': 'Best Business Hotels in Chandigarh',
				'url': 'https://www.makemytrip.com/blog/business-hotels-in-chandigarh'
			}
		]
	},
	{
		'trackVal': 'Quicklinks_clicked',
		'head': 'Quick Links',
		'icon': 'icQuickLinks',
		'list': [
			{
				'text': 'myBiz Customer care',
				'url': 'https://mybiz.makemytrip.com/corporate/mybiz-customer-care.html'
			},
			{
				'text': 'Corporate Travel',
				'url': 'https://mybiz.makemytrip.com/corporate/corporate-travel-management.html'
			},
			{
				'text': 'Business Hotels',
				'url': 'https://mybiz.makemytrip.com/corporate/corporate-hotels-booking.html'
			},
			{
				'text': 'Corporate Flights',
				'url': 'https://mybiz.makemytrip.com/corporate/corporate-flight-booking.html'
			},
			{
				'text': 'Corporate Hotels ',
				'url': 'https://mybiz.makemytrip.com/corporate/corporate-hotel-booking.html'
			},
			{
				'text': 'Business Travel for MSME ',
				'url': 'https://mybiz.makemytrip.com/corporate/business-travel-with-udyam-ngo.html'
			},
			{
				'text': 'GST Invoice for International flights ',
				'url': 'https://mybiz.makemytrip.com/corporate/gst-invoice-international-flights.html'
			},
			{
				'text': 'Business Travel Solutions ',
				'url': 'https://mybiz.makemytrip.com/corporate/business-travel-solutions.html'
			},
			{
				'text': 'GST Invoice for Bus',
				'url': 'https://mybiz.makemytrip.com/corporate/gst-for-bus-travel.html'
			},
			{
				'text': 'Corporate Bus booking ',
				'url': 'https://mybiz.makemytrip.com/corporate/corporate-bus-booking.html'
			},
			{
				'text': 'myBiz - Best Business Travel Platform ',
				'url': 'https://mybiz.makemytrip.com/corporate/best-business-travel-platform.html'
			},
			{
				'text': 'GST Invoice for Flights ',
				'url': 'https://mybiz.makemytrip.com/corporate/gst-invoice-flights.html'
			},
			{
				'text': 'GST Invoice for Travel ',
				'url': 'https://mybiz.makemytrip.com/corporate/gst-bill.html'
			},
			{
				'text': 'GST Invoice for Hotels ',
				'url': 'https://mybiz.makemytrip.com/corporate/hotels-gst-invoice.html'
			},
			{
				'text': 'myBiz for Small Business ',
				'url': 'https://mybiz.makemytrip.com/corporate/travel-for-small-business.html'
			},
			{
				'text': 'Free cancellation on International Flights ',
				'url': 'https://mybiz.makemytrip.com/corporate/corporate-flights-free-cancellation-international.html'
			}
		]
	},
	{
		'trackVal': 'others_clicked',
		'head': 'Others',
		'icon': 'icLegals',
		'list': [
			{
				'text': 'About us',
				'url': 'https://www.makemytrip.com/about-us/company_profile.php'
			},
			{
				'text': 'Privacy Policy',
				'url': 'https://www.makemytrip.com/legal/privacy_policy.html'
			},
			{
				'text': 'User Agreement',
				'url': 'https://www.makemytrip.com/legal/user_agreement.html'
			},
			{
				'text': 'Terms of Services',
				'url': 'https://www.makemytrip.com/legal/user_agreement.html#tos'
			},
			{
				'text': 'Mail us',
				'url': 'mailto:myBizAcquisition@gommt.onmicrosoft.com?subject=myBiz Query'
			}
		]
	}
];

export const followUsConfig = {
	trackVal: 'follow_us_clicked',
	head: 'Follow us',
	icon: 'icFollowUs',
	list: [
		{ alt: 'Linkedin',
			img: ' https://promos.makemytrip.com/images/myBiz/linkedinlogo.png',
			mobileImg: 'https://promos.makemytrip.com/images/myBiz/linkedinlogo.png',
			url: ' https://www.linkedin.com/showcase/mybiz-by-makemytrip/' }
	],
	imgDim: {
		width: '25',
		height: '25'
	}
};

export const downloadConfig = {
	head: 'Download',
	trackVal: 'Download_clicked',
	icon: 'icDownload',
	list: [{
		alt: 'IOS',
		img: landingImgPath + 'applelink.png',
		mobileImg: mobileLandingImgPath + 'applelink.png',
		url: 'https://applinks.makemytrip.com/joinMyBiz'
	},
	{
		alt: 'Android',
		img: landingImgPath + 'androidlink.png',
		mobileImg: mobileLandingImgPath + 'androidlink.png',
		url: 'https://applinks.makemytrip.com/joinMyBiz'
	}
	]
};

export const downloadFormFields = {
	emailField: {
		value: '',
		name: 'userEmail',
		label: 'Work Email Id',
		type: 'email',
		validation: {
			required: {
				msg: 'Required!'
			},
			custom: {
				validator: validEmail,
				msg: 'Enter Valid Email'
			}
		},
		errorOnBlur: false,
		nocHangeError: true,
		isMandatory: true,
		outLabel: true
	},
	mobileField: {
		name: 'phoneNumber',
		label: 'Mobile No.',
		value: '',
		maxLen: '10',
		validation: {
			required: {
				msg: 'Required!'
			},
			custom: {
				validator: validPhone,
				msg: 'Invalid phone number, must be 10 digits'
			}
		},
		type: 'tel',
		isMandatory: true,
		outLabel: true
	}
};

export const serverPersuation = [{
	'text': '37,492 Travellers',
	'text1': 'have been able to cancel their tickets at the last-minute due to changes in government regulations and easily get refunds',
	'icon': 'https://promos.makemytrip.com/images/myBiz/Onboardingv2/first_fold/persuasion.png'
}];

export const BenefitDetailsData = {
	EM: { carousel: {
		heading: 'We Care for Employees who Travel for Business',
		subHeading: 'Quickly create your account and start enjoying the benefits',
		url: 'https://promos.makemytrip.com/images/myBiz/Onboardingv2/EM/employee.png'
	},
	list: [{
		'heading': 'Special corporate fare',
		'type': 'TM',
		'icon': 'flight',
		'description': 'With special corporate rates on flights, get additional benefits like free seats, reduced cancellaton fee on last-minute cancellations & free meals.'

	},
	{
		'heading': 'myBiz Assured Hotels',
		'type': 'TM',
		'icon': 'hotel',
		'description': 'Stay in hotels reviewed & rated high by other business travellers, ensuring all comforts during your business trip.'

	},
	{
		'heading': 'Hassle-free Payment',
		'type': 'TM',
		'icon': 'hotel',
		'iconUrl': 'https://promos.makemytrip.com/images/myBiz/Onboardingv2/EM/hassle_free_payment.png',
		'description': 'Pay directly from the company wallet and skip the hassle of submitting extensive paperwork for reimbursement.'

	},
	{
		'heading': 'Safe & Sanitised Cabs',
		'type': 'TM',
		'icon': 'cab',
		'description': 'The best on-ground crew to support your ground travel, whether it be airport pickups or an outstation travel. There is no surge pricing or waiting for cars to arrive.'

	},
	{
		'heading': 'Self-booking Online Platform',
		'type': 'TM',
		'icon': 'flight',
		'iconUrl': 'https://promos.makemytrip.com/images/myBiz/Onboardingv2/AD/invoice.png',
		'description': 'Available on MakeMyTrip App, myBiz allows you to book whenever, and wherever. You can now take better control of your itinerary, modifications, cancellations and more.'

	},
	{
		'heading': 'Seamless Approval & Reimbursements',
		'type': 'TM',
		'icon': 'phone',
		'iconUrl': 'https://promos.makemytrip.com/images/myBiz/Onboardingv2/EM/approve.png',
		'description': 'Once you find the perfect flight or hotel, that’s all you need to do. An integrated approval and reimbursement mechanism makes things simpler and faster.'

	},
	{
		'heading': 'Personalised Experience',
		'type': 'TM',
		'icon': 'phone',
		'iconUrl': 'https://promos.makemytrip.com/images/myBiz/Onboardingv2/EM/experience.png',
		'description': 'No need to scroll endlessly to find the perfect flight or hotel. Our smart algos offer a personal touch to help you search within seconds.'

	},
	{
		'heading': 'Personal Rewards',
		'type': 'TM',
		'icon': 'flight',
		'iconUrl': 'https://promos.makemytrip.com/images/myBiz/Onboardingv2/EM/quality.png',
		'description': 'Your business trips will now be personally rewarding too! Accrue reward points, and save more with loyalty memberships and frequent flyer miles.'

	},
	{
		'heading': '24x7 Support',
		'type': 'TM',
		'icon': 'phone',
		'description': 'A team of specially-trained executives works 24x7 to ensure your colleagues travel worry-free. For any help you may need, whether before the trip, on the trip or after the trip, we’re always there to support you.'

	}
	] },
	CB: { carousel: {
		heading: 'Super Features to Make Central Booker\'s Life Simpler',
		subHeading: 'Quickly create your account and start enjoying the benefits',
		url: 'https://promos.makemytrip.com/images/myBiz/Onboardingv2/CB/booker.png	'
	},
	list: [{
		'heading': 'Special corporate fare',
		'type': 'TM',
		'icon': 'flight',
		'description': 'With special corporate rates on flights, get additional benefits like free seats, reduced cancellaton fee on last-minute cancellations & free meals.'

	},
	{
		'heading': 'myBiz Assured Hotels',
		'type': 'TM',
		'icon': 'hotel',
		'description': 'Stay in hotels reviewed & rated high by other business travellers, ensuring all comforts during your business trip.'

	},
	{
		'heading': 'Hassle-free Payment',
		'type': 'TM',
		'icon': 'hotel',
		'iconUrl': 'https://promos.makemytrip.com/images/myBiz/Onboardingv2/EM/hassle_free_payment.png',
		'description': 'Pay directly from the company wallet and skip the hassle of submitting extensive paperwork for reimbursement.'

	},
	{
		'heading': 'Safe & Sanitised Cabs',
		'type': 'TM',
		'icon': 'cab',
		'description': 'The best on-ground crew to support your ground travel, whether it be airport pickups or an outstation travel. There is no surge pricing or waiting for cars to arrive.'

	},
	{
		'heading': 'Easy-to-Use Admin Dashboard ',
		'type': 'TM',
		'icon': 'flight',
		'iconUrl': 'https://promos.makemytrip.com/images/myBiz/Onboardingv2/AD/dashboard.png',
		'description': 'Set up company account and add travel policies within just a few clicks. You can also upload and manage bulk employee details.'

	},
	{
		'heading': 'Track Your Requests',
		'type': 'TM',
		'icon': 'phone',
		'iconUrl': 'https://promos.makemytrip.com/images/myBiz/Onboardingv2/CB/request.png',
		'description': 'Track all approval for business travel bookings for a smooth booking experience.'

	},
	{
		'heading': 'Book Using the Traveller\'s Policy',
		'type': 'TM',
		'icon': 'phone',
		'iconUrl': 'https://promos.makemytrip.com/images/myBiz/Onboardingv2/CB/policy.png',
		'description': 'Choose a Primary Traveller at the start of the booking process to discover their travel policies right from the outset, thereby reducing your effort and time.'

	},
	{
		'heading': 'Shortlist & Share',
		'type': 'TM',
		'icon': 'flight',
		'iconUrl': 'https://promos.makemytrip.com/images/myBiz/Onboardingv2/CB/share.png',
		'description': 'Shortlist and share options to your employees directly from myBiz and help them book with more convenience.'

	},
	{
		'heading': '24x7 Support',
		'type': 'TM',
		'icon': 'phone',
		'description': 'A team of specially-trained executives works 24x7 to ensure your colleagues travel worry-free. For any help you may need, whether before the trip, on the trip or after the trip, we’re always there to support you.'

	}
	] },
	TM: { carousel: {
		heading: 'We make Travel Management Simpler for you',
		subHeading: 'Quickly create your account and start enjoying the benefits',
		url: 'https://promos.makemytrip.com/images/myBiz/Onboardingv2/AD/admin.png'
	},
	list: [{
		'heading': 'Special corporate fare',
		'type': 'TM',
		'icon': 'flight',
		'description': 'With special corporate rates on flights, get additional benefits like free seats, reduced cancellaton fee on last-minute cancellations & free meals.'

	},
	{
		'heading': 'myBiz Assured Hotels',
		'type': 'TM',
		'icon': 'hotel',
		'description': 'Stay in hotels reviewed & rated high by other business travellers, ensuring all comforts during your business trip.'

	},
	{
		'heading': 'Hassle-free Payment',
		'type': 'TM',
		'icon': 'hotel',
		'iconUrl': 'https://promos.makemytrip.com/images/myBiz/Onboardingv2/EM/hassle_free_payment.png',
		'description': 'Pay directly from the company wallet and skip the hassle of submitting extensive paperwork for reimbursement.'

	},
	{
		'heading': 'Safe & Sanitised Cabs',
		'type': 'TM',
		'icon': 'cab',
		'description': 'The best on-ground crew to support your ground travel, whether it be airport pickups or an outstation travel. There is no surge pricing or waiting for cars to arrive.'

	},
	{
		'heading': 'Easy-to-Use Admin Dashboard ',
		'type': 'TM',
		'icon': 'flight',
		'iconUrl': 'https://promos.makemytrip.com/images/myBiz/Onboardingv2/AD/dashboard.png',
		'description': 'Set up company account and add travel policies within just a few clicks. You can also upload and manage bulk employee details.'

	},
	{
		'heading': 'Employee-friendly',
		'type': 'TM',
		'icon': 'phone',
		'iconUrl': 'https://promos.makemytrip.com/images/myBiz/Onboardingv2/EM/approve.png',
		'description': 'Along with one-click approvals and reimbursements, with adherence to policy, myBiz gives you more control, while ensuring that the booking process is not hassle for your employees.'

	},
	{
		'heading': 'Savings',
		'type': 'TM',
		'icon': 'phone',
		'iconUrl': 'https://promos.makemytrip.com/images/myBiz/Onboardingv2/AD/savings.png',
		'description': 'With our features such as cheaper flight suggestions, missed savings report and much more, find more ways to save on your business travel spends alwyas.'

	},
	{
		'heading': 'Smart Reporting and Bulk Invoice Download',
		'type': 'TM',
		'icon': 'flight',
		'iconUrl': 'https://promos.makemytrip.com/images/myBiz/Onboardingv2/AD/invoice.png',
		'description': 'Consolidated reports and invoices are the need of the hour. Ease your GST worries and leverage the power of myBiz for smarter invoicing and reporting.'

	},
	{
		'heading': '24x7 Support',
		'type': 'TM',
		'icon': 'phone',
		'description': 'A team of specially-trained executives works 24x7 to ensure your colleagues travel worry-free. For any help you may need, whether before the trip, on the trip or after the trip, we’re always there to support you.'

	}
	] },
	DEF: { carousel: {
		heading: 'We make Travel Management Simpler for you',
		subHeading: 'Quickly create your account and start enjoying the benefits',
		url: landingImgPath + 'employeeBenefits.png'
	},
	list: [{
		'heading': 'Special corporate fare',
		'type': 'TM',
		'icon': 'flight',
		'description': 'With special corporate rates on flights, get additional benefits like free seats, reduced cancellaton fee on last-minute cancellations & free meals.'

	},
	{
		'heading': 'myBiz Assured Hotels',
		'type': 'TM',
		'icon': 'hotel',
		'description': 'Stay in hotels reviewed & rated high by other business travellers, ensuring all comforts during your business trip.'

	},
	{
		'heading': 'Hassle-free Payment',
		'iconUrl': 'https://promos.makemytrip.com/images/myBiz/Onboardingv2/EM/hassle_free_payment.png',
		'type': 'TM',
		'icon': 'hotel',
		'description': 'Pay directly from the company wallet and skip the hassle of submitting extensive paperwork for reimbursement.'

	},
	{
		'heading': 'Safe & Sanitised Cabs',
		'type': 'TM',
		'icon': 'cab',
		'description': 'The best on-ground crew to support your ground travel, whether it be airport pickups or an outstation travel. There is no surge pricing or waiting for cars to arrive.'

	},
	{
		'heading': '24x7 Support',
		'type': 'TM',
		'icon': 'phone',
		'description': 'A team of specially-trained executives works 24x7 to ensure your colleagues travel worry-free. For any help you may need, whether before the trip, on the trip or after the trip, we’re always there to support you.'

	}
	] }
};

export const GuidLinesPopText = {
	heading: {
		'preparing': 'Downloading...',
		'downloaded': 'Thank You !',
		'def': 'Ready to Download'
	},
	subHeading: {
		'preparing': 'Please wait while the guideline is being downloaded.',
		'downloaded': 'Thank you for downloading guideline.',
		'def': 'Please tell us about you to download.'
	}
};

export const SMENavigationLinks = [
	{
		text: 'Benefits',
		type: 'benefits',
		link: 'Benefits',
		trackVal: 'benefits_clicked'
	},
	{
		text: 'Case Studies',
		type: 'case',
		link: `CaseStudies`,
		trackVal: 'case studies_clicked'
	}
];

export const smePersuation = [{
	'text': '31,000+ SMEs/MSMEs',
	'text1': 'use myBiz for their corporate travel to maximise savings, avail GST invoices and much more',
	'icon': 'https://promos.makemytrip.com/images/myBiz/Onboardingv2/first_fold/persuasion.png'
}];

export const smeBannerCarouselData = [{
	heading: 'Best-in-class Fares on Flights in India',
	subHeading: 'Up to 10% additional discount on flights, free cancellation on business travel for SMEs'
},
{
	heading: 'Limited Period Deals on Hotels in India',
	subHeading: 'Up to 40% additional discount on hotels, exclusive corporate offers curated for your business travel needs'

},
{
	heading: 'Exclusive Bank Partner Offers',
	subHeading: 'Save more on your business travel with 4% additional discount on HDFC Bank Corporate Credit Cards and 2% additional discount on other bank cards'

}
];

export const SIGNUP_BTN_TEXT = {
	loggedin: 'GET STARTED',
	loggedOut: 'SIGN UP NOW',
	mobilemenuLogin: 'SIGN IN',
	menuLogin: 'SIGN IN / SIGN UP'
};
