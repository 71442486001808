import React from 'react';
import loadable from 'react-loadable';

import Loader from '../../../common/Loader/Loader';

export default loadable({
	loader: () => import('./GettingStarted' /* webpackChunkName: 'GettingStarted' */),
	loading: () => <Loader $bg='#fff' color='#723b63' margin='0 auto' />,
	modules: ['GettingStarted']
});
