import { logOmniClick } from '../logging/omnitureService';

import { offerSectionData } from './config';
import { APPLICATION_CONSTANTS, LANDING_CONSTS } from '../AppConstants';
import { saveServerLogs } from '../UtilityComponents/Util';

let landingObserver = false;

export const loadNextComponent = (ref, nextFn, next, prev) => {
	try {
		let options = {
			root: null,
			rootMargin: '-60px 0px 0px 0px',
			threshold: 0.2
		};
		const cb = (entries) => {
			entries.forEach(({ isIntersecting }) => {
				if (isIntersecting && nextFn) {
					nextFn(next, prev, ref);
					if (landingObserver) {
						landingObserver.unobserve(ref.current);
					}
				}
			});
		};
		if (typeof window !== 'undefined' && window.IntersectionObserver) {
			landingObserver = new IntersectionObserver(cb, options);
			landingObserver.observe(ref.current);
		}
	} catch (error) {
		if (nextFn) {
			nextFn(next, prev, ref);
		}
		saveServerLogs(error && error.stack ? error.stack : error, 'error');
	}
};

export const formatBuisnessDets = (res = {}) => {
	const { data = {} } = res;
	let retData = {
		error: true
	};
	if (data.responseCode === APPLICATION_CONSTANTS.SUCCESS_RESPONSE_CODE) {
		const offerData = data.offers && data.offers.map((off) => {
			const theme = offerSectionData[off.type] || offerSectionData['DEFAULT'];
			return { ...off, ...theme };
		});
		retData = {
			onboard: {
				offers: offerData,
				videoLink: data.videoLink,
				imageLink: data.imageLink,
				caraouselDetails: data.caraouselDetails
			},
			orgDets: {
				counts: data.orgCount,
				topOrgs: data.topOrgs,
				persuation: data.persuation
			},
			app: {
				qrCode: data.qrCode,
				andriod: data.androidLink,
				ios: data.iosLink
			},
			monetary: {
				spend: data.spend,
				saving: data.saving
			}
		};
	}
	return retData;
};

export const scrollToElem = (refHtml) => {
	if (refHtml.current) {
		const headerOffset = 80;
		const elementPosition = refHtml.current.offsetTop;
		const offsetPosition = elementPosition - headerOffset;

		window.scrollTo({
			top: offsetPosition,
			behavior: 'smooth'
		});
	}
};

// track landing page Events
export const trackClickEvents = (eventType, key = 'prop54') => {
	logOmniClick(LANDING_CONSTS.LINK_NAME, {
		[key]: eventType
	});
};
