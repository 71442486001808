import styled, { css, keyframes } from 'styled-components';

import { FlexDiv, bgShowAnimate } from '../Styles';

export const ModalBg = styled.div`
	animation: ${bgShowAnimate} 0.4s cubic-bezier(0.5, 0, 0.5, 1) forwards;
  position:fixed;
  top:0;
  left:0;
  width:100%;
  height:100%;
  z-index:10;
`;
export const ModalWrapper = styled.div `
  width:${(props) => props.$width ? props.$width : '' };
  height:${(props) => props.height ? props.height : '' };
  position: absolute;
  z-index: 10;
  top: 50%;
  border-radius:4px;
  left: 50%;
  transform: ${(props) => props.type == 'bottom' ? 'translate(0%, 0%)' : 'translate(-50%, -50%)'};
  display: flex;
  flex-direction: row;
	.submodal {
		max-height: 100vh;
		overflow: auto;
	}

	&.no-scroll{
		.content {
			overflow: initial;
		}
	}
	&.invoice{
		max-height: 90%;
		.content{
			height: auto;
		}
	}
  @media only screen and (max-width: 767px) {
    max-width: 100%;
    height: 100%;
		.content {
			padding: 0;
			overflow: auto;
		}
		top: ${(props) => props.type === 'bottom' ? 'auto' : '0' };
		border-radius: ${(props) => props.type === 'bottom' ? '4px' : '0' };
		left: 0;
		transform: none;
    ${(props) => props.type === 'bottom' && css`
      height: 70%;
      `}
  }
  ${(props) => props.type === 'bottom' && css`
    width:${(props) => props.width ? props.width : '100%' };
    height:${(props) => props.height ? props.height : '100%' };
    position: fixed;
    animation: ${slideUp} 0.3s cubic-bezier(0.5, 0, 0.5, 1);
    left: 0;
    bottom: 0;
    top: auto;
  `}
	${(props) => {
		switch (props.submodalPos) {
			case 'left':
				return css`
					flex-direction: row;
					.submodal {
						border-radius : 4px 0px 0px 4px;
					}
			`;
			case 'right':
				return css`
					flex-direction: row-reverse;
					.submodal {
						border-radius : 0 4px 4px 0;
					}

			`;
			case 'top':
				return css`
					flex-direction: column;
					.submodal {
						border-radius : 4px 4px 0px 0px;
						height: auto;
						overflow: unset;
					}
					
			`;
			case 'bottom':
				return css`
					flex-direction: column-reverse;
					.submodal {
						border-radius : 0px 0px 4px 4px;
						height: auto;
						overflow: unset;
					}
			`;
		}
	}}
  &.zeroPadding{
		.content {
			padding: 0;
		}
  }

`;
export const ImgWrapper = styled.span`
  width:${(props) => props.imgWidth ? props.imgWidth : '313px' };
  height:${(props) => props.imgHeight ? props.imgHeight : '245px' };
  background-size: 100% 100%;
  display:flex;
  align-self:center;
  margin-bottom:15px;
	&.gradGreenTick {
  	background-size: 254px 254px;
		display: inline-block;
		width: 40px;
		height: 40px;
		background-position: -163px -5px;
	}
  ${(props) => props.EmailImgStyle && css`
    `
}
`;
export const ButtonWrapper = styled.p `
	width: ${(props) => props.btnWidth || '200px'};
	display: flex;
	align-self: center;
	justify-content: center;
`;
export const ModalMainWrapper = styled.div`
	position:fixed;
	top:0;
	left:0;
	width:100%;
	height:100%;
	z-index:10;
	&.sample .modalWrapper{
		.buttonWrapper {
			width:560px;
		}
	}
	&.navModal {
		top: 63px;
    width: auto;
    height: auto;
    right: calc(50% - 600px + 930px);
    left: auto;
		& .modalWrapper {
			left: 0;
			top: 0;
			transform: none;
			box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
			.content {
				padding: 0;
			}
		}
	}

	&.helpModal .modalWrapper {
		.buttonWrapper {
			width:560px;
		}
		& .content {
			border-radius: 10px;
		}
	}

	&.travelClassModal {
		position: absolute;
		bottom: 0px;
		& .modalWrapper {
			top: 100%;
			left: auto;
			right: 0px;
			transform: none;
			background: #FFFFFF;
			border: 1px solid #D8D8D8;
			box-sizing: border-box;
			box-shadow: 0px 7px 7px rgb(0 0 0 / 2%);
			border-radius: 8px;
			overflow: auto;
		}
		& .modalBg {
			background-color: transparent !important;
			cursor: auto;
		}
	}

	&.reminderOverlay .modalWrapper, 
	&.accountDetails .modalWrapper, 
	&.rechargeFailed .modalWrapper, 
	&.cancelItenaryOverlay .modalWrapper, 
	&.moreOptionsOverlay .modalWrapper, 
	&.addExpenseOverlay .modalWrapper {
		h3{
			font-size: 22px;
		}
		h3, .desc, .subtitle,.modal-title {
			text-align: left;
		}
		.buttonWrapper {
			width: 100%;
		}
  	width: 485px;
	}
	&.rechargeFailed {
		.subtitle {
			margin-bottom: 0;
		}
		.modal-title {
			margin-bottom: 10px;
		}
	}
	&.uploadStatus{
		text-align: center;
	}
	&.videoModal {
		.modalBg {
			background-color: rgba(0, 0, 0, 1);
			animation: none;
		}
		.close-ico {
			z-index: 2;
		}
  	& .modalWrapper {
			width: 1180px;
			@media screen and (max-width: 767px) {
				.content {
					padding-left: 0px;
					padding-right: 0px;
				}
				height: 80%;
			}
			iframe {
				width: 100%;
				height: 100%;
			}
			iframe,
			video {
				margin: 0 auto;
			}
			& .videoPlayer {
				margin: auto;
			}
			.modalBg {
				background: rgba(0,0,0);
			}
			.submodal {
				display: flex;
				align-items: flex-end;
			}
			.content {
				padding: 0;
				background: transparent;
			}
		}
		video {
			margin: 0 auto;
		}
	}
	&.shortlisterror {
		@media screen and (max-width: 680px) {
			top: 65px;
			.modalBg { 
				display: none;
			}
		}
	
	}
	&.shortlisterror .modalWrapper, &.empstatuspop .modalWrapper {
		.content {
			padding: 56px 52px;
		}
		.modal-title {
			text-align:center;
			margin-bottom: 5px;
		}
		.cartSucess {
			background-position: 0 0;
			background-size: 282px 282px;
			width: 132px;
			height: 96px;
			margin-bottom: 45px;
		}
		.inviteSucess, .dwlSuccs {
			background-position: 0 0;
			background-size: cover;
			width: 132px;
			min-height: 96px;
			margin-bottom: 50px;
		}
		.emppopImg {
			margin-bottom: 45px;
		}
		.emailimg {
			width: 200px;
			height: 158px;
		}
		.bgtick {
			width: 201px;
			height: 149px;
		}
		.trav-req-success {
			width: 127px;
			min-height: 100px;
		}
		.successReport {
			width: 200px;
			height: 185px;
		}
		.email {
			font-weight: bold;
		}
	}
	&.req-demo .modalWrapper{
		.subtitle {
			margin-bottom: 18px;
		}
		@media screen and (max-width: 680px) {
			background: linear-gradient(150.65deg,#203153 0.7%,#0B242F 100%);
			.submodal {
				height: auto;
				overflow: unset;
			}
			.content {
				padding: 16px;
				border-radius: 5px;
				.err-msg {
					padding-bottom: 0;
					margin-bottom: 0;
				}
				.subtitle {
					margin-top: 32px;
				}
			}
		}
	}
	&.req-demo.demo-form .modalWrapper {
		@media screen and (max-width: 680px) {
			padding: 16px;
		}
		.content {
			border-radius : 0 4px 4px 0;
			@media screen and (max-width: 680px) {
				border-radius : 4px;
			}
		}
		.submodal {
			min-width: 305px;
		}
	}
	&.req-demo.status .modalWrapper {
		background: #fff;
		.imgWrap {
			width: 130px;
			height: 125px;
			margin: 50px auto 20px auto;
			&.tick-broken {
				width: 180px;
				height: 110px;
			}
		}
	}

	&.req-demo.timing .modalWrapper {
		.content {
			.subtitle {
				margin-left: 32px;
			}
			.err-msg {
				padding-left: 32px;
			}
			.modal-title {
				margin-bottom: 0;
				font-size: 20px;
			}
		}
		@media screen and (max-width: 680px) {
			background: #fff;
			.content {
				.subtitle {
					margin: 0 0 30px 5px;
				}
				.modal-title {
					.titletxt {
						width: 100%;
						display: inline-block;
						padding-left: 5px;
						white-space: nowrap;
					}
					margin-bottom: 0;
				}
			}
		}
	}
	&.request-demo-succ, &.InvoiceOverlay .modalWrapper {
		.modal-title, .desc, .subtiltle {
			text-align:center;
		}
		.modal-title {
			margin-bottom:20px;
		}
		.subtitle {
			margin-bottom:30px;
		}
		.desc {
			margin-bottom:30px;
		}
	}
	&.shortlisterror .modalWrapper {
		.content {
			padding: 56px 52px;
		}
		.modal-title {
			text-align:center;
			margin-bottom: 5px;
		}
		.cartSucess {
			background-position: 0 0;
			background-size: 282px 282px;
			width: 132px;
			height: 96px;
			margin-bottom: 45px;
		}
		.bgtick {
			width: 201px;
			height: 149px;
		}
		.email {
			font-weight: bold;
		}
	}

	&.imagePreviewModal .modalWrapper {
		height: 100%;
		width: 100%;
		.content {
			background-color: rgba(0, 0, 0, 0.8);
		}
		overflow: hidden;
		display: block;
		position: relative;
		@media screen and (max-width: 680px) {
			padding-left: 0px;
			padding-right: 0px;
		}
	}

	&.expenseStatus {
		& .modalWrapper {
			.successDefaultICon {
				width: 201px;
				height: 149px;
			}
		}
		@media screen and (min-width: 320px) and (max-width: 767px) {
			& .modalWrapper {
				width: 95vw !important;
				position: absolute !important;
			}
		}
	}

	&.expenseApproveModal {
		& .subtitle {
			color: #4a4a4a;
			font-size: 14px;
			margin-bottom:15px;
			min-height:50px;
		}

		& .modal-title {
			color: #000000;
			font-size: 22px;
			font-weight: 900;
			margin-bottom: 15px;
		}
		@media screen and (min-width: 320px) and (max-width: 480px) {
			& .modalWrapper {
				width: 95vw !important;
				position: absolute !important;
			}
		}
		@media screen and (min-width: 320px) and (max-width: 359px) {
			& .flexOne {
				& p {
					font-size: 20px;
					& + p {
						font-size: 13px;
					}
				}
			}
			& .font16 {
				font-size: 14px;
			}
			& .font12 {
				font-size: 11px;
			}
			& button {
				font-size: 14px;
				&:first-child {
					width: 105px;
				}
			}
		}
	}

	&.inviteEmpPop {
		.subtitle {
			margin-bottom: 10px;
		}
	}

	&.mobile-header {
		.modalWrapper {
			top: 0;
			left: auto;
			right:0;
			height: 100%;
			background: #fff;
			padding: 0;
		}
	}

	&.csvReimburse {
		.modalWrapper {
			max-width: 510px;
			width: 100%;
		}
	}

	&.empPop {
		.empList {
			min-height: 250px;
			margin-bottom: 20px;
			> .custom-scroll {
				max-height: 60vh;
			}
		}
		.subtitle {
			margin-bottom: 15px;
		}
	}
	&.empGrpPop {
		.empList>.custom-scroll {
			max-height: 250px;
		}
	}
	&.grpAddPop {
		.empList {
			padding: 10px;
			margin-bottom: 20px;
			margin-top: 20px;
			>.custom-scroll {
				max-height: 60vh;
			}
		}
		.subtitle {
			margin-bottom: 15px;
		}
	}
	@media only screen and (max-width: 767px) {
		max-width: 100%;
		height: 100%;
		.modal-title, .subtitle {
			padding-right: 15px; 
			padding-left: 15px; 
		}
	}
	&.mobile-header {
		.modalWrapper {
			top: 0;
			left: auto;
			right:0;
			height: 100%;
			.content {
				padding: 70px 20px 20px;
				width: 100%;
			}
			@media only screen and (max-width: 1024px) {
				top: 0;
			}
		}
	}
	&.shortlist_mob {
		.btmSheet {
			height: auto;
			.content {
				padding-top: 30px;
				padding-bottom: 30px;
				min-height: 300px;
			}
			.close-ico {
				top: 20px;
				right: 20px;
			}
			.modal-title, .subtitle {
				padding: 0 30px;
			}
			.err-msg {
				padding: 0 30px;
			}
		}

	}

	&.reports {
		.subtitle {
			margin-top: 10px;
		}
	}
	@media only screen and (max-width: 767px) {
		max-width: 100%;
		height: 100%;
		.content {
			max-width: 100%;
			height: 100%;
		}
		.modal-title, .subtitle {
			padding-right: 15px; 
			padding-left: 15px; 
		}
	}
	${(props) => props.type === 'bottom' && css`
		left: 0;
		bottom: 0;
	`}

	${(props) => props.type === 'bottom' && css`
			left: 0;
			bottom: 0;
	`}
`;

export const Tilte = styled.h3 `
 font-size:22px;
 color:#000;
 margin-bottom:5px;
 font-weight:900;
`;
export const Disc = styled.p `
 font-size:14px;
 color:#4a4a4a;
 margin-bottom:15px;
`;

export const Subtitle = styled.p `
  font-size: 14px;
  color:#4a4a4a;
  margin-bottom:25px;
`;

export const ModalFooter = styled.div `
  width: 100%;
  font-size: 14px;
  text-align: center;
  color: #4a4a4a;
`;

export const MobileHeader = styled(FlexDiv)`
  padding-bottom: 16px;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 10%);
  margin: 0 0 24px;
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 20px;
  width: 100%;
  min-height: 60px;
`;

export const BackButton = styled.div `
 position: relative;
 width: 1em;
 height: 1em;
 font-size: 20px;
 padding-left: 15px;
 .back-arrow {
   top: 2px;
   left: 15px;
   right: auto;
	 margin: 0 0 10px 0;
 }
 width: 12%;
`;

export const ContentWrapper = styled.div`
	max-height: 100vh;
	overflow: auto;
	padding: 30px;
	background:#fff;
	width:100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	border-radius : 4px;
	@media screen and (max-width: 680px) {
		padding: 16px;
	}
`;

export const slideUp = keyframes `
    0% {transform: translate(0, 100%);}
    100% {transform: translate(0, 0%);}
`;
