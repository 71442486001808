const axios = require('axios');

const APP_CONSTANTS = require('../src/AppConstants');
const APP_PATH = APP_CONSTANTS.PATHS;

/**
 * get ab experiment data from pokus for client and server
 * @author mmt8270
 * @param { String } dvidVal deviceid for pokus data
 * @param { String } lob lob for which data required
 * @param { String } orgID
 * @param { String } mmtAuth
 * @param { Boolean } isServer if api is called for ssr or client
 * @return { Promise } axios promise object for the pokus api
 */
const abApi = (dvidVal, lob, orgID, mmtAuth, isServer = false) => {
	let data = {
		'attributes': {
			'device_type': 'desktop'
		},
		'lob': lob,
		'user': {
			'deviceId': dvidVal
		},
		'context': {
			'pageName': '',
			'section': ''
		}
	};
	if (orgID) {
		data.attributes.orgID = orgID;
	}
	data = JSON.stringify(data);
	const headers = {
		'Content-Type': 'application/json',
		'Accept': 'application/json'
	};
	if (mmtAuth) {
		const authKey = isServer ? 'mmtAuth' : 'mmt-auth';
		headers[authKey] = mmtAuth;
	}
	const pokusUrl = isServer ? APP_PATH.SERVER_POKUS : APP_PATH.POKUS_API;
	const timeout = isServer ? APP_CONSTANTS.APPLICATION_CONSTANTS.AB_API_TIMEOUT : APP_CONSTANTS.APPLICATION_CONSTANTS.AB_API_CLIENT_TIMEOUT;
	return axios({
		method: 'post',
		url: pokusUrl,
		timeout,
		headers,
		data
	});
};

/**
 * get random unique string to be used as uuid
 * @author mmt8270
 * @return { String } uuid
 */
const getUUID = () => {
	function s4() {
		return Math.floor((1 + Math.random()) * 0x10000)
			.toString(16)
			.substring(1);
	}
	return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
		s4() + '-' + s4() + s4() + s4();
};

/**
 * get random unique string to be used as uuid
 * @author mmt8270
 * @return { String } uuid
 */
const getReqID = () => {
	function s4() {
		return Math.floor((1 + Math.random()) * 0x10000)
			.toString(16)
			.substring(1);
	}
	return s4() + s4() + s4() + s4();
};

/**
 * encode url
 * @author mmt8270
 * @param { String } val url to be encoded
 * @return { String } encoded url
 */
const encodeUrl = (val) => {
	try {
		return encodeURIComponent(val);
	} catch (error) {
		return val;
	}
};

/**
 * serialize object
 * @author mmt8270
 * @param { Object } obj object to be serialized
 * @return { String } serialized object string
 */
const serializeObject = (obj) => {
	try {
		return Object.keys(obj).map((key) => key + '=' + encodeUrl(obj[key])).join('&');
	} catch (error) {
		return '';
	}
};

/**
 * return if any element is present in other array
 * @author mmt8270
 * @param { Array|String } arr1 array 1 to be matched
 * @param { Array|String } arr2 array 2 to be matched
 * @return { Boolean }
 */

const findCommonElements = (arr1, arr2) => {
	try {
		return arr1.some((item) => arr2.includes(item));
	} catch (error) {
		return false;
	}
};

/**
 * return url data
 * @author mmt8270
 * @param { Object } req request Object
 * @return { Object } url params url base
 */
const getUrlData = (req) => {
	let urlObj = {};
	try {
		const { url, query } = req;
		const urlArr = url.split('?');
		const pageUrl = urlArr[0] || '';
		const params = Object.keys(query).length > 0 ? query : false;
		urlObj = {
			pageUrl,
			params
		};
	} catch (error) {
		urlObj = {
			pageUrl: '',
			params: false
		};
	}
	return urlObj;
};

const cookieParser = (cookieString)=> {
	if (cookieString === '') {
		return {};
	}
	let pairs = cookieString.split(';');
	let splittedPairs = pairs.map((cookie) => cookie.split('='));
	const cookieObj = splittedPairs?.reduce(function(obj, cookie) {
		obj[decodeURIComponent(cookie[0].trim())] =
					decodeURIComponent(cookie[1].trim());
		return obj;
	}, {});
	return cookieObj;
};

module.exports = {
	abApi,
	getUUID,
	serializeObject,
	encodeUrl,
	findCommonElements,
	getUrlData,
	cookieParser,
	getReqID
};
