if (!global._babelPolyfill) {
	require('@babel/polyfill');
}

import { hydrate } from 'react-dom';
import React from 'react';
import Loadable from 'react-loadable';
import { BrowserRouter } from 'react-router-dom';

import App from './index';
import { V2_APPLICATION_PATH } from 'AppConstants';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from 'UtilityComponents/Util';

const preloadedState = window.__PRELOADED_STATE__;
let appData = {};
if (preloadedState && preloadedState.length > 0) {
	preloadedState.forEach((data) => {
		if (data) {
			appData = { ...appData, ...data };
		}
	});
}

delete window.__PRELOADED_STATE__;

Loadable.preloadReady().then(() => {
	if (typeof window !== 'undefined') {
		hydrate(
			<BrowserRouter basename={V2_APPLICATION_PATH}>
				<StyleSheetManager shouldForwardProp={shouldForwardProp}>
					<App contextData={appData} isPreLoad={isPreload} />
				</StyleSheetManager>
			</BrowserRouter>,
			document.querySelector('#root')
		);
	}
});
