import React from 'react';
import Loadable from 'react-loadable';

import Loader from '../../../common/Loader/Loader';

export default Loadable({
	loader: () => import('./Benefits' /* webpackChunkName: 'benefits' */),
	loading: () => <Loader $bg='#fff' color='#723b63' margin='0 auto' />,
	modules: ['benefits']
});
