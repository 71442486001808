const BUILD_ASSETS = require('./build_assets/react');
const BUILD_ASSETS_CSS = require('./build_assets/css');

const BASE_PATH = typeof isDev !== 'undefined' && isDev ? '/assets/' : '//imgak.mmtcdn.com/mybiz/assets/';
const BASE_DIST_PATH = BASE_PATH + 'dist/';
const BASE_MF_DIST_PATH = BASE_PATH + 'distMF/';
const BASE_MOBILE_DIST_PATH = BASE_PATH + 'distMobile/';

const ASSET_PATH = {
	'JS': BASE_PATH + 'js/',
	'CSS': BASE_PATH + 'css/',
	'IMAGES': BASE_PATH + 'images/',
	'POST_LOGGEDIN': BUILD_ASSETS.PostLoggedin && BUILD_ASSETS.PostLoggedin.js,
	'HEADER': BUILD_ASSETS.Header && BUILD_ASSETS.Header.js,
	'LANDING_BUNDLE': BUILD_ASSETS.LandingFlow && BUILD_ASSETS.LandingFlow.js,
	'LANDING_OLD_BUNDLE': BUILD_ASSETS.LandingOldFlow && BUILD_ASSETS.LandingOldFlow.js,
	'POST_LOGGEDIN_CSS': BUILD_ASSETS.PostLoggedin && BUILD_ASSETS.PostLoggedin.css,
	'GLOBAL_CSS': BASE_DIST_PATH + (BUILD_ASSETS_CSS.globalMin && BUILD_ASSETS_CSS.globalMin.css),
	'SHORTLIST_BUNDLE': BUILD_ASSETS.ShortListFlow && BUILD_ASSETS.ShortListFlow.js,
	'HEADER_CSS': BASE_DIST_PATH + (BUILD_ASSETS_CSS.headerCss && BUILD_ASSETS_CSS.headerCss.css),
	'EXPENSE_BUNDLE': BUILD_ASSETS.ExpenseFlow && BUILD_ASSETS.ExpenseFlow.js,
	'REDUX': BUILD_ASSETS.redux && BUILD_ASSETS.redux.js,
	'EXPENSE_CSS': BUILD_ASSETS.ExpenseFlow && BUILD_ASSETS.ExpenseFlow.css,
	'VENDOR_JS': BUILD_ASSETS.vendor && BUILD_ASSETS.vendor.js,
	'VENDOR_CSS': BUILD_ASSETS.vendor && BUILD_ASSETS.vendor.css,
	'RUNTIME_JS': BUILD_ASSETS.runtime && BUILD_ASSETS.runtime.js
};

const PAGE_TYPE = {
	LANDING: 'LandingFlow',
	POST_LOGGEDIN: 'PostLoggedin',
	HEADER: 'Header',
	NEW_THEME: 'newtheme',
	SHORTLIST: 'ShortListFlow',
	EXPENSE: 'ExpenseFlow',
	SEO_PAGES: 'SeoPages',
	INTEGRATION_PAGES: 'IntegrationPages',
	MOBILE: {
		LANDING: 'LandingFlow',
		APP: 'app~mobile'
	}
};

module.exports = {
	ASSET_PATH,
	BASE_PATH,
	BASE_DIST_PATH,
	PAGE_TYPE,
	BASE_MF_DIST_PATH,
	BASE_MOBILE_DIST_PATH
};
