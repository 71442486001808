import React from 'react';

import * as S from './CircularLoaderStyle';

const CircularLoader = ({
    ldrWidth,
    ldrHeight,
    pageLoader
}) => {
    return (
        <React.Fragment>
            <S.Loader
                ldrWidth={ldrWidth}
                ldrHeight={ldrHeight}
                pageLoader={pageLoader}
            >
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </S.Loader>
            {pageLoader && <S.LoaderShadow />}
        </React.Fragment>
    );
};

export default CircularLoader;
