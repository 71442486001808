const { getConsulData } = require('./UtilityComponents');

const EXPENSE_ADMIN_PATHS = {
	CATEGORIES: 'categories',
	TNE_FORM: 'tneForm',
	POLICY: 'policy',
	REPORTS: 'reports'
};
const mapiUrl = '//mapi.makemytrip.com';
const APPLICATION_PATH = '';
const V2_APPLICATION_PATH = '/v2';

const PATHS = {
	LANDING_LOGIN_PAGE: APPLICATION_PATH + '/?signup=true',
	/* ===== WEBPAGE URLS =====*/

	// REDIRECTED PAGES
	SIGNUP_PAGE: APPLICATION_PATH + '/signup',
	REQUEST_DEMO_PAGE: APPLICATION_PATH + '/requestDemo',
	LOGINPAGE: APPLICATION_PATH + '/userlogin',
	EMPLOYEE_LANDING_PAGE: APPLICATION_PATH + '/employeeLanding',
	ADMIN_LANDING_PAGE: APPLICATION_PATH + '/adminLanding',
	TRAVEL_REQUEST_PAGE: APPLICATION_PATH + '/travelRequest',
	INVITE_PAGE: APPLICATION_PATH + '/invite/',
	USERVERIFY_PAGE: APPLICATION_PATH + '/userverify',
	GST_OLD: APPLICATION_PATH + '/gst',
	VENUES_URL: APPLICATION_PATH + '/venues',
	CORP_BULK_BOOKING: APPLICATION_PATH + '/corp/bulkBooking',
	CORP_BULK_BOOKING_LEADS: APPLICATION_PATH + '/corp/bulkBooking/leads',
	CORP_BULK_BOOKING_THANK_YOU: APPLICATION_PATH + '/corp/bulkBooking/thank-you',

	// OLD NONUSED PAGES
	SIGNUP_THANK_YOU: APPLICATION_PATH + '/SignUpThankYouPage',
	COMPANY_DETAILS_PAGE: APPLICATION_PATH + '/companyDetails',
	DOCUMENT_TYPE_PAGE: APPLICATION_PATH + '/documentType',
	DOCUMENT_UPLOAD_PAGE: APPLICATION_PATH + '/documentUpload',
	POLICY: APPLICATION_PATH + '/policydashboard',
	REFER_ADMIN_PAGE: APPLICATION_PATH + '/referAdmin',
	REPORT_DOWNLOAD_OLD: APPLICATION_PATH + '/reportDownload',
	GIFT_CARDS_URL: APPLICATION_PATH + '/gift-card',

	// PAGES
	APPLICATION_PATH: APPLICATION_PATH,
	LANDING_PAGE: APPLICATION_PATH + '/',
	LANDING_SME_FEST: APPLICATION_PATH + '/sme-travel-fest',
	CORPORATE_SEO_PAGE: '/corporate/',
	MICE_PAGE: APPLICATION_PATH + '/mice',
	RESOURCE_PAGE: APPLICATION_PATH + '/business-travel-on-myBiz',
	REQUEST_DEMO_PAGE_NEW: APPLICATION_PATH + '/?modal=requestDemo',
	BROWSER_HCSHEADER: APPLICATION_PATH + '/hcsHeader',
	SERVER_HCSHEADER: APPLICATION_PATH + '/hcsHeader',
	FORGOT_PWD: APPLICATION_PATH + '/forgotPassword',
	EMAIL_VERIFICATION: APPLICATION_PATH + '/emailverification',
	SSO_LOGIN: APPLICATION_PATH + '/ssoLogin',
	DASHBOARD: APPLICATION_PATH + '/dashboard',
	PROVISIONAL_ADMIN_PAGE: APPLICATION_PATH + '/provisionalDashboard',
	PROCEED_TO_CORP: APPLICATION_PATH + '/proceedToCorp',
	SSO_PAGE_RELATIVE_URL: APPLICATION_PATH + '/ssologin',
	REPORT_DOWNLOAD: APPLICATION_PATH + '/reportDownload',
	REPORT_REVAMP: APPLICATION_PATH + '/reports',
	INVOICE_PAGE: APPLICATION_PATH + '/invoice',
	WALLET_PAGE: APPLICATION_PATH + '/wallet',
	TRIP_TAG: APPLICATION_PATH + '/tripTag',
	TRIP_TAG_EDIT: APPLICATION_PATH + '/tripTag/new',
	CHARTERED_FLIGHT: APPLICATION_PATH + '/charteredFlightrequest',
	CORPORATE: '/corporate',
	POLICY_PAGE: APPLICATION_PATH + '/policy',
	EMPLOYEE_PAGE: APPLICATION_PATH + '/employee',
	GROUPS_PAGE: APPLICATION_PATH + '/groups',
	GROUPS_DETAIL_PAGE: APPLICATION_PATH + '/groupsDetail',
	COMPANY_DETAILS: '/dashboard',
	GST_PAGE: APPLICATION_PATH + '/gstDetails',
	SHORTLIST_PAGE: APPLICATION_PATH + '/shortlist',
	CB_WISHLIST: APPLICATION_PATH + '/cbWishlist',
	SHORTILSTED_OPTIONS: APPLICATION_PATH + '/shortlistcart',
	EMP_SHARED_OPTIONS: APPLICATION_PATH + '/empSharedOptions',
	REFERRAL_COMPANY_ADMIN: APPLICATION_PATH + '/referCompanyAdmin',
	MYBIZ_BENEFIT: APPLICATION_PATH + '/benefits',
	CONTACT_US: APPLICATION_PATH + '/contactus',
	EXPENSE_LANDING: APPLICATION_PATH + '/expenseLanding',
	TRAVEL_EXPENSE: APPLICATION_PATH + '/travelExpense',
	EXPENSE_DETAILS: APPLICATION_PATH + '/expenseDetails',
	EXPENSE_REIMBURSE: APPLICATION_PATH + '/reimburseExpense',
	EXPENSE_ADMIN: APPLICATION_PATH + '/expenseAdmin',
	EXPENSE_ADMIN_CATEGORIES: APPLICATION_PATH + `/expenseAdmin#/${EXPENSE_ADMIN_PATHS.CATEGORIES}`,
	EXPENSE_ADMIN_TNE_FORM: APPLICATION_PATH + `/expenseAdmin#/${EXPENSE_ADMIN_PATHS.TNE_FORM}`,
	EXPENSE_ADMIN_POLICY: APPLICATION_PATH + `/expenseAdmin#/${EXPENSE_ADMIN_PATHS.POLICY}`,
	EXPENSE_ADMIN_REPORTS: APPLICATION_PATH + `/expenseAdmin#/${EXPENSE_ADMIN_PATHS.REPORTS}`,
	EXPENSE_SEO: APPLICATION_PATH + '/corporate-expense-management',
	ORGANIZATION_DETAILS_PAGE: APPLICATION_PATH + '/orgDetails',
	TRAVEL_REQ_FORM: APPLICATION_PATH + '/travel-request-form',
	TRAVEL_REQUEST_DETAILS: APPLICATION_PATH + '/travel-request-details',
	MMT_TRAVEL_REQ_FORM: '//makemytrip.com/corptravel/travel-request-form',
	MMT_TRAVEL_REQUEST_DETAILS: '//makemytrip.com/corptravel/travel-request-details',
	TRAIN_LANDING: APPLICATION_PATH + '/trainRequest',
	REPORT_DETAIL_VIEW: APPLICATION_PATH + '/reportsDetail/',
	TRAVEL_REQUEST_AND_BOOKING: APPLICATION_PATH + '/manageBookings',
	TRAVEL_INTEGRATION_LANDING: APPLICATION_PATH + '/integrated-travel-solutions',

	// OTHER PAGES
	BOOKING_SUMMARY: '//supportz.makemytrip.com/Mima/BookingSummary/',
	MY_PROFILE: '//www.makemytrip.com/my-profile/',
	FLIGHTS_PAGE_URL: 'https://www.makemytrip.com/flights/',
	FLIGHT_SEARCH_URL: 'https://www.makemytrip.com/flight/search?',
	HOTEL_PAGE_URL: 'https://www.makemytrip.com/hotels/',
	BUS_TICKETS_URL: 'https://www.makemytrip.com/bus-tickets/',
	VENUES_MICE: 'https://www.makemytrip.com/mice/venues',
	MMT_GIFT_CARD_URL: 'https://www.makemytrip.com/gift-cards/corporate-gift-vouchers.html?isCorporate=true',
	BOOKING_SUMMARY_URL: 'https://supportz.makemytrip.com/MyAccount/BookingSummary',
	CALL_SUPPORT_URL: 'https://support.makemytrip.com/?intid=Header_ch_support',
	CORP_REQUEST_URL: '//mybiz.makemytrip.com/approval/corporate?workflowType=request',
	CORP_APPROVAL_URL: '//mybiz.makemytrip.com/approval/corporate?workflowType=approval',
	SSO_PAGE_URL: `https://mybiz.makemytrip.com${APPLICATION_PATH}/ssologin`,
	MMT_MYBIZ: 'https://www.makemytrip.com?intid=FromMyBusiness',
	MYBIZ_URL: '//mybiz.makemytrip.com' + APPLICATION_PATH,
	MYBIZ_API_URL: '//mybiz.makemytrip.com',
	MMT_APP_LINK: 'http://applinks.makemytrip.com/SMsf/ss58WrOoaG',
	MMT_PATH: '//makemytrip.com',
	APP_SIGNUPLINK: 'https://applinks.makemytrip.com/mwebsignupmybiz',
	MICE_BG: 'https://wwww.promos.makemytrip.com/mybiz/MICEBG.png',
	MMT_BIZ_PROFILE_URL: '//www.makemytrip.com/?isCorporate=true&isProfile=b2b',
	SEO_SCHEMA_URL: 'http://schema.org',
	BIZ_PWA_URL: 'https://www.makemytrip.com/mybizsignup',
	MICE_MMT: 'https://www.makemytrip.com/mice',
	MMT_TRAVEL_REQUEST_PAGE: 'https://www.makemytrip.com/corptravel/travelRequest',
	MMT_REFFERRAL_PAGE: 'https://www.makemytrip.com/corptravel/referral',
	MMT_PROFILE: 'https://www.makemytrip.com/my-profile/',

	ACCESS_LOGS_PATH: '/opt/logs/access.log',

	/* ===== API SERVERS =====*/
	CCB_URL: '//corpcb.makemytrip.com',
	SERVER_MICE_URL: 'http://corp-mice.ecs.mmt',
	SERVER_CCB_URL: 'http://corp-cb.mmt.mmt',
	WISHLIST_FQDN: '//mybizwishlist.makemytrip.com/',
	CONSUL_PROD_FQDN: '//consul.mmt.mmt',
	CONSUL_DEV_FQDN: '//consul-dev.mmt.mmt',
	MAPI_BASE_PATH: mapiUrl,
	SERVER_WORKFLOW_URL: 'http://corp-wf.mmt.mmt',

	TARTAN_SDK_SCRIPT: 'https://hrms-sdk.tartanhq.com/v1/sync-sdk.js',

	/* ===== API URLS =====*/

	// COMMON APIS
	POKUS_API: mapiUrl + '/experiment/assignVariant',
	SERVER_POKUS: 'http://pokus.mmt.mmt/experiment/assignVariant',
	GET_USER_DETAILS_API: 'http://userservice.mmt.mmt/Corporate/CorpAdmin/user/details',
	GET_EMP_DATA: '/getEmployeeDataByAuth',
	GET_USER_SERVICE_MAPI: '/ext/web/pwa/user/details',
	SERVER_LOG: '/saveLogs',
	GET_CONFIG_DATA: '/admin/config?flowType=',
	BASE_IMAGES_CDN_API: 'https://mybiz-img.mmtcdn.com/lobIcons/',

	LOGINBY_AUTH: '/loginByAuth',
	// SIGNUP APIS
	SIGNUP_WORK: '/v3/workDomain/signup',
	SIGNUP_NONWORK: '/v3/nonWorkDomain/signup',
	GST_ADD: '/v3/workDomain/signup/add/gstn',
	RESEND_EMAIL: '/pwa/resendEmailverification',
	VERIFY_EMAIL_VERIFICATION: 'pwa/emailverification?time=',
	REQ_ADMIN: '/v2/mark/self/admin',
	REFER_ADMIN: '/v2/save/referred/admin',
	SET_COMPANY_DATA: '/v2/save/org/metadata',

	LOGOUT_USER: mapiUrl + '/ext/web/pwa/user/logout',

	// DASHBOARD APIS
	DASHBOARD_CONF: '/dashboardConf',
	DASHBOARD_DATA: '/admin/dashboardData?granularity=',

	// INVOICE APIS
	GET_INVOICE_LIST: '/v2/booking/invoice/details',
	GET_INVOICE_COUNT_BUNDLE: '/v2/invoice/details/count',
	GET_FILTER_OPTIONS: '/v2/invoice/gst/triptag/vendor/details',
	INVOICE_ID_SEARCH: '/v2/booking/{id}/details',
	DOWNLOAD_INVOICE: '/v2/invoice/download',
	EMAIL_DOWNLOAD_LINK: '/v2/invoice/download/job/submit',
	BULK_DOWNLOAD: '/bulk/v3/invoice/download/job/{jobId}',
	INVOICE_SUMMARY: '/v2/booking/invoiceSummary',
	POLL_JOB_STATUS: '/job/status',

	// REFER ADMIN APIS
	REFERRAL_COMPANY_ADMIN_URL: '//mybiz.makemytrip.com/referCompanyAdmin',
	MYBIZ_LOGIN_EXPIRE_URL: '/v2/userlogin?sessionExpiry=true',
	GET_EMP_DETAILS: '/v2/user/employee',

	// CHARTERED_FLIGHT APIS
	MAILER_URL: '/genericNotification/v1/send',
	SEND_MAIL: APPLICATION_PATH + '/sendMail',

	// WALLET APIS
	GET_WALLET_LIST: '/wallet/txn/report/details',
	GET_WALLET_COUNT: '/wallet/txn/report/count',
	SET_REMINDER: '/reminderSettings',
	REMOVE_REMINDER: '/reminderSettings/disable',
	GET_WALLET_DETAILS: '/wallet/cardDetails',
	RECHARGE_WALLET: '/organization/secure/credit-wallet',
	WALLET_DOWNLOAD: '/walletReport/v4/saveFilters',
	WALLET_START_DOWNLOAD: '/walletReport/v2/download',
	GET_REMINDER_SETTING: '/reminderSettings?type=',
	SELF_MAIL: '/wallet/accountDetails',

	// POLICY apis
	MANAGER_MISSING_COUNT: '/admin/manager/v1/count/notExist',
	GET_POLICY_CONFIG: '/admin/policy/config',
	AUTO_SUGGEST_CITY: '/autosuggest/v5/search',
	FETCH_POLICY_DATA: '/admin/policy/getPolicyV2/bulk?metadata_required=true',
	SAVE_POLICY_DATA: '/admin/policy/createOrUpdatePolicyV2/bulk',

	// hEADER apis
	GET_HEADER_OPTS: '/navigation/flow/employee?isNewFlow=true',
	GET_PROFILE: '/v2/user/employee?needCompleteDetail=true&lob=MYBIZ',
	POST_CLAIMS_OPT_IN: '/corporate/v1/expense/lead',
	GET_CLAIMS_AUTH_TOKEN: '/corporate/client/v1/partner/auth/token',
	GET_MSR_DETAILS: '/admin/v1/myservice/search',

	GET_ADMIN_AUTH: '/corp/user/employee',

	// Cosmos RequestCallback API
	GET_REQUEST_CALLBACK: '/empeiria/cosmos/corporate/cards/requestCallback',
	GET_CALLBACK_SUPPORT: '/empeiria/cosmos/corporate/cards/callbackSupport',
	UNAUTH_REQ_CALLBACK: '/corporate/requestCallback?source=BIZ_ONBOARD',

	// Shortlist and Share APIs
	CANCEL_ITENARY: '/shortlist/cancel_itenary',
	MORE_OPTIONS: '/shortlist/cancel_itenary',
	WISH_LIST: '/wcb/wishlist/myBiz/listingPage',
	SHORTLISTED_OPTION: '/wcb/wishlist/reviewAndSharePage',
	REMOVE_PRODUCT: '/wcb/wishlist/removeProducts',
	SHARE_WISHLIST: '/wcb/wishlist/shareNow',
	TRAVEL_REQUEST_API: '/wcb/wishlist/myBiz/listingPage',
	REMIND_EMPLOYEE: '/wcb/wishlist/remindEmployee',
	CB_SINGLE_WISHLIST: '/wcb/wishlist',
	AUTH_CB_SINGLE_WISHLIST: '/wcb/auth/wishlist',
	WISHLIST_CONFIRM_STATUS: '/wcb/wishlist/confirmStatus',
	AUTH_WISHLIST_CONFIRM_STATUS: '/wcb/auth/wishlist/confirmStatus',
	CANCEL_REQUEST: '/wcb/wishlist/cancelRequest',
	AUTH_CANCEL_REQUEST: '/wcb/auth/wishlist/cancelRequest',
	LATEST_FLIGHT_FARE: '/wcb/wishlist/authenticated/user/latest/fare',
	AUTH_LATEST_FLIGHT_FARE: '/wcb/auth/wishlist/latest/fare',
	GET_APPR_DETAILS: 'clientbackend/entity/api/v1/approvals/',
	TRAVEL_REQUESTS: '/clientbackend/entity/api/v1/approvals/getApprovalList',

	// Expense Landing API's
	EXPENSE_SUMMARY: '/expense/summary',
	EXPENSE_DATA: '/expense/details',
	GET_EXPENSE_DETAILS: '/expense/getDetailsById',
	EXPENSE_HISTORY: '/expense/history',
	EXPENSE_CATEGORY: '/expense/fetchCategories',
	APPROVAL_EXPENSE_DATA: '/expense/approval',
	EXPENSE_API: '/expense',
	EXPENSE_ITEM_API: '/expense/item',
	MY_BIZ_EXPNS_LIST: '/expense/myBiz/booking/details',
	APPROVE_REJECT_EXPNS: '/expense/approveReject',
	LATEST_HOTEL_FARE: '/wcb/auth/wishlist/latest/hotel/fare',
	EXPNS_RECEIPT_UPLD: '/expense/receipt/upload',
	EXPENSE_AUTH_HISTORY: '/auth/expense/history',
	GET_EXPENSE_DETAILS_AUTH: '/auth/expense/getDetailsById',
	APPROVE_REJECT_AUTH: '/approveReject/exp',

	// Expense API urls
	GET_EXPENSE_LIST: '/admin/expense/details',
	GET_EXPENSE_COUNTS: '/admin/expense/count',
	EXPENSE_CSV_DWL: '/admin/expense/csv/saveRequest',
	EXPENSE_DWLD_URL: '/admin/expense/csv/download?downloadReportId=',
	EXPENSE_UPLOAD: '/admin/expense/csv/reimburse',
	EXPENSE_REIMBBYID: '/admin/expense/reimburse',
	SEARCH_EXPENSE: 'admin/expense/search',
	DOWNLOAD_TNE_REPORT: '/report/download/job/submit',
	BULK_REPORT_DOWNLOD: '/report/bulk/download/job/{jobId}',

	// EXPENSE CATEGORIES
	GET_CATEGORY: '/expCategories?fetchDeleted=false',
	ADD_OR_UPDATE: '/expCategory/addOrUpdate',
	DELETE_CATGORY: '/expCategory/',
	GET_POLICY_GROUPS: '/admin/expense/groups',
	GET_POLICY: '/expPolicyInBulk',
	EMPLOYEE_AUTOSUGGEST: '/v3/user/autosuggest',
	EXPENSE_MANAGER: '/admin/manager/expense/v1/count/notExist',
	GENERATE_REPORT: '/expense/report/',
	TRAVEL_N_EXPENSE_FORM: '/expense/prefix',
	SAVE_POLICY: '/expPolicyInBulk',
	GET_TNE_DATA: '/expense/prefix',

	// LANDING APIS
	GET_MYBIZ_ORGS_DET: '/onboarding/businessDetails',
	GET_MYBIZ_BENEFITS: '/onboarding/benefits',
	GET_MYBIZ_TESTIMONY: '/onboarding/testimonies',
	GET_MYBIZ_CASESTUDY: '/onboarding/caseStudies',
	GET_MYBIZ_QUESTIONS: '/onboarding/orgGeneralQuestion',
	GET_MYBIZ_GUIDLINES: '/onboarding/quotation',
	REQUEST_DEMO_FORM: 'user/v2/requestDemo',
	VERIFY_USER: '/user/verify',
	GET_FOOTER_DATA: '/fetchConfigData?configKey=seo',
	GET_CONFIG: '/fetchConfigData?configKey=',
	GET_REFERRAL_DATA: '/referral/demo/search/',

	// Dashboard Banner

	// EMPLYEE APIS
	INVITE_EMPLOYEE: '/v2/employee/invite',
	GET_GROUP: '/organization/all/groups',
	GET_EMPLOYEE_LIST: '/emp/v3/filter',
	SEARCH_EMPLOYEE_LIST: '/emp/v3/employee/fuzzySearch',
	EMPLOYEE_COUNT: '/emp/v3/count',
	GET_DESIGNATION: '/emp/v3/designation',
	BULK_UPDATE_EMP: '/emp/v3/employee/bulkUpdate',
	DWL_EMP_DATA: '/emp/v3/start/download/job',
	DWL_EMP_JOB: '/emp/v3/csv/download',
	DWL_MAILER_URL: '/emp/v3/resource',
	UPDATE_POLL: '/emp/v3/jobStatus',
	UPDATE_JOB_STATUS: '/emp/v3/updateJob',
	CREATE_GROUP: '/group/v3/createOrUpdate',
	MODIFY_EMP_DET: '/emp/v3/update',
	EMP_BULK_UPLOAD: '/hrms/v1/file/upload',
	EMP_CSV_DWNLOAD: '/hrms/v1/template/download',
	PREFERENCE_DOWNLOAD_API: '/group/v3/download-user-pref-template-csv?fileName=airline',
	PREF_BULK_UPLOAD: '/user/preference/upsert/bulk',
	VALIDATE_EMP: '/emp/v3/employee/validate',
	INVITE_CHANNELS: '/v1/employee/inviteChannels',
	GET_PARTNER_HRMS_TOKEN: '/corporate/partner-hrms/getToken',
	GET_HRMS_STATUS: '/hrms/v1/integration/status',

	// GROUP APIS
	GET_CONFLICTING_GRPS: '/group/v3/nameSimilarity',
	RESOLVE_CONFLICTS: '/group/v3/merge',
	GRP_BULK_UPLOAD: '/hrms/v1/upload/group/bulk',
	DELETE_GROUPS: 'group/v3/delete',
	TEMPLATE_CSV_DOWNLOAD: '/group/v3/download-template-csv',

	// Train Landing
	SAVE_TRAIN_DATA: '/corporate/submitTrainForm',

	// Mice Apis
	QUERY_FORM_REQUEST: '/mice/v1/data ',

	// Resource Apis
	BLOGS_DATA_REQUEST: '/resources/blogs',
	PRODUCT_VIDEO_REQUEST: '/resources/videos',
	TESTIMONIES_REQUEST: '/resources/testimonies',
	BUSINESS_DATA_REQUEST: '/resources/business',
	MYBIZ_BENEFIT_REQUEST: '/resources/mybiz/benifits',

	// Company Details Apis
	GET_ORGANIZATION_DETAILS: '/getOrganisationDetails',
	SAVE_ORGANIZATION_DETAILS: '/admin/organization/details',
	GET_CURRENCIES: '/currency/configs',
	GET_API_KEYS: '/admin/corp/fetch/organizationSecrets',
	UPDATE_API_KEYS: '/admin/corp/create/secret',

	// Trav Requisition
	POPULAR_FLIGHT_SEARCH: '//www.makemytrip.com/pwa/hlp/v3/api/flight/popular-searches/',
	SEARCH_BUS: '//ground-auto-suggest.makemytrip.com/api/v1/bus/autosuggest/search',
	HOTEL_LISTING_URL: '/hotels/hotel-listing/',
	BUS_LISTING_URL: 'https://www.makemytrip.com/bus/search',
	SEARCH_FLIGHT: mapiUrl + '/api/flights-search/autosuggest',
	SEARCH_HOTEL: mapiUrl + '/autosuggest/v5/search',
	GET_PLACE_DETAILS: mapiUrl + '/autosuggest/v5/get-place-details',
	GET_TRIPTAGS_REASONS: '/requisition/form/data',
	SEARCH_CAB_DATA: mapiUrl + '/airportAutoComplete/searchKey',
	SAVE_TRIP_FORM_DATA: '/requisition/request/upsert',
	// Travel Request Detail APIs
	TRAVEL_REQUEST_DETAILS_API: '/requisition/requestDetails?requisitionId=',
	TRAVEL_REQUEST_DETAILS_API_AUTHCODE: '/external/requisition/services/details?authCode=',
	APPR_REJ_DETAILS_API: '/clientbackend/entity/api/v1/requisition/approvals?requisitionId=',
	APPR_REJ_DETAILS_API_AUTHCODE: '/external/v1/requisition/approvals?authCode=',
	CANCEL_TRAVEL_API: '/requisition/recall/approvals',
	MARK_AS_BOOKED_API: '/requisition/request/updateBookingStatus',

	// Mobile Landing
	MYBIZ_APP_DOWNLOAD: 'https://applinks.makemytrip.com/joinMyBiz?cmp=',
	// REPORTS //

	// REPORTS SUMMARY
	GET_REPORTS_SUMMARY: '/corporate/reports/v3/summary',
	GET_GSTR_STATUS: '/gst/2A/report/setup',
	GET_CUSTOM_REPORTS: '/corp/customReport/org/data',
	GET_REPORT_DATA: '/corp/customReport/data',
	GET_ADDITIONAL_DATA: '/corp/customReport/reportType/config',
	SCHEDULER_SETTINGS: '/schedulerSettings',
	CUSTOM_REPORT_UPSERT: '/corp/customReport/upsert',
	SUBMIT_REPORT_DWL_PARAMS: '/corporate/reports/v4/submit/download/request',
	DOWNLOAD_REPORT: '/corporate/reports/v3/download/report',
	DOWNLOAD_GSTR_REPORT: '/gst/2A/report/download',
	DOWNLOAD_LEDGER_REPORT: '/v2/send/ledgerReport',

	// REPORTS DETAILS
	GET_REPORTS_DETAILS: '/corporate/reports/v3/list/records',
	GET_REPORTS_DETAILS_SEARCH: '/corporate/reports/v3/search/records',
	GET_REPORT_FILTER_COUNT: '/corporate/reports/v3/filter/count',

	// GST APIS
	GET_GST: '/gst/details',
	UPDATE_GST: '/gst/upsert',

	// DASHBOARD APIS
	GET_WALLET_DATA: '/admin/walletDetails',
	GET_SAVING_DATA: '/admin/savings-delivered?startDate=',
	GET_BANNER_DATA: '/corporate/org/supportBanners',

	// TRIP TAG APIS
	FETCH_ALL_API_URL: 'tripTag/v2/fetchAllTripTagsByGroup',
	FETCH_TRIP_API_URL: '/tripTag/v2/fetchATripTagByGroupAndUUID',
	DELETE_TRIP_API_URL: '/tripTag/v2/deleteTripTag',
	EDIT_API_URL: '/tripTag/v2/editTripTag',
	CREATE_AI_URL: '/tripTag/v2/createTripTag',
	CSV_DOWNLOAD_API_URL: '/tripTag/v1/csv/download',

	// TRAVEL REQUEST BOOKINGS
	GET_BOOKING_LIST: '/admin/booking/manage/listing',
	GET_BOOKINGS_FILTERS_COUNT: '/admin/booking/manage/count',
	MODIFY_BOOKING: '/admin/booking/manage/addTraveller',

	// ORGANIZATION DETAILS
	GET_ORGS_DETAILS: '/v1/orgDetails',
	GET_IDENTIFY_DETAILS: '/organization/identifier',
	GET_ADDRESS_DETAILS: '/organization/frequent/company/addresses/fetch',
	POST_ORG_FORM_DATA_API: APPLICATION_PATH + '/api/v1/convertSvg',
	MICE_SERVER_FORM_API: '/convertSvgs',
	POST_IDENTIFIER_DETAILS: '/organization/post/cin',
	ADD_UPDATE_ADDRESS: '/organization/frequent/company/addresses/upsert'
};
const APPLICATION_CONSTANTS = {
	HCS_SSO_LOGIN_API_ERROR_CODE: 5411,
	HCS_RESET_PASSWORD_ERROR_CODE: 5413,
	HCS_PASSWORD_EXPIRE_ERROR_CODE: 5408,
	SSO_SIGN_ON_ERROR_MESSAGE: 'Your company has set up for Single Sign On. Please use SSO login option given below to continue.',
	RESET_PASSWORD_ERROR_MESSAGE: 'Your password has expired. Please click on Forgot Password button below to change your password.',
	TWENTY_THOUSAND: '₹20,000',
	OLD_API_DATE_FORMAT: 'YYYY-MM-DD',
	DATE_FORMAT: 'YYYY/MM/DD',
	FULLNAME_LENGTH: 75,
	NEW_API_DATE: '2019/09/10',
	ONE_YEAR_TIME: 31536000000,
	ONE_HOUR_TIME: 3600000,
	MMT_COOKIE_DOMAIN: '.makemytrip.com',
	MYBIZ_COOKIE_DOMAIN: 'mybiz.makemytrip.com',
	SIGN_UP_COOKIE: 'abSignUp',
	HEADER_SIGN_UP_COOKIE: 'headerSignUp',
	ADMIN_ROLE: 'Admin',
	EMPLOYEE_ROLE: 'Employee',
	SIGNUP_AB_LOB: 'OTHERS',
	MYBIZ_CORE: 'MYBIZ_CORE',
	AB_API_TIMEOUT: 1000,
	AB_API_CLIENT_TIMEOUT: 30000,
	ONE_YEAR_COOKIE: 31556952000,
	USER_API_TIMEOUT: 3000,
	ADMIN_COOKIE: 'admin',
	HCS_USER_SERVICE_AUTHORIZATION: 'bzpiZkjclyOxuIq',
	MMT_USER_SERVICE_AUTHORIZATION: 'h4nhc9jcgpAGIjp',
	ONE_DAY_TIME: 86400000,
	MAX_COOKIE_TIME: 267 * 365,
	MMT_AUTH_COOKIE: 'mmt-auth',
	NEW_MYBIZ_LOGIN: 'mb_popup',
	TODAY: new Date(),
	QUARTER: 3,
	API_ERROR: 'Something went wrong. Please try again later',
	RUPEE: '₹',
	EMPLOYEE_TYPE: 'EMPLOYEE_INVITATION',
	INVOICE_TYPE: 'invoice',
	MYBIZ_LOGIN: 'MYBIZ_LOGIN',
	ORGANIZATION_LEVEL_GROUP_ID: '-9',
	ORGANIZATION_LEVEL_GROUP_NAME: 'Organization Group',
	SIGNUP_POPUP: 'mbOtpSignuppop',
	SUCCESS_RESPONSE_CODE: '600',
	FORBIDDEN_RESPONSE: 'Forbidden',
	ALERT_RESPONSE_CODE: '800',
	DEVICE_TYPE: 'DESKTOP',
	DEVICE_TYPE_KEY: 'deviceType',
	NOTIFICATION_THEME: 'notification',
	TOAST_THEME: 'toast',
	RESP_CODE_610: '610',
	CACHE_TIME: 900,
	AB_CACHE_TIME: 600,
	AB_COOKIE: 'ab_cache',
	PARTIAL: 'PARTIAL_UPDATED',
	SUCCESS: 'SUCCESS',
	COMPLETED: 'COMPLETED',
	FAILED: 'FAILED',
	PENDING: 'IN_PROGRESS',
	NOOP: 'NOOP',
	LOADING: 'LOADING',
	CHATBOT_DELAY: 5000,
	EXPLORE_DELAY: 5000,
	GENERIC_API_ERROR: 'Something went wrong, please try again later',
	GENERIC_ERROR_HEADING: 'API ERROR!',
	AUTO_REDIRECT: 'Auto redirecting to home page in',
	pageTitle: `One stop shop for your Corporate/Business Travel & Expense Solution`,
	pageDesc: 'Plan your Business travel, company offsite or flights & Hotels for corporate traveler with GST compliant invoice. ✓ Free Registration ✓ Complimentary Meals ✓ 100% GST Invoice Assured Amenities: 100% GST Assured Invoice.',
	BIZ_PROFILE: 'BUSINESS',
	USER_CANCEL_MSG: 'Operation canceled by the user.',
	CHARTERED_FLIGHT_SENDER_EMAIL: 'charters@gommt.onmicrosoft.com',
	LOB_COMMON: 'COMMON',
	NO_POP: 'NONE',
	KEY_FOR_AUTOCOMPLETE_AND_LAT_LONG: 'AIzaSyDTP5MPNRHEVqC9IfRZuvorUQWbIgc2YQI',
	WHATS_APP_DEEP_LINK: 'https://wa.me/919717227149?text=Hi,%20I%20would%20like%20to%20know%20more%20about%20myBiz',
	CURRENT_QUARTER: 'This Quarter',
	LAST_QUARTER: 'Last Quarter',
	LAST_QUARTER_TILL_DATE: 'Last quarter till date',
	LAST_MONTH_TILL_DATE: 'Last Month Till date',
	INDIA_COUNTRY_CODE: '91',
	ADMIN_REQUIRED_POP: {
		heading: 'Admin privileges required',
		content: 'To view this page you require admin privileges, Please contact your company admin'
	},
	OOP_TXT: 'OUT OF POLICY',
	DEFAULT_MONTHS: 1,
	DEFAULT_QTR: 'lqtr',
	NEWLY_LAUNCHED: 'Newly Launched',
	MIN_CHAR_TO_ENABLE_SEARCH: 3,
	BTN_TYPE: {
		BUTTON: 'BUTTON',
		CLICKTEXT: 'CLICKTEXT'
	},
	GET_FOOTER_DATA_KEY: 'seo',
	NOT_FOUND_ERROR_CODE: 404,
	SERVER_ERR_ERROR_CODE: 500,
	DEFAULT_FILE_SIZE_LIMIT: 1024 * 1024,
	OPEN_CONFIG_KEYS: {
		REQUEST_DEMO: 'requestDemo',
		SEO: 'seo',
		LANDING_CONFIG_KEY: 'landing'
	},
	DEFAULT_CURRENCY: {
		cCode: 'INR',
		currency: 'Indian Rupee'
	},
	POLL_INTERVAL: 2000,
	AUTO_SIGNUP_POPUP_DELAY: 5000,
	INPUT_FIELD: 'TextInput',
	TARTAN_AB_KEY: 'tartan_ab'
};
const INVOICE_CONSTANTS = getConsulData('constants')?.INVOICE || {};

const LOGIN_CONSTANTS = {
	DASHBOARD_URL: APPLICATION_PATH + '/dashboard?orgId=',
	MOBILE_EMPLOYEE_URL: 'http://applinks.makemytrip.com/SMsf/ss58WrOoaG',
	USER_INACTIVITY: 'userInactivity',
	SESSION_EXPIRY: 'sessionExpiry',
	API_ERROR: 'Something went wrong, please try again later',
	LOGIN_ERROR: 'Email id and password does not match',
	B2C_USER_ERROR: 'Please signup on myBiz in order to continue.',
	DISABLED_ERROR: 'Your account is currently not active. Please contact your company admin or drop an email to mysupport@makemytrip.com',
	EMPLOYEE_LOGIN_MESSAGE: 'You are not an admin for this company. To get admin access, please contact your company admin or drop an email to mysupport@makemytrip.com',
	MOBILE_EMPLOYEE_LOGIN_MESSAGE: 'To use myBiz on mobile, please download the MakeMyTrip app.',
	VERIFY_USER: 'Please verify email id'

};

const BROWSER_URLS = {
	LOGINPAGE: APPLICATION_PATH + '/userlogin',
	SIGNUP_PAGE: APPLICATION_PATH + '/signup',
	SIGNUP_THANK_YOU: PATHS.SIGNUP_THANK_YOU,
	LANDING_PAGE: APPLICATION_PATH + '/',
	LANDING_SME_FEST: APPLICATION_PATH + '/sme-travel-fest',
	COMPANY_DETAILS_PAGE: APPLICATION_PATH + '/companyDetails',
	DOCUMENT_UPLOAD_PAGE: APPLICATION_PATH + '/documentUpload',
	DOCUMENT_TYPE_PAGE: APPLICATION_PATH + '/documentType',
	REFER_ADMIN_PAGE: APPLICATION_PATH + '/referAdmin',
	EMPLOYEE_LANDING_PAGE: APPLICATION_PATH + '/employeeLanding',
	ADMIN_LANDING_PAGE: APPLICATION_PATH + '/adminLanding',
	REQUEST_DEMO_PAGE: PATHS.REQUEST_DEMO_PAGE,
	HCSHEADER: APPLICATION_PATH + '/hcsHeader',
	INVOICE_PAGE: APPLICATION_PATH + '/invoice',
	CHARTERED_FLIGHT: APPLICATION_PATH + '/charteredFlightrequest',
	WALLET_PAGE: APPLICATION_PATH + '/wallet',
	POLICY_PAGE: APPLICATION_PATH + '/policy',
	SHORTLIST_PAGE: APPLICATION_PATH + '/shortlist',
	MYBIZ_BENEFIT: APPLICATION_PATH + '/benefits',
	CONTACT_US: APPLICATION_PATH + '/contactus',
	REPORT_WALLET_DETAIL: APPLICATION_PATH + '/reportsDetail/wallet'
};

const WALLET_CONSTANTS = {
	API_DATE_FORMAT: 'YYYY-MM-DD',
	BOOK_DATE_FORMAT: 'DD/MM/YY',
	PAGE_LIMIT: 12,
	ZERO_AMOUNT: 0.01,
	TRXN_SORT: 'direction',
	SORT_KEY: 'direction',
	ASCENDING: 'ASC',
	DESCENDING: 'DESC',
	PARAM_SEPERATOR: '_',
	FLIGHT_TYPE: 'FLIGHT',
	HOTEL_TYPE: 'HOTEL',
	CABS_TYPE: 'CABS',
	BUS_TYPE: 'BUS',
	TRAIN_TYPE: 'TRAIN',
	MISC_TYPE: 'MISCELLANEOUS',
	DEBIT: 'DEBIT',
	CREDIT: 'CREDIT',
	DEBIT_COUNT: 'debitCount',
	CREDIT_COUNT: 'creditCount',
	START_DATE: 'startDate',
	END_DATE: 'endDate',
	DEFAULT_MONTHS: 1,
	TOTAL_FEATURES: 5,
	BALANCE_FEATURE: 1,
	RECHARGE_FEATURE: 2,
	REMINDER_FEATURE: 3,
	LIST_FEATURE: 4,
	HELP_FEATURE: 5,
	GET_STARTED_FEATURE: -1,
	LINK_NAME: 'Corporate_Menubar_wallet',
	WALLET_UI_KEY: 'new_wallet',
	ALL_COUNTS: 'ALL_COUNT',
	QTR_COUNT: 'lqtr',
	ONLINE: 'ONLINE',
	E_COLLECTION: 'E_COLLECTION',
	OFFLINE: 'OFFLINE',
	ONLINE_COUNT: 'onlineCount',
	E_COLLECTION_COUNT: 'neftCount',
	OFFLINE_COUNT: 'offlineCount',
	REMINDER_FREQUECNY: 'daily',
	REMOVED: 'REMOVED',
	WALLET_RECHARGED: 'walletRecharged',
	PAYMENT_FAILED: 'paymentFailed',
	PAYMENT_RECIEVED: 'paymentReceived',
	DISABLE_CALENDER_BEFORE: new Date(2019, 3, 1),
	WALLET_NEW_FLOW: 'walletNewFlow',
	OMNI_EVTS: {
		MAIL_TRACKEVT: 'mailer_landed',
		HELP_CLCK: 'help_clicked',
		WHAT_CLCK: 'Whatsthis_clicked'

	},
	WALLET_PAGE_NAME: 'wallet:landing_new_v3',
	WALLET_PAGE_NAME_NEW: 'wallet:landing_new_v3.1',
	DISABLE_CALENDER_BEFORE: new Date(2019, 3, 1)
};

const FILTER_CONSTANTS = {
	QTR_FILTER: 'lqtr',
	MONTH_FILTER: 'month',
	DATE_FILTER: 'dateRange',
	INVOICE_DATE_FILTER: 'invoiceDateRange',
	CUSTOM_DATE_FILTER: 'datePicker',
	DROPDOWN: 'dropdown',
	CHECKBOX: 'checkbox',
	TABTYPE: 'tabtype',
	FILTER_QUERY_FAILED: 'FILTER_QUERY_FAILED',
	INIT_QUERY_FAILED: 'INIT_QUERY_FAILED',
	SELECT: 'select',
	SELECTED: 'selected',
	REMOVED: 'removed'
};

const POLICY_CONSTANTS = {
	CHEAPER_VALS: {
		CHEAPER_WITH_DELTA: 'CHEAPER_WITH_DELTA'
	},

	PRIVACY_POLICY: 'Privacy Policy',
	USER_AGREEMENT: 'User Agreement',
	TERMS_OF_SERVICES: 'Terms of Services',
	PRIVACY_POLICY_URL: 'https://www.makemytrip.com/legal/privacy_policy.html',
	USER_AGREEMENT_URL: 'https://www.makemytrip.com/legal/user_agreement.html',
	TERMS_OF_SERVICES_URL: 'https://www.makemytrip.com/legal/user_agreement.html#tos',
	VOUCHER_TERMS_AND_CONDITIONS: 'https://promos.makemytrip.com/mybiz-admin-referral-terms.html'
};

const REGEX_PATTERNS = {
	MAXLEN: '^(?=.{len,})',
	GST_REGEX: /[0-9]{2}[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9A-Za-z]{1}[A-Za-z]{1}[0-9a-zA-Z]{1}/,
	PAN_REGEX: /^[A-Za-z]{3}[AaBbCcFfGgHhLlJjPpTt][A-Za-z][0-9]{4}[A-Za-z]$/,
	DEF_PHONE_REGEX: /^(([0-9]{2})(\*{6}|[0-9]{6})[0-9]{2})$/i,
	PHONE_REGEX: /^(0|[1-9][0-9]{9})$/i,
	INTERNATIONAL_PHONE_REGEX: /^(0|[1-9][0-9]{4,15})$/i,
	EMAIL_REGEX: /(?=^.{6,80}$)(^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]+$)/i,
	EXP_ID: /[a-z]\-[0-9]/i,
	AADHAR_REGEX: /^[0-9]{12}$/i,
	PASSPORT_REGEX: /^[A-PR-WYa-pr-wy][1-9][0-9]{5}[1-9]$/,
	BOOKID_REGEX: /[n]/i,
	FILE_CACHE: /(wishlist)|(EmpSharedOptions)|(expense)|(invoice)|(signupflow)|(PostLoggedin)|(shortlist)|(employee)|(group)|(wallet)|(policy)|(dashboard)|(provisional)|(reports)|(chartered)|(referadmin)|(referadmin)|(referCompany)|(train)|(triptag)/i,
	LOG_DATE_FORMATTER: /[A-Z]{3}\+/,
	MMT_DOMAIN_URLS: /^[^.]*$|(makemytrip\.com)/,
	ESCAPE_CHARACTER: /[.*+?^${}();"&|[\]\\]/g,
	NGO_ID_REGEX: /[A-Z]{2}[12][0-9]{3}[0-9]{7}/i,
	UDYAM_REGEX: /UDYAM[A-Z]{2}[0-9]{2}[0-9]{7}/i,
	LOG_EMAIL_REGEX: /(=|:)[\w-\.]+@/,
	NUMERIC_ONLY_REGEX: /^[0-9]+$/,
	ALPHANUMERIC_REGEX: /^[a-zA-Z0-9]+$/
};

const SHORTLIST_URLS = [
	PATHS.SHORTLIST_PAGE,
	PATHS.SHORTILSTED_OPTIONS,
	PATHS.CB_WISHLIST,
	PATHS.EMP_SHARED_OPTIONS,
	PATHS.TRAVEL_REQUEST_PAGE
];

const SHORTLIST_SHARE_CONSTANTS = {
	CANCEL_REQ_MODAL: 'CANCEL_REQ_MODAL',
	REQ_MORE_MODAL: 'REQ_MORE_MODAL',
	CONFIRM_MODAL: 'CONFIRM_MODAL',
	WINDOW_SCROLL_Y_OFF_SET: 335,
	CB: 'CB',
	PRIMARY_PAX: 'PRIMARY_PAX',
	TRIP_TEXT: {
		'MC': 'Multi City Trip',
		'O': 'Oneway Trip',
		'R': 'Round Trip'
	},
	CAB_TYPES: {
		ONE_WAY: 'OW',
		ROUND_TRIP: 'RT',
		AIRPORT_TRANSFER: 'AT'
	},
	FLIGHT_TYPES: {
		ONE_WAY: 'O',
		ROUND_TRIP: 'R',
		MULTI_CITY: 'M'
	},
	APPR_ROLE: 'APPROVER',
	REQ_ROLE: 'REQUESTER',
	LATO_DATE: 'ddd, [<span class=\'latoBlack\'>]DD MMM\'[</span>] YYYY',
	CARD_DATE_FORMAT: 'ddd, DD MMM',
	REQ_CARD_DATE_FORMAT: 'ddd, DD MMM YY',
	EMP_SHARED: 'EMP_SHARED',
	SELECTION_PAGE: 'SELECTION_PAGE'
};

const TRF_DETAILS_CONSTANTS = {
	APPR_ROLE: 'APPROVER',
	REQ_ROLE: 'REQUESTER',
	PENDING: 'PENDING',
	APPROVED: 'APPROVED',
	BOOKED: 'BOOKED',
	REJECTED: 'REJECTED',
	RECALLED: 'RECALLED',
	CENTRAL_BOOKER: 'CENTRAL_BOOKER',
	LATO_DATE: 'ddd, [<span class=\'latoBlack\'>]DD MMM\'[</span>] YYYY',
	CAB_DATE_FORMAT: 'ddd, [<span>]DD MMM\'[</span>] YY([<span>]hh:mm A\'[</span>])',
	CAB_DATE_FORMAT_TRF_DETAILS: 'ddd, [<span class=\'latoBlack\'>]DD MMM\'[</span>] ([<span class=\'latoBlack\'>]hh:mm A\'[</span>])',
	CARD_DATE_FORMAT: 'ddd, MMM DD',
	TRAVEL_INFORMATION_FORMAT: 'DD MMM ddd, YYYY',
	REJECT_REQ_MODAL: 'REJECT_REQ_MODAL',
	APPROVE_REQ_MODAL: 'APPROVE_REQ_MODAL',
	MARK_AS_BOOKED_MODAL: 'MARK_AS_BOOKED_MODAL',
	OTHERS: 'OTHERS',
	ERROR_MODAL: 'errorPop'
};

const LANDING_CONSTS = {
	ALL: 'ALL',
	SIGNUP: 'SIGNUP',
	DEMO: 'DEMO',
	PROGRESS: 'progress',
	SUCCESS: 'success',
	LINK_NAME: 'new_landing_page_click_tracker',
	LANDING_PAGE_VIEW: 'landingpage:landing_new_v3',
	SME_LANDING_VIEW: 'landingpage:sme_travel',
	MOBILE_LANDING_PAGE_VIEW: 'mweb_mybiz_homepage',
	DROPOFF_DELAY: 10000,
	TRAV_FEST_URL: 'https://promos.makemytrip.com/images/myBiz/travelfestjune1.png',
	REQ_CALLBACK: 'REQUESTCALLBACK'
};

const PAGE_NAME_CONST = {
	CB_WISHLIST: 'CB_WISHLIST',
	EMP_OPTION_SHARED: 'EMP_OPTION_SHARED',
	EXPENSE_REIMB: 'EXPENSE_REIMB'
};

const EXPENSE_CONSTANTS = {
	ADD_MODAL: 'ADD_MODAL',
	EDIT_MODAL: 'EDIT_MODAL',
	ERROR_MODAL: 'ERROR_MODAL',
	APPROVE_MODAL: 'APPROVE_MODAL',
	REJECT_MODAL: 'REJECT_MODAL',
	DATE_FORMAT: 'DD MMM\' YY',
	DATE_MONTH_FORMAT: 'DD MMM YYYY',
	HISTORY_DATE_FORMAT: 'DD MMM\' YYYY, h:mm A',
	IMG_PRVW_MODAL: 'IMG_PRVW_MODAL',
	EXPNS_ITEM_DELETE: 'EXPNS_ITEM_DELETE',
	NAME_SAVE_MODAL: 'NAME_SAVE_MODAL',
	NAME_SUBMIT_MODAL: 'NAME_SUBMIT_MODAL',
	EXPENSE_DELETE: 'EXPENSE_DELETE',
	TOTAL_FEATURES: 3,
	SUMMARY_FEATURE: 1,
	LIST_FEATURE: 2,
	NEW_EXPENSE_FEATURE: 3,
	EXPENSE_UI_KEY: 'expense_ui',
	ADD_EXPENSE: 'ADD_EXPENSE',
	DELETE_EXPENSE: 'DELETE_EXPENSE',
	API_DATE_FORMAT: 'time',
	QTR_COUNT: 'lqtr',
	ASCENDING: 'ASC',
	DESCENDING: 'DESC',
	DEF_SORT_KEY: 'updated',
	DATE_SORT_KEY: 'updated',
	SUBMIT_SORT_KEY: 'updated',
	REIMBURSED: 'REIMBURSED',
	PEND_REIMB_KEY: 'PENDING_REIMBURSEMENT',
	REIMBURSED_KEY: 'REIMBURSED',
	DEF_TAB: 'PEND_REIMB_KEY',
	PAGE_LIMIT: 8,
	START_DATE: 'startDate',
	END_DATE: 'endDate',
	SHOW_DATE_FORMAT: 'DD MMM YYYY',
	LIST_LOAD: 'LIST_LOAD',
	LIST_SCROLL: 'LIST_SCROLL',
	DEFAULT_DAYS: -90,
	REIMB_POPUP: 'reimb_pop',
	REIMBURSEMENT_PAGE_EVT: 'Expense_reimbursement',
	REIMB_CLICKEVT: 'Expense_reimbursement_click',
	DOWNLOAD_OVERLAY: 'DOWNLOAD_OVERLAY',
	EMAIL_OVERLAY: 'EMAIL_OVERLAY',
	EMAIL_COUNT_LIMIT: 100,
	LANDING_PAGE_NAME: 'Expense_listing',
	DETAIL_PAGE_NAME: 'Expense_details',
	VIEW_ALL_HEIGHT: '643px',
	VIEW_LESS_HEIGHT: '294px',
	CATEGORY_HEIGHT: 'calc(100vh - 234px)',
	DETAIL_TYPE: 'expensePolicy'
};

const EMPLOYEE_CONSTANTS = {
	HRMS_MODAL: 'HRMS_MODAL',
	INVITE_EMP: 'INVITE_EMP',
	INVITE_LINK_EMP: 'INVITE_LINK_EMP',
	ADD_EMP_MANAGER: 'ADD_EMP_MANAGER',
	INVITE_EMP_MGR: 'INVITE_EMP_MGR',
	PAGE_LIMIT: 8,
	CHANGE_EMP_PROP: 'CHANGE_EMP_PROP',
	NEW_GROUP_MODAL: 'NEW_GROUP_MODAL',
	GROUPS_KEY: 'groups',
	MANAGER_KEY: 'managers',
	ROLE_KEY: 'roles',
	TYPE_KEY: 'types',
	RESTORE_KEY: 'restore',
	RESTORE_INVITE_KEY: 'restore_and_invite',
	REINVITE_KEY: 're_invite',
	REVERIFY_KEY: 're_verify',
	STATE_KEY: 'statusList',
	DESIGNATION_KEY: 'designations',
	DELETE_KEY: 'delete',
	CHANGE_GRP_KEY: 'changeGroup',
	EMP_SUCCESS: 'EMP_SUCCESS',
	VIP_EXCEED: 'VIP_EXCEED',
	EMP_CSV_DOWNLOAD: 'startEmpDwl',
	EMP_CSV_UPLOAD: 'satrtEmpUpl',
	BULK_UPLD_MODAL: 'BULK_UPLD_MODAL',
	ONBOARDING_MODAL: 'ONBOARDING_MODAL',
	TOTAL_FEATURES: 4,
	HELP_FEATURE: 3,
	LIST_FEATURE: 2,
	UPLD_PREF_FEATURE: 4,
	TOP_FEATURE: 1,
	EMP_UI: 'emp_ui',
	DETAIL_TYPE: 'expensePolicy',
	EMAIL_DWL: 'EMAIL_DWL',
	CREATEGRP_SUCCESS: 'CREATEGRP_SUCCESS',
	BULK_STATUS_POP: 'BULK_STATUS_POP',
	EMP_NO_INVITELINK_SUCCESS: 'EMP_NO_INVITELINK_SUCCESS',
	LAST_DUR: 500,
	UPDATE_SPEED_REC_P_S: 100,
	EMP_UPDATE: 'EMP_UPDATE',
	EMP_PAGE_OMNI: 'Employee_listing',
	INVITE_CHANNEL: 'INVITE_CHANNEL',
	PREF_UPLOAD_SUCCESS: 'PREF_UPLOAD_SUCCESS',
	HRMS_STATUS: {
		'SUCCESS': 'SUCCESS',
		'PENDING': 'PENDING',
		'SYNCED': 'SYNCED',
		'API_FAILED': 'API_FAILED',
		'FAILED': 'FAILED',
		'NOT_INITIATED': 'NOT_INITIATED'
	},
	HRMS_TOKEN_KEY: 'hrmsSyncSdkToken',
	TARTAN_EVENTS: {
		CLOSE: {
			name: 'sdkWindow.close'
		},
		MODE_SELECT: {
			name: 'hrmsConnectionParams.submit',
			format: { flowType: 'HRMS' }
		},
		HRMS_SELECT: {
			name: 'hrms.select',
			format: { hrmsName: 'greythr' }
		},
		CONNECT: {
			name: 'hrmsConnectionParams.submit',
			format: { flowType: 'HRMS' }
		},
		CONNECT_SUBMIT: {
			name: ' hrmsConnectionParams.submit ',
			format: { hrmsName: 'greythr' }
		}
	}
};

const MICE_CONSTANTS = {
	PAGE_NAME: 'MICE_page',
	MICE_CLICKEVT: 'MICE_click',
	MONTH_FORMAT: 'MMM YYYY',
	PROGRESS: 'progress',
	SUCCESS: 'success',
	HEADING: 'Tell Us Your Requirements',
	SUBHEADING: 'Just fill out the form, and our team of MICE booking experts will get back to you shortly with suitable options.',
	SUCCESS_HEADING: 'Request Submitted !',
	SUCCESS_DESC1: 'Our representative will contact you within 24 hours to confirm the appointment',
	SUCCESS_DES2: 'To change or cancel the appointment, please contact our customer care.'
};

const RESOURCE_CONSTANT = {
	ANIMATE_INTERVAL: 5000,
	PAGE_NAME: 'resource',
	PAGE_VIEW: 'landingpage:resource_page'
};

const EXPENSE_ADMIN_CONST = {
	CATEGORY_PAGE: 'Expense_categories',
	POLICY_PAGE: 'Expense_policy',
	TnE_PAGE: 'Expense_T&E_Form_Settings',
	REPORT_PAGE: 'Expense_Report',
	TOTAL_FEATURES: 3,
	ELIGIBILITY_FEATURE: 2,
	CAT_FEATURE: 1,
	CAT_LIST_FEATURE: 3,
	EXPENSE_ADMIN_UI: 'expense_admin_ui'
};

const REPORTS_CONSTANT = {
	API_DATE_FORMAT: 'YYYY-MM-DD',
	START_DATE: 'startDate',
	END_DATE: 'endDate',
	GST_STATUS_NOT_FILED: 'NOT_FILED',
	GST_STATUS_PROCESSING: 'PROCESSING',
	GST_STATUS_READY: 'READY',
	SCHEDULER_MODAL: 'schedulerSetting',
	DOWNLOAD_REPORT_MODAL: 'downloadReport',
	SUCCESS_MODAL: 'success',
	DELETE_MODAL: 'delete',
	NEW_REPORT_MODAL: 'newReport',
	EDIT_REPORT_MODAL: 'editReport',
	DOWNLOAD_REPORT_TYPE_MODAL: 'dwnldReportTypeModal',
	DOWNLOAD_CUSTOM_REPORT_MODAL: 'dwnldCustomRepotModal',
	DOWNLOAD_REPORT_FORMAT: 'MMM DD, YY',
	VIEW_MORE: 'VIEW MORE',
	VIEW_LESS: 'VIEW LESS',
	IS_COACHMARKS: 'rep_coachmark',
	DEFAULT_MONTHS: 2,
	LIST_FEATURE: 1,
	SUMM_TOTAL_FEAT: 2,
	HELP_FEAT: 2,
	GST_FEAT: 4,
	TOTAL_FEATURES: 2,
	DETAIL_COACHMARK: 'rep_det_coachmark',
	DETAIL_LIST_FEAT: 1,
	DETAIL_FILTER_FEAT: 2,
	DETAIL_HELP_FEAT: 3,
	DETAIL_TOTAL_FEAT: 3,
	DETAILS_PAGE_LIMIT: 8,
	DEAFULT_BOOKING_ACTION: { bookingAction: ['BOOKED'] },
	DEAFULT_NO_ACTION: { bookingAction: [] },
	DEFAULT_REPORTS_FIELD_PREFERENCE: 'DEFAULT_FIELDS',
	ALL_FIELDS: 'ALL_FIELDS',
	DEAFULT_DOWNLOAD_REPORT_TYPE: 'RELATED_ACTIONS',
	OTHER_DOWNLOAD_REPORT_TYPE: 'BOOKING_ACTION',
	/** Filter type constants starts  */
	BOOKING_ACTION: 'bookingAction',
	POLICY: 'policyType',
	TRIP_TYPE: 'tripType',
	CAB_TYPE: 'cabType',
	SYSTEM_SUGGESTED_BOOKING: 'suggestedBookingsType',
	ACTION_DATE: 'actionDate',
	DATE_RANGE: 'dateRange',
	/** Filter type constants ends*/
	REPORT_SUMMARY: 'REPORT_SUMMARY',
	REPORTS_DETAILS: 'REPORT_DETAILS',
	ACTION_LEVEL: 'ACTION',
	INVOICE_LEVEL: 'INVOICE',
	OFFLINE: 'offline',
	LEDGER_MODAL: 'LEDGER_MODAL',
	MAILER_MODAL: 'MAILER_MODAL'
};

const EXPENSE_URLS = [
	PATHS.EXPENSE_LANDING,
	PATHS.EXPENSE_DETAILS,
	PATHS.EXPENSE_REIMBURSE,
	PATHS.TRAVEL_EXPENSE,
	PATHS.EXPENSE_DETAILS,
	PATHS.EXPENSE_ADMIN
];

const SEO_URLS = [
	PATHS.EXPENSE_SEO,
	PATHS.MICE_PAGE,
	PATHS.RESOURCE_PAGE
];

const REPORTS_URLS = [
	PATHS.REPORT_REVAMP,
	`${PATHS.REPORT_DETAIL_VIEW}:reportType`
];

const WALLET_URLS = [
	PATHS.WALLET_PAGE,
	`${PATHS.REPORT_DETAIL_VIEW}wallet`
];

const FILE_TYPE = {
	IMAGE: 'IMAGE',
	PDF: 'PDF',
	MUSIC: 'MUSIC',
	VIDEO: 'VIDEO',
	CSV: 'CSV',
	EXCEL: 'EXCEL'
};

const URL_PROPS = {
	CACHEABLE: [BROWSER_URLS.LANDING_PAGE, PATHS.RESOURCE_PAGE],
	NO_PRELOAD: [BROWSER_URLS.LANDING_PAGE]
};

const SORT_KEY = {
	DESCENDING: 'DESC',
	ASCENDING: 'ASC'
};

const GROUP_CONSTANTS = {
	GROUP_NAME: 'groupName',
	GROUP_DESC: 'groupDesc',
	ENABLE_2FA: 'mfaEnabled',
	CREATE_GROUP_MODAL: 'CREATE_GROUP_MODAL',
	CREATE_GRP_SUCCESS_MODAL: 'CREATE_GRP_SUCCESS_MODAL',
	CONFLICTING_GROUP_MODAL: 'CONFLICTING_GROUP_MODAL',
	GRP_BULK_UPLOAD: 'GRP_BULK_UPLOAD',
	TOTAL_FEATURES: 3,
	HELP_FEATURE: 3,
	BULK_UPLOAD_GRP_FEATURE: 2,
	ORG_GRP_FEATURE: 1,
	BULK_STATUS_POP: 'BULK_STATUS_GRP_POPUP',
	GRP_UI_FEATURE: 'grp_ui',
	CREATE_GRP_MODAL_TYPE: {
		EDIT_GRP: 'EDIT_GRP',
		ADD_MEMBER: 'ADD_MEMBER',
		CREATE_GRP: 'CREATE_GRP'
	},
	EDIT_GRP_MODAL: 'EDIT_GRP_MODAL',
	ADD_MEMBER_GRP: 'ADD_MEMBER_GRP',
	GRP_UPDATE: 'GRP_UPDATE',
	SUCCESS_MODAL: 'successModal',
	ONBOARDING_MODAL: 'ONBOARDING_MODAL',
	GRP_PAGE_OMNI: 'Groups_listing',
	GRP_DTL_PAGE_OMNI: 'Groups_details'
};

const REQUISITION_CONSTS = {
	GRP_TRAVS_MODAL: 'GRP_TRAVS_MODAL',
	GUEST_TRAVS_MODAL: 'GUEST_TRAVS_MODAL',
	API_MODALS: 'API_MODALS_REQ',
	BOOKFOR: {
		MYSELF: 'MYSELF',
		GROUP: 'GROUP',
		GUEST: 'GUEST'
	},
	BOOKFORTXT: {
		'GROUP': 'Group',
		'GUEST': 'Guest'
	},
	tagInputType: {
		RADIO: 'RadioButton',
		TEXTBOX: 'FreeText',
		MULTISELECT: 'MultiSelect',
		DROPDOWN: 'DropDown',
		MULTISELECTDROPDOWN: 'multiDropDown'
	},
	TRAVELLER_SECTION: 'TRAVELLER',
	SERVICES_SECTION: 'SERVICES',
	REVIEW_SECTION: 'REVIEW',
	MAX_OPTIONS: 5,
	CAB_TRIP_TYPE: {
		PICKUP: 'pickupDetails',
		DROP: 'dropDetails'
	},
	CAB_DATE_FORMAT: 'ddd, [<span class=\'latoBlack\'>]DD MMM\'[</span>] YYYY([<span class=\'latoBlack\'>]hh:mm A\'[</span>])',
	API_TyPE: {
		MAPI: 'mapi',
		GOOGLE: 'google'
	},
	API_CONSTS: {
		REASONS_TRIP_TAGS: 'REASONS_TRIP_TAGS',
		PROCEED_SUBMIT: 'PROCEED_SUBMIT'
	}
};

const GST_CONSTANTS = {
	MARK_HEADQTR: 'MARK_HEADQTR',
	DELETE: 'DEL_GST',
	EDIT: 'EDIT_GST'
};

const LOGIN_REDIRECT_SERVER_PATHS = [
	PATHS.LANDING_PAGE,
	PATHS.RESOURCE_PAGE
];

const MODAL_NAMES = {
	WHATSAPP_CALLBACK: 'WHATSAPP_CALLBACK',
	REQ_DEMO: 'requestDemo',
	DROPOFF: 'DROPOFF',
	MAILER_SUBSCRIBE: 'MAILER_SUBSCRIBE',
	GUIDLINES: 'GUIDLINES',
	PRODUCT_VIDEO: 'PRODUCT_VIDEO',
	MYBIZ_VIDEO: 'MYBIZ_VIDEO',
	STATUS: 'STATUS'

};

const MRB_CONSTANTS = {
	PAGE_LIMIT: 10,
	EMAIL_KEY: 'email',
	BOOKING_ID_KEY: 'bookingId',
	PENDING_REQUEST_TYPE: 'PENDING',
	UPCOMING_REQUEST_TYPE: 'UPCOMING',
	POLICY: 'policyType',
	TRIP_TYPE: 'tripType',
	APPROVER: 'approvalEmailIds',
	DATE_RANGE: 'dateRange',
	AGE_RANGE: 'ageRange',
	FILTER_FEATURE: 3,
	LIST_FEATURE: 2,
	TOTAL_FEATURE: 3,
	PENDING_NO_OF_DAYS: 7,
	UPCOMING_NO_OF_DAYS: 90
};

const NO_LOGIN_REDIRECT_QUERY = ['unSubscribeMailer', 'userverify'];

const MOBILE_REDIRECT_URLS = new Map([[PATHS.INVITE_PAGE, PATHS.BIZ_PWA_URL]]);

const REDIRECT_URLS = new Map([
	[PATHS.SIGNUP_PAGE, PATHS.LANDING_LOGIN_PAGE],
	[PATHS.REQUEST_DEMO_PAGE, PATHS.REQUEST_DEMO_PAGE_NEW],
	[PATHS.LOGINPAGE, PATHS.LANDING_LOGIN_PAGE],
	[PATHS.EMPLOYEE_LANDING_PAGE, `${PATHS.MYBIZ_BENEFIT}?type=EM`],
	[PATHS.ADMIN_LANDING_PAGE, `${PATHS.MYBIZ_BENEFIT}?type=TM`],
	[PATHS.MICE_PAGE, PATHS.MICE_MMT],
	[PATHS.TRAVEL_REQ_FORM, PATHS.MMT_TRAVEL_REQ_FORM],
	[PATHS.TRAVEL_REQUEST_DETAILS, PATHS.MMT_TRAVEL_REQUEST_DETAILS],
	// [PATHS.REPORT_DOWNLOAD_OLD, PATHS.REPORT_DOWNLOAD],
	[PATHS.REPORT_DOWNLOAD, PATHS.REPORT_REVAMP],
	[PATHS.PROCEED_TO_CORP, PATHS.DASHBOARD],
	[PATHS.PROVISIONAL_ADMIN_PAGE, PATHS.MMT_BIZ_PROFILE_URL],
	[PATHS.TRAVEL_REQUEST_PAGE, PATHS.MMT_TRAVEL_REQUEST_PAGE],
	[PATHS.GIFT_CARDS_URL, PATHS.MMT_GIFT_CARD_URL],
	[PATHS.GST_OLD, PATHS.GST_PAGE],
	[PATHS.VENUES_URL, PATHS.VENUES_MICE],
	[PATHS.CORP_BULK_BOOKING, PATHS.MICE_MMT],
	[PATHS.CORP_BULK_BOOKING_LEADS, PATHS.MICE_MMT],
	[PATHS.CORP_BULK_BOOKING_THANK_YOU, PATHS.MICE_MMT]
]);

const LANDING_TEMPLATE_PATHS = [
	BROWSER_URLS.LANDING_PAGE,
	BROWSER_URLS.MYBIZ_BENEFIT,
	BROWSER_URLS.LANDING_SME_FEST,
	BROWSER_URLS.CONTACT_US,
	PATHS.INVITE_PAGE,
	PATHS.USERVERIFY_PAGE];

const MOBILE_PAGES = [
	PATHS.EMP_SHARED_OPTIONS,
	PATHS.TRAVEL_INTEGRATION_LANDING
];

const POPUP_CONSTS = {
	SUCCESS_POP: 'SUCCESS',
	ERR_POPUP: 'ERR_DEFAULT'
};

const LOB_TYPES = {
	FLIGHT: 'FLIGHT',
	HOTEL: 'HOTEL',
	CABS: 'CABS',
	BUS: 'BUS',
	REQUISITION: 'REQUISITION'
};

const INTEGRATION_AWARNESS_CONSTANTS = {
	LANDING_CONFIG_KEY: 'landing',
	LANDING_REDUX_KEY: 'integrationLandingData',
	DETAIL_REDUX_KEY: 'integrationDetailData',
	SUPPORT_TEXT: 'Support',
	INTEGRATION_URL_REGEX: /\/integrated-travel-solutions\/.+/,
	COPIED: 'COPIED',
	COPY: 'COPY'
};

const COMPANY_DETAILS_CONSTS = {
	UPDATE_KEYS: 'UPDATE',
	GET_KEYS: 'GET_KEYS',
	CLIENT_KEY: 'client',
	EXP_CLIENT_ID: 'expenseclientid',
	EXT_ORG_ID: 'externalorgid',
	INTERNAL: 'INTERNAL'
};

const ORGANIZATION_DETAILS_CONSTANT = {
	ORG_DETAILS_MODAL: 'orgDetails',
	IDENTIFIER_MODAL: 'identifier',
	DELETE_MODAL: 'deleteModal',
	IDENTIFICATION_NUM_KEY: 'identificationNumber',
	BILLING_ADDRESS_KEY: 'billingAddress',
	IDENTIFICATION_TYPE_KEY: 'identificationType',
	PINCODE_KEY: 'pinCode',
	ADDRESS_ALIAS_KEY: 'addressAlias',
	CITY_KEY: 'city',
	UPDATE_KEY: 'UPDATE_KEY',
	GET_KEYS: 'GET_KEYS',
	HEADQUARTER_BOOLEAN_KEY: 'isHeadQuarter',
	SECONDARY_IMAGES_OPACITY: 33,
	CLIENT_KEY: 'client',
	EXP_CLIENT_ID: 'expenseclientid',
	EXT_ORG_ID: 'externalorgid',
	INTERNAL: 'INTERNAL',
	VALID_DELETION_GST: 'isValidForTripTag',
	ADDRESS_PREFIX: 'addressPrefix',
	COMPANY_IDENTIFICATION_NAME_KEY: 'companyName',
	UPDATE_TYPE: {
		ADDRESS: 'ADDRESS',
		ID: 'ID'
	}
};

module.exports = {
	PATHS,
	APPLICATION_CONSTANTS,
	BROWSER_URLS,
	POLICY_CONSTANTS,
	REGEX_PATTERNS,
	LOGIN_CONSTANTS,
	INVOICE_CONSTANTS,
	WALLET_CONSTANTS,
	FILTER_CONSTANTS,
	SHORTLIST_URLS,
	SHORTLIST_SHARE_CONSTANTS,
	TRF_DETAILS_CONSTANTS,
	PAGE_NAME_CONST,
	EXPENSE_URLS,
	SEO_URLS,
	EXPENSE_CONSTANTS,
	FILE_TYPE,
	URL_PROPS,
	LANDING_CONSTS,
	EXPENSE_ADMIN_CONST,
	EXPENSE_ADMIN_PATHS,
	EMPLOYEE_CONSTANTS,
	SORT_KEY,
	GROUP_CONSTANTS,
	MICE_CONSTANTS,
	RESOURCE_CONSTANT,
	LOGIN_REDIRECT_SERVER_PATHS,
	MODAL_NAMES,
	NO_LOGIN_REDIRECT_QUERY,
	REDIRECT_URLS,
	REQUISITION_CONSTS,
	REPORTS_URLS,
	REPORTS_CONSTANT,
	WALLET_URLS,
	LANDING_TEMPLATE_PATHS,
	GST_CONSTANTS,
	POPUP_CONSTS,
	MRB_CONSTANTS,
	LOB_TYPES,
	INTEGRATION_AWARNESS_CONSTANTS,
	APPLICATION_PATH,
	V2_APPLICATION_PATH,
	COMPANY_DETAILS_CONSTS,
	ORGANIZATION_DETAILS_CONSTANT,
	MOBILE_PAGES,
	MOBILE_REDIRECT_URLS
};
