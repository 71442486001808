import React, { useContext } from 'react';

import { ASSET_PATH } from 'assetsPath';
import BannerCarousel from './BannerCarousel';

import { bannerCarouselData, smeBannerCarouselData } from '../../config';
const videoPreview = `${ASSET_PATH.IMAGES}landing/videoPreview.png`;
const videoPreviewMobile = `${ASSET_PATH.IMAGES}landing/mobile/videoPreview.png`;
const mybizLogo = `${ASSET_PATH.IMAGES}landing/myBizLogo_light.png`;

const videoPreviewwebp = `${ASSET_PATH.IMAGES}landing/videoPreview.webp`;
const videoPreviewMobilewebp = `${ASSET_PATH.IMAGES}landing/mobile/videoPreview.webp`;

const mainImagePng = `${ASSET_PATH.IMAGES}landing/home-page-banner.png`;
const mainImageWebP = `${ASSET_PATH.IMAGES}landing/home-page-banner.webp`;
import landingContext from '../../landingContext';
import { isMobileContext } from '../../../UtilityComponents/Util';
import DisplayContext from '../../../UtilityComponents/displayContext';
import { trackClickEvents } from '../../utils';
import { LANDING_CONSTS } from 'AppConstants';

const MainCarousel = (props = {}) =>{
	const { isSME, onboardData, isCorpSeo, additionalSeoData = {} } = props || {};
	const appContext = useContext(landingContext) || {};
	const displayName = useContext(DisplayContext).isMobile;
	const { toggleModal, bizModal } = appContext;
	const isMobile = isMobileContext(displayName);
	const { seoImageLink } = additionalSeoData;
	const imagePreview = seoImageLink ? seoImageLink : isSME ? onboardData.imageLink : false;
	return (
		<BannerCarousel
			isCorpSeo={isCorpSeo}
			mybizLogo={mybizLogo}
			mainImages={{ mainImagePng, mainImageWebP }}
			videoPreviews={{ videoPreview: isSME ? false : videoPreview, videoPreviewwebp, videoPreviewMobilewebp, videoPreviewMobile }}
			bannerCarouselData={isSME ? smeBannerCarouselData : bannerCarouselData}
			toggleModal={toggleModal}
			bizModal={bizModal}
			isMobile={isMobile}
			imagePreview={imagePreview}
			trackClickEvents={trackClickEvents}
			additionalSeoData={additionalSeoData}
			{...props}
		/>
	);
};

export default MainCarousel;
