import React, { useState, useEffect, useContext, memo, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';

import { logPageLoadEvents } from '../logging/omnitureService';
import { LANDING_CONSTS, APPLICATION_CONSTANTS, MODAL_NAMES, PATHS } from 'AppConstants';

import Loader from 'common/Loader/Loader';
import BannerCarousel from './components/BannerCarousel';
import BuisnessPartners from './components/BuisnessPartners/buisnessPartner';
import Benefits from './components/Benefits';
import GettingStarted from './components/GettingStarted';
import DownloadSection from './components/DownloadSection';
import Questionnaire from './components/Questionnaire';
import CaseStudies from './components/CaseStudies';
import Testimonials from './components/Testimonial';
import SignUpFooter from './components/SignUpFooter/SignUpFooter';
import ExploreMore from './components/exploreMore';
import StatusPopup from 'common/statusPopup/statusPopup';
import StickyFooter from './components/StickyFooter/StickyFooter';

import DisplayContext from 'UtilityComponents/displayContext';
import { GenericDiv, ResponsiveContainer, HiddenElem } from 'commonStyles';
import landingContext from './landingContext';
import { userVerify } from './landingApi';

const LandingApp = ({
	isScollView,
	updateShowComp,
	visibleComps,
	navClick,
	updateCurrElem,
	businessDetails = {},
	pageStripName,
	isSME,
	isCorpSeo,
	children,
	additionalSeoData
}) => {
	const history = useHistory();

	const [showExplore, setShowExplore] = useState('');
	const [isActive, setIsActive] = useState(false);
	const [isTimeout, setIsTimeout] = useState(false);
	const [statusData, setStatusData] = useState({});

	const displayName = useContext(DisplayContext).isMobile;
	const { bizModal, toggleModal, appSsrData = {}, onSignUpClicked } = useContext(landingContext) || {};
	const isMobile = displayName == 'mobile';
	const { search, pathname } = useLocation() || {};

	const isUserVerifyPage = pathname === PATHS.USERVERIFY_PAGE;

	const { unSubscribeMailer } = queryString.parse(search, { parseNumbers: true, parseBooleans: true }) || {};

	const bizData = appSsrData?.bizDetails || businessDetails;

	useEffect(()=>{
		if (typeof unSubscribeMailer !== 'undefined') {
			let popData = {
				heading: unSubscribeMailer === 'true' ? 'UNSUBSCRIBED' : 'UNSUBSCRIBTION FAILED',
				content: unSubscribeMailer === 'true' ? 'Thanks for your input. We have duly noted down your preference of travel' : false,
				apiStatus: unSubscribeMailer === 'true' ? 'SUCCESS' : 'ERR_DEFAULT'
			};
			setStatusData(popData);
			toggleModal(MODAL_NAMES.STATUS);
		}
	}, [unSubscribeMailer]);

	const { corp_email: corpEmail, verification_code: verificationCode } = queryString.parse(search, { parseNumbers: true, parseBooleans: true }) || {};

	const handleStatusModal = useCallback(()=>{
		toggleModal(false);
		history && history.replace({ pathname: PATHS.LANDING_PAGE });
	}, []);

	const verifyInvitedUser = useCallback(async () => {
		let resp = null;
		try {
			resp = await userVerify({
				businessEmailId: corpEmail,
				verificationCode
			});
		} catch (error) {
			resp = {
				data: {
					errorMessage: APPLICATION_CONSTANTS.API_ERROR
				}
			};
		} finally {
			if (resp.data) {
				if (resp.data.corpUserId) {
					let email = '';
					try {
						email = window.atob(corpEmail);
					} catch (error) {

					}

					onSignUpClicked(false, email);
				} else {
					const message = resp.data.errorMessage;
					let popData = {
						heading: 'User Verification Status',
						content: message,
						apiStatus: 'ERR_DEFAULT',
						isFooter: false
					};
					setStatusData(popData);
				}
			}
		}
	}, [verificationCode, corpEmail]);

	useEffect(()=>{
		if (isUserVerifyPage && typeof corpEmail !== 'undefined') {
			verifyInvitedUser();
			setStatusData({
				content: '<span class="latoBlack">Verifying User...</span>',
				isLoading: true,
				heading: '',
				isFooter: false
			});
			toggleModal(MODAL_NAMES.STATUS);
		}
	}, [corpEmail, isUserVerifyPage]);

	useEffect(()=>{
		logPageLoadEvents(isSME ? LANDING_CONSTS.SME_LANDING_VIEW : LANDING_CONSTS.LANDING_PAGE_VIEW);
		const setActiveWin = () => {
			setIsActive(true);
		};
		['scroll', 'click'].forEach( (evt) =>
			window.addEventListener(evt, setActiveWin, false));
		setTimeout(() => {
			setIsTimeout(true);
		}, APPLICATION_CONSTANTS.EXPLORE_DELAY);

		return () => {
			['scroll', 'click'].forEach( (evt) =>
				window.removeEventListener(evt, setActiveWin, false));
		};
	}, [isSME]);

	useEffect(()=>{
		if (isTimeout && !isActive && !isMobile) {
			setShowExplore(true);
		}
	}, [isTimeout, isActive, isMobile]);

	const { orgDets = {}, app, monetary, onboard = {}, error } = bizData;

	return (
		<>
			<HiddenElem itemScope itemType={`${PATHS.SEO_SCHEMA_URL}/Organization`}>
				<meta itemProp='name' content='myBiz by MakeMyTrip' />
				<meta
					itemProp='description' content='myBiz, MakeMyTrip for Business is a one-stop expense & travel management solution for all corporate/business travel needs of micro to large enterprises.
						We are a preferred travel partner to more than 2.5 Lakh business travellers'
				/>
				<meta itemProp='logo' content='https://imgak.mmtcdn.com/mybiz/assets/images/mybizlogo.png' />
				<meta itemProp='url' content='https://mybiz.makemytrip.com/' />
				<meta itemProp='sameAs' content='https://www.linkedin.com/showcase/makemytrip-for-business' />

				<span itemProp='address' itemScope itemType={`${PATHS.SEO_SCHEMA_URL}/PostalAddress`}>
					<meta itemProp='streetAddress' content='MakeMyTrip India Pvt. Ltd., DLF Building No. 5 Tower B DLF Cyber City, DLF Phase 2. Sector 25' />
					<meta itemProp='addressLocality' content='Gurugram' />
					<meta itemProp='addressRegion' content='Haryana' />
					<meta itemProp='postalCode' content='122002' />
				</span>
				<span itemProp='contactPoint' itemScope itemType={`${PATHS.SEO_SCHEMA_URL}/ContactPoint`}>
					<meta itemProp='telephone' content='1860 500 8747' />
					<meta itemProp='contactType' content='Customer Care' />
					<meta itemProp='contactOption' content='TollFree' />
					<meta itemProp='areaServed' content='India' />
				</span>
			</HiddenElem>
			<BannerCarousel
				isCorpSeo={isCorpSeo}
				isShowSEO
				isSME={isSME}
				isMobile={isMobile}
				onboardData={onboard}
				navClick={navClick}
				pageStripName={pageStripName}
				height={pageStripName && 680}
				additionalSeoData={additionalSeoData}
				error={error}
			/>
			{!isCorpSeo && <BuisnessPartners updateCurrElem={updateCurrElem} orgDets={error ? true : orgDets} showNextComp={updateShowComp} nextComp='Benefits' />}
			{typeof children !== 'undefined' &&
			<GenericDiv as='section' position='relative' $bg='#fff'>
				<ResponsiveContainer isNoBTPadd>
					{children}
				</ResponsiveContainer>
			</GenericDiv>}
			{visibleComps.Benefits ?
				<Benefits
					videoLink={onboard.videoLink}
					isScollView={isScollView === 'Benefits'}
					showNextComp={updateShowComp}
					nextComp='GettingStarted'
				/> :
				<Loader $bg='#fff' color='#723b63' margin='0 auto' />}
			{visibleComps.GettingStarted &&
			<GettingStarted
				isScollView={isScollView === 'GettingStarted'}
				showNextComp={updateShowComp}
				nextComp='DownloadSection'
				prevComp='Benefits'
			/>}
			{visibleComps.GettingStarted &&
			<DownloadSection
				app={app}
				showNextComp={updateShowComp}
				nextComp='Testimonials'
				prevComp='GettingStarted'
			/>}
			{visibleComps.Testimonials &&
			<Testimonials
				showNextComp={updateShowComp}
				nextComp='CaseStudies'
				prevComp='DownloadSection'
				isCorpSeo={isCorpSeo}
			/>}
			{visibleComps.CaseStudies &&
			<CaseStudies
				isScollView={isScollView === 'CaseStudies'}
				showNextComp={updateShowComp}
				nextComp='Questionnaire'
				prevComp='Testimonials'
				isCorpSeo={isCorpSeo}
			/>}
			{visibleComps.Questionnaire &&
			<Questionnaire
				monetary={monetary}
				showNextComp={updateShowComp}
				prevComp='CaseStudies'
				updateCurrElem={updateCurrElem}
			/>}
			<SignUpFooter showNextComp={updateShowComp} prevComp='CaseStudies' />
			{!isMobile && showExplore && <ExploreMore />}
			{bizModal === MODAL_NAMES.STATUS && <StatusPopup
				{...statusData}
				handleModal={handleStatusModal}
			/>}
			{isMobile && <StickyFooter />}
		</>
	);
};

export default memo(LandingApp);
