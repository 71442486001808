import styled from 'styled-components';

import { ResponsiveContainer } from '../../../common/Styles';

export const SignUpFooterWrapper = styled.div`
  position: relative;
  background-image: linear-gradient(87deg, #723b63 1%, #4c2340 98%);
  padding: 60px 0 20px 0;
`;

export const Conatiner = styled(ResponsiveContainer)(
	({ theme: { down, breakpoints } }) => `
    ${down(breakpoints.md)} {
      flex-direction: column;
      }
    
  `
);

export const BtnWrapper = styled.div(
	({ theme: { down, breakpoints } }) => `
  width: 46%;
  display: flex;
  justify-content: flex-end;
  padding: 30px  0;
   > button {
    &:first-child {
      margin-right: 20px;
    }
  }
  
  ${down(breakpoints.md)} {
      flex-wrap: wrap;
      width: 100%;
      > button {
      &:first-child {
        margin-right: 0px;
        margin-bottom: 16px;
      }
    }
  }
    
  `
);

export const BenefitsCurve = styled.div(
	({ $bg, theme: { down, breakpoints } }) => `

  width: calc(100% * 1.4);
  height: 170px;
  background: ${$bg || '#F4F4F4'};
  border-radius: 50% / 150px;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  position: absolute;
  left: 50%;
  top: -122px;
  z-index: 9;
  transform: translateX(-50%);

  ${down(breakpoints.md)} {
    width: calc(100% * 2);
    }
    
  `
);
