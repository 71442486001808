import React, { useCallback, useContext } from 'react';

import DisplayContext from 'UtilityComponents/displayContext';
import landingContext from '../../landingContext';
import { isMobileContext } from 'UtilityComponents/Util';
import { trackClickEvents } from '../../utils';
import Button from '../../../common/Button/Button';
import { BtnWrapper } from './actionButtonStyles';
import { LANDING_CONSTS, MODAL_NAMES, PATHS } from 'AppConstants';

const ActionButtons = ({ $width = 'auto', align = 'flex-start', direction = 'row',
	show = LANDING_CONSTS.ALL, indexNo = '1', signupTxt = 'SIGN UP NOW',
	directionInMobile = 'column', btnHeight = '64px', zIndex }) => {
	const { isMobile: displayName } = useContext(DisplayContext);
	const appContext = useContext(landingContext) || {
	};
	const isMobile = isMobileContext(displayName);
	const { onSignUpClicked, toggleModal } = appContext;
	const isShowSignup = show === LANDING_CONSTS.ALL || show === LANDING_CONSTS.SIGNUP;
	const isShowDemo = show === LANDING_CONSTS.ALL || show === LANDING_CONSTS.DEMO;
	const isSingle = !isShowSignup || !isShowDemo;

	const clickSignUp = useCallback((e)=>{
		onSignUpClicked(e);
		trackClickEvents('Signupforfree_clicked_' + indexNo);
	}, [onSignUpClicked, indexNo]);

	const requestDemoClick = useCallback((e)=>{
		toggleModal(MODAL_NAMES.REQ_DEMO);
		trackClickEvents('Request_demo_click_' + indexNo);
	}, [toggleModal, indexNo]);

	return (
		<BtnWrapper zIndex={zIndex} position='relative' $width={$width} align={align} $direction={direction} directionInMobile={directionInMobile}>
			{isShowSignup &&
			<Button
				btnTxt={signupTxt}
				btnType='Flat'
				btnName='Primary'
				btnShadow
				btnWidth={`${isSingle || (isMobile) ? '100%' : 'calc(61% - 20px)'}`}
				height={btnHeight}
				handleClick={clickSignUp}
				seoUrl={PATHS.LANDING_LOGIN_PAGE}
				isSeoAnchor
			/>}
			{isShowDemo && <Button
				btnTxt='REQUEST DEMO'
				btnType='Flat'
				btnName='Secondary'
				btnShadow
				handleClick={requestDemoClick}
				btnWidth={`${isSingle || (isMobile) ? '100%' : '38%'}`}
				height={btnHeight}
				seoUrl={PATHS.REQUEST_DEMO_PAGE_NEW}
				isSeoAnchor
			/>}
		</BtnWrapper>
	);
};

export default ActionButtons;
