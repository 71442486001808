import styled from 'styled-components';

import { FlexDiv } from 'commonStyles';

export const FooterWrapper = styled.footer`
		position: relative;
`;

export const FooterHeadWrap = styled(FlexDiv)(
	({ theme: { down, breakpoints } }) => `
			padding: 16px 0px;
			position: relative;
        ${down(breakpoints.md)} {
					padding: 0px;
					align-items: center;
        }
    
      `
);

export const FooterLabel = styled.p(
	({ theme: { down, breakpoints } }) => `
				
        ${down(breakpoints.md)} {
						padding: 24px 0px 24px 10px;
						display: flex;
				}
				margin-left: 5px;
    
      `
);

export const HeadSpan = styled.span(
	({ theme: { down, breakpoints } }) => `
  
        ${down(breakpoints.md)} {
						font-size: 14px;
						// padding: 24px 0px 24px 10px;
        }
        text-transform: uppercase;
        font-size: 16px;
        font-weight: 900;
        color: white;
				margin-left: 5px;
    
      `
);

export const FooterColWrap = styled(FlexDiv)(
	({ borderBottom, theme: { down, breakpoints } }) => `
				flex-direction: column;
        ${down(breakpoints.tb)} {
						width: 100%;
						border-bottom: ${borderBottom || ''};
        }
        ${down(breakpoints.md)} {
						width: 100%;
						border-bottom:  ${borderBottom || ''};
        }
    
      `
);

export const LinkWrap = styled.ul(
	({ mobDir, theme: { down, breakpoints } }) => `
	display: flex;
	flex-wrap: wrap;
	padding-bottom: 16px;
	${down(breakpoints.md)} {
		flex-direction: ${mobDir || 'column'};
		align-items: flex-start;
		justify-content: space-around;
		padding-left: 32px;
}
`
);

export const FooterItems = styled.li(
	({ margin, theme: { down, breakpoints } }) => `
		margin: ${margin};
		font-size: 16px;
		color: white;
		margin-bottom: 8px;
		position: relative;
		display: flex;
		align-items: center;
		${down(breakpoints.md)} {
				font-size: 12px;
		}
		font-size: 16px;
		color: white;
		margin-bottom: 8px;
		>a{
				color: #fff;
		}
	`
);

export const Dot = styled.span`
		content: "";
    width: 3px;
    height: 3px;
    background-color: #ffffff;
    border-radius: 50%;
    display: block;
    margin: 0 4px;
`;

export const FooterSubHead = styled(FlexDiv)(
	({ theme: { down, breakpoints } }) => `
	color: #ffffff;
	font-weight: 700;
	font-size: 16px;
	flex-direction: column;
	width: auto;
	justify-content: normal;
	${down(breakpoints.md)} {
		font-size: 9px;
		flex-direction: column;
		align-items: center;
	}
`
);

export const MobileArrow = styled.i`
  border: ${(props) => props.border ? props.border : 'solid #ff674b'};
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 4px;
  transform: rotate(45deg);
  position: absolute;
  right: ${(props) => props.right ? props.right : '0'};
  top: ${(props) => props.$top ? props.$top : '0'};
	&.up{
		transform: rotate(225deg);
	}
`;
