import styled from 'styled-components';

export const Heading = styled.img`
    max-width: 100%;
`;

export const Footer = styled.div`
    margin-top: 14px;
    margin-bottom: 20px;
`;
