import styled from 'styled-components';

import { FlexDiv } from '../Styles';

export const HeaderContainerShadow = styled.header`
	z-index: 10;
	box-shadow: none;
	background: transparent;
	position: fixed;
	top: 0px;
	transition: all 200ms ease-in-out;
	width: 100%;
	z-index: 10;
	height: 80px;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.16);
	&.stickyHeaderLanding {
		background: #fff;
	}
`;

export const HeaderWrapper = styled.div(
	({ theme: { down, breakpoints } }) => `
	${down(breakpoints.tb)} {
		padding: 0 20px;
	}
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 80px;
	
	`
);

export const NavigationList = styled.nav(
	({ stickyHeader, hasMenus, theme: { up, breakpoints } }) => `
				width: auto;
				padding-top: 10px;
				margin-right: 10px;
				list-style-type: none;
				display: flex;
				> a {
					cursor: pointer;
					position: relative;
					font-size: 16px;
					color: ${stickyHeader ? '#4a4a4a' : 'rgba(255, 255, 255, 0.84)'};
					transition: color 500ms ease-in-out;
					text-transform: uppercase;
					margin-right: 50px;
					&:last-child {
						margin-right: ${hasMenus ? '57px' : '0px'};
					}
					&:before {
						content:'';
						position: absolute;
						left: -10px;
						top: 4px;
						transition: all 500ms ease-in-out;
						width: 4px;
						opacity: 0;
						height: 16px;
						background: #f2c6ab;
					}
					&:hover {
						color:${stickyHeader ? '#ff664b' : '#f2c6ab'};

					}
					${up(breakpoints.md)} {
						height: 38px;
						display: inline-block;
					}
				}
    
      `
);

export const LogoSubText = styled.span(
	({ theme: { down, breakpoints } }) => `
        ${down(breakpoints.md)} {
            font-size: 10px;
            margin: 5px 0 0 8px;
        }
        font-size: 16px;
        color: #9b9b9b;
        font-weight: 700;
        margin: 0 0 0 8px;
        border-left: 1px solid #b5b5b5;
        padding-left:5px;
    
      `
);

export const LogoLink = styled.a`
	padding-bottom: 10px;
	.hidden {
		width: 0;
		height: 0;
		overflow: hidden;
		display: inline-block;
	}
`;

export const HamburgerMenuWrap = styled.a`
  text-decoration: none;
  outline: none;
  width: 20px;
  margin-top: 8px;
  > span {
    background-color: rgb(17, 17, 17);
    height: 2px;
    float: left;
    width: 100%;
    margin-bottom: 4px;
  }
`;

export const NavigationListMobile = styled(FlexDiv)(
	({ theme: { down, breakpoints } }) => `
        ${down(breakpoints.tb)} {
          flex-direction: column;
        }
        > div {
          color: #000;
        }
      `
);

export const LinkArrow = styled.span(
	({ active, color, $bg, theme: { down, breakpoints } }) => `
	position: relative;
	background: ${$bg ? $bg : '#F7F7F7'};
	width: 22px;
	height: 22px;
	border-radius: 50%;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	&::before {
		border: solid ${color ? color : '#F76162'};
		border-width: 0 2px 2px 0;
		display: inline-block;
		padding: 2.5px;
		transform: rotate(-45deg);
		position: relative;
		right: 0;
		top: 0px;
		content: "";
	}
  `
);

export const HeaderLink = styled(FlexDiv)`
	border-bottom: 1px solid #e8e8e8;
	padding: 12px 0;
	margin: 0;
	span {
		pointer-events: none;
	}
`;

export const ArrowNextButtonCircular = styled.span`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 16px;
	height: 16px;
	border-radius: 50%;
	background: #f7f7f7;
	&::before {
		border: solid #ff664b;
		border-width: 0 2px 2px 0;
		border-radius: 2px;
		display: inline-block;
		padding: 3px;
		transform: rotate(-45deg);
		position: relative;
		right: 1px;
		top: 0px;
		content: "";
	}
`;

export const NavLinkContainer = styled(FlexDiv)`
	border-bottom: 1px solid #e8e8e8;
	padding: 12px 23px 12px 16px;
`;
