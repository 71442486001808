import Axios from 'axios';

import { getCookie, getXrequestId } from '../UtilityComponents/Util';

export const getAxiosConfig = (baseUrl, passHeaders, customHeaders, withCredentials = true, timeout = 30000) => {
	let ccbConfig = {
		baseURL: baseUrl,
		timeout,
		withCredentials: withCredentials
	};

	const requestId = typeof window == 'undefined' ? '' : getXrequestId();
	const auth = getCookie('mmt-auth');
	if (passHeaders) {
		ccbConfig['headers'] = {
			'Content-Type': 'application/json',
			'Accept': 'application/json',
			'X-Request-ID': requestId
		};
	}
	if (auth) {
		ccbConfig['headers'] = ccbConfig['headers'] || {};
		ccbConfig['headers']['mmt-auth'] = auth;
	}

	if (customHeaders && passHeaders) {
		ccbConfig['headers'] = { ...ccbConfig['headers'], ...customHeaders };
	}
	return Axios.create(ccbConfig);
};
