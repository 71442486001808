import styled, { css } from 'styled-components';

import { ASSET_PATH } from '../../assetsPath';

export const AppWrapper = styled.main`
padding-top: 80px;
.loadwrap {
	position: relative;
}

.custom-scroll {
	webkit-overflow-scrolling: touch;
	scrollbar-color:rgba(255, 255, 255, .5) rgba(0, 0, 0, .3);
	-ms-overflow-style : scrollbar;
	::-webkit-scrollbar {
			-webkit-appearance: none;
			width: 6px;
	}
	
	::-webkit-scrollbar-thumb {
			border-radius: 4px;
			background-color: rgba(0, 0, 0, .5);
			box-shadow: 0 0 1px rgba(255, 255, 255, .5);
	}
	::-webkit-scrollbar-track {
			background-color: rgba(0, 0, 0, .15)
	}
	::-webkit-scrollbar-thumb:hover {
			background: #555;
	}
}

`;
export const LandingSpriteIcon = styled.span`
    background:url(${ASSET_PATH.IMAGES}landing_NewSprite1.png) no-repeat;
    background-size: 400px 400px;
		display: inline-block;
    ${(props) => {
		switch (props.icon) {
			case 'icSpeaker':
				return css`
					background-position: -84px -12px;
					width: 48px;
					height: 48px;`;
			case 'icPlay':
				return css`
					background-position: 0 0;
					width: 78px;
					height: 78px;
					cursor: pointer;`;
			case 'icStar':
				return css`
					background-position: -142px -163px;
					min-width: 15px;
					height: 14px;`;
			case 'TM':
				return css`
					background-position: -22px -88px;
					width: 40px;
					height: 40px;`;
			case 'CB':
				return css`
					background-position: -87px -150px;
					width: 40px;
					height: 39px;`;
			case 'EM':
				return css`
					background-position: -88px -88px;
					width: 35px;
					height: 40px;`;
			case 'greenGift':
				return css`
					background-position: -362px -120px;
					width: 21px;
					height: 21px;`;
			case 'exclaim':
				return css`
					background-position: -83px -277px;
					width: 23px;
					height: 23px;`;
			case 'flight':
				return css`
					background-position:-280px -76px;
					width: 75px;
					height: 75px`;
			case 'hotel':
				return css`      
					background-position:-207px -71px;
					width: 75px;
					height: 75px;`;
			case 'cab':
				return css`      
					background-position: -9px -189px;
					width: 75px;
					height: 75px;`;
			case 'phone':
				return css`      
					background-position:-203px 4px;
					width: 75px;
					height: 75px;`;
			case 'icNote':
				return css`
					background-position: -31px -148px;
					width: 15px;
					height: 20px;`;
			case 'icBizCities':
				return css`
					background-position: -137px -312px;
					width: 22px;
					height: 22px;
		`;
			case 'icBlog':
				return css`
					background-position: -168px -311px;
					width: 22px;
					height: 22px;
		`;
			case 'icDownload':
				return css`
					background-position: -81px -312px;
					width: 22px;
					height: 22px;
		`;
			case 'icFollowUs':
				return css`
					background-position: -109px -312px;
					width: 22px;
					height: 22px;
		`;
			case 'icQuickLinks':
				return css`
					background-position: -232px -310px;
					width: 22px;
					height: 22px;
		`;
			case 'icLegals':
				return css`
					background-position: -200px -310px;
					width: 22px;
					height: 22px;
		`;
			case 'default':
				return css`
					width: 19px;
					height: 14px;
					background-position: -77px -8px;`;
			default:
				break;
		}
	}}
`;

export const HiddenElem = styled.div`
      height: 0;
      width: 0;
      overflow: ${(prop) => prop.activeModal ? 'visible' : 'hidden'}
`;

export const HeaderWrap = styled.div`
	.modal.myBiz {
		background: transparent;
	}
                           
`;
