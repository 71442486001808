import React, { useState, useEffect, useMemo, useRef } from 'react';
import Slider from 'react-slick';

import * as S from './BannerCarouselStyle';

import {
	GenericP,
	FlexDiv
} from 'commonStyles';
import OfferSection from './offers';
import GetStartedButton from '../GetStartedButton/GetStartedButton';
import VideoModal from './videoModal';
import StripPageName from '../StripPageName/StripPageName';
import { MODAL_NAMES, PATHS } from 'AppConstants';

const BannerCarousel = ({ onboardData = {}, navClick, mainImages = {}, videoPreviews = {},
	bannerCarouselData, mybizLogo, isFullWidth, toggleModal, bizModal,
	isMobile, trackClickEvents, height, imgOverlay = false, bannerImgWidth,
	pageStripName, isShowSEO, imagePreview, isCorpSeo, additionalSeoData = {}, error }) => {
	const { mainImagePng, mainImageWebP } = mainImages;
	const { videoPreviewwebp, videoPreviewMobilewebp, videoPreviewMobile, videoPreview } = videoPreviews;

	const imgMinHeight = isCorpSeo ? '600px' : '700px';

	const [imgStyle, setImgStyle] = useState({ position: 'absolute', height: '100%', minHeight: imgMinHeight });

	const [sliderSetiings, setSliderSettings] = useState({
		infinite: false,
		autoplay: false
	});
	const [showBgOverlay, setShowBgOverlay] = useState(imgOverlay);
	const sliderRef = useRef();
	const imageRef = useRef();
	const [showMainImg, setShowImg] = useState(!isMobile);

	const { carousalAddData } = additionalSeoData;
	const { offers = [], videoLink, caraouselDetails = {} } = onboardData;
	const offer = offers[0];
	const { mainHeading = 'MakeMyTrip For Business', data: carouselData } = caraouselDetails;
	let sliderData = carouselData && carouselData.length ? carouselData : bannerCarouselData;
	if (isCorpSeo && carousalAddData) {
		sliderData = [carousalAddData, ...sliderData];
	}

	const handleVideo = () => {
		let modalVal = false;
		if (bizModal !== MODAL_NAMES.MYBIZ_VIDEO) {
			modalVal = MODAL_NAMES.MYBIZ_VIDEO;
			trackClickEvents && trackClickEvents('Video_clicked');
		}
		toggleModal(modalVal);
	};

	useEffect(()=>{
		if (error || (carouselData && carouselData.length)) {
			const upateStyle = {
				height: imageRef.current?.height,
				position: 'fixed',
				minHeight: imgMinHeight
			};
			setImgStyle(upateStyle);
		}
	}, [error, carouselData, isShowSEO]);

	useEffect(()=>{
		setTimeout(() => {
			setSliderSettings((state) => ({
				...state,
				infinite: true,
				autoplay: true
			}));
			sliderRef && sliderRef.current && sliderRef.current.slickPlay();
		}, 4000);
	}, []);

	const settings = useMemo(()=>({
		...sliderSetiings,
		dots: true,
		fade: true,
		arrows: false,
		speed: 1000,
		autoplaySpeed: 5000,
		slidesToShow: 1,
		slidesToScroll: 1,
		appendDots: (dots) => (
			<S.BannerDotsWrap
				style={{
        	display: 'flex'
				}}>
				<ul style={{ margin: '0px' }}> {dots} </ul>
			</S.BannerDotsWrap>
		)
	}), [sliderSetiings]);

	const toggleBgOverlay = (state) =>() => {
		if (!imgOverlay) {
			setShowBgOverlay(state);
		}
	};

	useEffect(() => {
		const eventHandler = () => setShowImg(window.pageYOffset <= 900);
		if (typeof window !== 'undefined' && window.innerWidth > 768) {
			window.addEventListener('scroll', eventHandler);
		}
		return () => {
			if (typeof window !== 'undefined' && window.innerWidth > 768) {
				window.removeEventListener('scroll', eventHandler);
			}
		};
	}, []);
	const seoData = isShowSEO ? { itemProp: 'image' } : {};
	const showVideo = (isCorpSeo) ? false : videoPreview;
	const renderPreview = () => {
		if (imagePreview) {
			return (
				<S.HomepageBannerRight isCorpSeo={isCorpSeo} itemScope itemType={!isCorpSeo && `${PATHS.SEO_SCHEMA_URL}/VideoObject`}>
					<img
						itemProp='thumbnailUrl'
						src={imagePreview}
						alt='mybiz description'
						height={isMobile ? '220' : isCorpSeo ? '240' : '315'}
						width={isMobile ? '360' : isCorpSeo ? 'auto' : '516'}
					/>
				</S.HomepageBannerRight>
			);
		} else if (showVideo) {
			return (
				<S.HomepageBannerRight itemScope itemType={`${PATHS.SEO_SCHEMA_URL}/VideoObject`}>
					<S.VideoPreviewWrap
						onClick={handleVideo}
						onMouseEnter={toggleBgOverlay(true)}
						onMouseLeave={toggleBgOverlay(false)}>
						<meta itemProp='name' content='Introducing myBiz by MakeMyTrip' />
						<meta
							itemProp='description' content='myBiz, MakeMyTrip for Business is a one-stop expense & travel management solution for all
					corporate/business travel needs of micro to large enterprises. We are a preferred travel partner to more than 2.5 Lakh business travellers'
						/>
						<meta itemProp='uploadDate' content='2020-06-02 T08:00:00+08:00' />
						<meta itemProp='duration' content='PT1M48S' />
						<meta itemProp='contentUrl' content={videoLink} />
						<meta itemProp='regionsAllowed' content='ALL' />
						<FlexDiv justify='center' align='center'>
							<picture>
								<source srcSet={videoPreviewwebp} media='(min-width: 768px)' type='image/webp' />
								<source srcSet={videoPreview} media='(min-width: 768px)' type='image/png' />
								<source srcSet={videoPreviewMobilewebp} media='(max-width: 767px)' type='image/webp' />
								<source srcSet={videoPreviewMobile} media='(max-width: 767px)' type='image/png' />
								<img
									itemProp='thumbnailUrl'
									src={videoPreview}
									alt='mybiz description'
									height={isMobile ? '220' : '315'}
									width={isMobile ? '360' : '516'}
									crossOrigin='anonymous'
								/>
							</picture>
							<S.OverlayWrap />
						</FlexDiv>
						<S.VideoOverlayContent>
							<S.PlayBtn aria-label='play-button' />
							<S.VideoText>EXPLORE MYBIZ</S.VideoText>
						</S.VideoOverlayContent>
					</S.VideoPreviewWrap>
				</S.HomepageBannerRight>
			);
		}
		return '';
	};

	return (

		<S.MainWrapper height='auto'>

			{showMainImg &&
			<picture>
				<source srcSet={mainImageWebP} type='image/webp' />
				<source srcSet={mainImagePng} type='image/png' />
				<img style={imgStyle} ref={imageRef} crossOrigin='anonymous' {...seoData} className='mainImg' src={mainImagePng} alt='mainimage' />
				{showBgOverlay && <div className='bgOverlay' />}
			</picture>}
			{/** pageStripName && <StripPageName pageStripName={pageStripName} />**/}
			<S.BannerWrapper isCorpSeo={isCorpSeo}>
				<S.HomepageBannerLeft isBannerImage={imagePreview || showVideo} $width={isFullWidth ? '100%' : '49%'} itemScope itemType={`${PATHS.SEO_SCHEMA_URL}/SoftwareApplication`}>
					<>
						<meta itemProp='name' content='myBiz by MakeMyTrip' />
						<meta itemProp='operatingSystem' content='ANDROID, IOS, Desktop' />
						<meta itemProp='applicationCategory' content='businesstravelApplication' />
						<span itemProp='aggregateRating' itemScope itemType={`${PATHS.SEO_SCHEMA_URL}/AggregateRating`}>
							<meta itemProp='ratingValue' content='4.6' />
							<meta itemProp='ratingCount' content='1,789,573' />
						</span>
					</>
					{isCorpSeo &&
					<S.MainHeading $top='0' marBt='8px' font_size={isMobile ? '12' : '22'} as={isCorpSeo ? 'h2' : 'h1'} color='#ff664b'>
						{mainHeading}
					</S.MainHeading>}
					<Slider {...settings} ref={sliderRef}>
						{sliderData && sliderData.map((data, index) => {
							const { heading: carouselHeading, subHeading: descp, carouselUrl, carouselMainHead = mainHeading } = data;
							return (
								<article key={carouselHeading + descp}>
									<S.HomepageBannerLeft isBannerImage={imagePreview || showVideo} $width={isFullWidth ? '49%' : '100%'}>
										<S.HomepageBannerContainer reverse>
											<S.HeaderWrapper>
												{!isCorpSeo &&
												<S.MainHeading marBt='8px' font_size={isMobile ? '12' : '22'} as={index > 0 || isCorpSeo ? 'h2' : 'h1'} color='#ff664b'>
													{carouselMainHead}
												</S.MainHeading>}
												{carouselHeading &&
												<GenericP
													fsize={`${isMobile ? '24px' : '48px'}`}
													f_weight='700'
													bottom_M={`${isMobile ? '16' : '26'}`}
													color='#fff'>
													{carouselHeading}
												</GenericP>}
												{descp &&
												<GenericP className='desc' bottom_M={!isMobile ? '20' : '0'} fsize={`${!isMobile ? '22px' : '14px'}`} color='#fff'>
													{descp}
												</GenericP>}
											</S.HeaderWrapper>
										</S.HomepageBannerContainer>
										<GetStartedButton zIndex='2' navClick={navClick} />
										{offer && !isCorpSeo &&
										<OfferSection
											isShowSEO={isShowSEO}
											key={index}
											bgColor={offer.bgColor}
											offerDescp={offer.text}
											bgGradient={offer.bgGradient}
											offerTag={offer.type}
											iconName={offer.iconName}
											link={offer.link}
											isMobile={isMobile}
											indexNo={index}
										/>}
									</S.HomepageBannerLeft>
									{carouselUrl && carouselUrl.type === 'img' && (
									<S.BannerImgWrap $width={bannerImgWidth}>
										<img
											loading='lazy' width='100%' height='310'
											src={isMobile ? carouselUrl.mobileUrl : carouselUrl.url} alt='banner_image'
											style={{ 'minHeight': isMobile ? '230px' : '385px', 'objectFit': 'contain', 'objectPosition': '0 0' }}
										/>
									</S.BannerImgWrap>
									)}
								</article>
							);
						})}
					</Slider>
				</S.HomepageBannerLeft>
				{renderPreview()}
			</S.BannerWrapper>
			{bizModal === MODAL_NAMES.MYBIZ_VIDEO && videoLink &&
			<VideoModal videoLink={videoLink} isMobile={isMobile} handleVideo={handleVideo} mybizLogo={mybizLogo} />}
		</S.MainWrapper>
	);
};

export default (BannerCarousel);
