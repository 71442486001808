import React, { useContext } from 'react';

import { LANDING_CONSTS } from 'AppConstants';
import ActionButtons from '../actionButtons/actionButtons';
import landingContext from '../../landingContext';
import { StickyWrapper } from './StickyFooter.style';
import { FlexDiv } from 'commonStyles';
import { SIGNUP_BTN_TEXT } from '../../config';

const StickyFooter = ({ onSignUpClicked }) => {
	const { isLoggedInUser } = useContext(landingContext) || {};

	return (
		<StickyWrapper align='center'>
			<FlexDiv top_P='6' right_P='13' left_P='13'>
				<ActionButtons
					show={isLoggedInUser ? LANDING_CONSTS.SIGNUP : LANDING_CONSTS.ALL}
					signupTxt={isLoggedInUser ? SIGNUP_BTN_TEXT.loggedin : SIGNUP_BTN_TEXT.loggedOut}
					onSignUpClicked={onSignUpClicked} $width='41%' indexNo='3'
					directionInMobile='row'
					btnHeight='44px'
				/>
			</FlexDiv>
		</StickyWrapper>
	);
};

export default StickyFooter;
