import styled from 'styled-components';

export const CloseButton = styled.div`
	color: #4a4a4a;
	box-sizing: border-box; 
	display: inline-block; 
	width: 2em; 
	height: 2em; 
	background-color: transparent; 
	border-radius: 50%; 
	cursor: pointer;
	right: ${(p) => p.pos_R ? p.pos_R : '18px'};
	top: ${(p) => p.pos_T ? p.pos_T : '16px'};
	position: ${(p) => p.pos ? p.pos : 'absolute'};
	z-index: 1;
	font-size:${(p) => p.fsize || '16px'};

	&.white{
		background-color: #fff;
	}

	&.white-icon {
		:after,
		:before {
			background-color: #fff;
		}
	}

	&:after,
	&:before {
		position: absolute;
		content:"";
		background-color: #4a4a4a;
		opacity: 0.6;
	}

	&:after {
		width: 0.14em;
		height: 1.25em;
		top: 0.45em;
		left: 1em;
		transform: rotate(45deg);
	}

	&:before {
		width: 1.25em;
		height: 0.14em;
		top: 1em;
		left: 0.45em;
		transform: rotate(45deg);
	}
`;
