import { saveServerLogs } from 'UtilityComponents/Util';
import { APPLICATION_CONSTANTS, PATHS } from 'AppConstants';
import { getccbAxiosConfig } from '../apisSetup/ccbConfig';
import { getAbExperiment } from '../apis/commonApis';
import { formatBuisnessDets } from './utils';

const ccbAxiosApiCaller = getccbAxiosConfig(true, {}, false);

export const getLandingExperiment = async () => {
	const abPlatformApi = APPLICATION_CONSTANTS.MYBIZ_CORE;
	response = await getAbExperiment( abPlatformApi).then((res) => res).catch((err) => {
		throw (err);
	});
	return response;
};

const getDataCall = async (url, data) => {
	let response = null;
	try {
		response = await ccbAxiosApiCaller.get(url, {
			params: data
		});
	} catch (e) {
		const errorMsg = e ? e.message : APPLICATION_CONSTANTS.API_ERROR;
		response = { error: errorMsg };
		saveServerLogs(e.stack, 'error', url, data);
	}
	return response;
};

export const getMyBizDetails = async (pageName) => {
	const response = await getDataCall(PATHS.GET_MYBIZ_ORGS_DET + '?pageName=' + pageName);
	const retData = formatBuisnessDets(response);
	return {
		bizDetails: retData
	};
};

export const getLandingSMEDetails = async () => {
	const retData = formatBuisnessDets({ data: SME_FEST_DATA });
	return {
		bizDetails: retData
	};
};

export const getMyBizBenefits = async (type) => {
	const response = await getDataCall(PATHS.GET_MYBIZ_BENEFITS, { type });
	const responseData = response && response.data;
	if (responseData && responseData.responseCode === APPLICATION_CONSTANTS.SUCCESS_RESPONSE_CODE && responseData.benefits) {
		return {
			bizBenefits: responseData
		};
	} else return false;
};

export const getMyBizTestimony = async () => {
	const response = await getDataCall(PATHS.GET_MYBIZ_TESTIMONY);
	let retData = { error: true };
	const responseData = response && response.data;
	if (responseData && responseData.responseCode === APPLICATION_CONSTANTS.SUCCESS_RESPONSE_CODE) {
		retData = responseData.testimoniesDetails;
	}
	return {
		bizTestimony: retData
	};
};

export const getMybizQuestions = async () => {
	const response = await getDataCall(PATHS.GET_MYBIZ_QUESTIONS);
	let retData = { error: true };
	const responseData = response && response.data;
	if (responseData && responseData.responseCode === APPLICATION_CONSTANTS.SUCCESS_RESPONSE_CODE) {
		retData = responseData.details;
	}
	return {
		bizQues: retData
	};
};

export const getMybizCaseStudy = async () => {
	const response = await getDataCall(PATHS.GET_MYBIZ_CASESTUDY);
	let retData = { error: true };
	const responseData = response && response.data;
	if (responseData && responseData.responseCode === APPLICATION_CONSTANTS.SUCCESS_RESPONSE_CODE) {
		retData = responseData.caseStudiesDetails;
	}
	return {
		caseStudy: retData
	};
};

export const getPrepGuidlinse = async (data) => {
	let response = null;
	try {
		response = await ccbAxiosApiCaller.post(PATHS.GET_MYBIZ_GUIDLINES, data);
	} catch (e) {
		const errorMsg = e ? e.message : 'Something went wrong, please try again later';
		response = { error: errorMsg };
		saveServerLogs(e.stack, 'error', PATHS.GET_MYBIZ_GUIDLINES);
	}
	return response;
};

export const requestDemoApi = async (data) => {
	let response = null;
	try {
		response = await ccbAxiosApiCaller.post(PATHS.REQUEST_DEMO_FORM, data);
	} catch (e) {
		const errorMsg = e ? e.message : 'Something went wrong, please try again later';
		response = { error: errorMsg };
		saveServerLogs(e.stack, 'error', PATHS.GET_MYBIZ_GUIDLINES);
	}
	return response;
};

export const userVerify = async (data) => {
	let response = null;
	try {
		response = await ccbAxiosApiCaller.put(PATHS.VERIFY_USER, data);
	} catch (e) {
		const errorMsg = e ? e.message : APPLICATION_CONSTANTS.API_ERROR;
		response = { error: errorMsg };
		saveServerLogs(e.stack, 'error', url, data);
	}
	return response;
};

export const getConfigData = async (configType) => {
	const response = await getDataCall(PATHS.GET_CONFIG + configType);
	let retData = { error: true };
	const responseData = response && response.data;
	if (responseData && responseData.responseCode === APPLICATION_CONSTANTS.SUCCESS_RESPONSE_CODE) {
		retData = responseData?.config;
	}
	return {
		data: retData
	};
};

export const getReferralData = async (data) => {
	let referralData = { error: true };
	try {
		const response = await ccbAxiosApiCaller.get(PATHS.GET_REFERRAL_DATA + data);
		const responseData = response && response.data;
		if (responseData && responseData.responseCode === APPLICATION_CONSTANTS.SUCCESS_RESPONSE_CODE) {
			return responseData;
		}
	} catch (error) {
		saveServerLogs(error.stack, 'error', PATHS.GET_REFERRAL_DATA + data);
		return referralData;
	}
};
