import React from 'react';
import Loadable from 'react-loadable';

import Loader from '../../common/Loader/Loader';

export default Loadable({
	loader: () => import('./benefitsDetail' /* webpackChunkName: 'benefitsDetail' */),
	loading: () => <Loader color='#723b63' />,
	modules: ['benefitsDetail']
});
