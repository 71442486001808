import React, { useCallback, useContext, useState, memo, useEffect } from 'react';

import * as S from './HeaderStyles';
import { Container, FlexDiv, Hamburger, GenericDiv, GenericSpan } from '../Styles';
import Button from '../Button/Button';
import DisplayContext from 'UtilityComponents/displayContext';
import MobileHeader from './MobileHeader';
import { redirectToResourcePage, conditionalClassNames, isMobileContext } from 'UtilityComponents/Util';
import { ASSET_PATH } from 'assetsPath';
import { PATHS } from 'AppConstants';

const mybizLogo = ASSET_PATH.IMAGES + 'mybizlogo.png';
const myBizLogoLight = ASSET_PATH.IMAGES + 'landing/myBizLogo_light.png';

const Header = ({
	preloadHeader,
	loadHeader,
	headerConfig = {},
	headerLinks = [],
	showMenuOnScroll = false,
	navOverHandler,
	isLanding,
	subText = 'By MakeMyTrip'
}) => {
	const displayName = useContext(DisplayContext).isMobile;
	const isMobile = isMobileContext(displayName);

	const [showMobileMenu, setShowMobileMenu] = useState(false);
	const [stickyHeader, setStickyHeader] = useState(false);
	const [showDesktopMenu, setShowDesktopMenu] = useState(!showMenuOnScroll);
	const { onNavClick, menuWidth, menus = [], scrollOffset = 60, isLoggedInUser, logoLink = PATHS.MYBIZ_URL } = headerConfig;

	useEffect(() => {
		const eventHandler = () => {
			const isScrolledDown = window.pageYOffset >= scrollOffset;
			setStickyHeader(isScrolledDown);
			if (showMenuOnScroll) setShowDesktopMenu(isScrolledDown);
		};
		if (typeof window !== 'undefined') {
			eventHandler();
			window.addEventListener('scroll', eventHandler);
		}
		return () => {
			if (typeof window !== 'undefined') {
				window.removeEventListener('scroll', eventHandler);
			}
		};
	}, [scrollOffset, showMenuOnScroll]);

	const handleMobileMenu = useCallback(() => {
		setShowMobileMenu(!showMobileMenu);
	}, [showMobileMenu]);

	const onNavClicked = useCallback((e) => {
		e.preventDefault();
		onNavClick(e);
	}, [onNavClick]);

	const loadMMtHeader = useCallback((e) => {
		if (!loadHeader && preloadHeader) {
			// preloadHeader(true);
		}
	}, [preloadHeader, loadHeader]);

	const headerClasses = conditionalClassNames({
		stickyHeaderLanding: stickyHeader
	});

	const hasMenus = menus && menus.length > 0 && showDesktopMenu;

	const renderMobileHeader = () => (
		<FlexDiv $width='50%' justify='flex-end' align='center'>
			{isLanding &&
				<GenericSpan right_M='20' onClick={redirectToResourcePage}>
					<GenericSpan f_weight='900' fsize='12px' color='#68beff' right_M='2'>Why myBiz?</GenericSpan>
					<S.LinkArrow color='#68beff' $bg='transparent' />
				</GenericSpan>}
			<Hamburger onMouseOver={loadMMtHeader} onClick={handleMobileMenu} bgColor={stickyHeader ? '#4a4a4a' : '#ffffff'} />
		</FlexDiv>
	);

	return (
		<>
			<S.HeaderContainerShadow className={headerClasses}>
				<Container>
					<S.HeaderWrapper>
						<FlexDiv justify='flex-start' align='center' $width='200px'>
							<FlexDiv align='center' $width='70px' height='70px'>
								<S.LogoLink href={logoLink}>
									<img crossOrigin='anonymous' src={stickyHeader ? mybizLogo : myBizLogoLight} alt='myBiz Logo' width='70' height='36' />

								</S.LogoLink>
							</FlexDiv>

							{subText && <S.LogoSubText>{subText}</S.LogoSubText>}
						</FlexDiv>
						{!isMobile && (
							<FlexDiv
								onMouseOver={loadMMtHeader}
								justify='flex-end'
								align='stretch'
								$width={menuWidth ? menuWidth : '640px'}>
								<S.NavigationList stickyHeader={stickyHeader} hasMenus={hasMenus}>
									{headerLinks.map((navlink) => (
										<a
											href={navlink.link ? ('#' + navlink.link) : navlink.pageurl}
											key={navlink.text}
											data-pageurl={navlink.pageurl}
											data-trackval={navlink.trackVal}
											data-link={navlink.link}
											data-modaltype={navlink.modalType}
											onMouseEnter={navOverHandler}
											onClick={onNavClicked}>
											{navlink.text}
										</a>
									))}
								</S.NavigationList>
								{showDesktopMenu && menus.map((item) => {
									const { btnTxt, btnType, btnName, btnShadow, btnWidth, height, handleClick, right_M = 0, show, loggedInCta, seoUrl } = item;
									return (
										show ?
											<GenericDiv right_M={right_M} key={btnName}>
												<Button
													btnTxt={(isLoggedInUser && loggedInCta) ? loggedInCta : btnTxt}
													btnType={btnType}
													btnName={btnName}
													btnShadow={btnShadow}
													btnWidth={btnWidth}
													height={height}
													handleClick={handleClick}
													seoUrl={seoUrl}
													isSeoAnchor
												/>
											</GenericDiv> :
											null
									);
								})}
							</FlexDiv>
						)}
						{isMobile && renderMobileHeader()}
						{showMobileMenu && <MobileHeader
							handleModal={handleMobileMenu}
							headerConfig={headerConfig}
							headerLinks={headerLinks}
						/>}
					</S.HeaderWrapper>
				</Container>
			</S.HeaderContainerShadow>
		</>
	);
};

export default memo(Header);
