import React from 'react';

import Modal from '../../../common/Modal/Modal';
import {
	GenericP,
	FlexDiv,
	GenericDiv
} from '../../../common/Styles';
import ActionButtons from '../actionButtons/actionButtons';
import { SIGNUP_BTN_TEXT } from '../../config';
import * as S from './BannerCarouselStyle';

const Modaldata = {
	title: '',
	img: null,
	imgstyle: null,
	btnTxt: null,
	customClass: 'videoModal'
};

const SubPop = ({ mybizLogo }) => (
	<S.SubImwrap $width='300px' $direction='column' top_P='45' left_P='10' right_P='10'>
		<img crossOrigin='anonymous' src={mybizLogo} alt='Mybiz Logo' width='70' height='36' />
		<GenericP f_weight='700' fsize='16px' color='#9b9b9b' bottom_M='20' top_M='10'>By MakeMyTrip</GenericP>
		<GenericP bottom_M='20' f_weight='700' fsize='22px'>
			Designed for businesses seeking flexibility and a wide range of choices.
		</GenericP>
		<ActionButtons $direction='column' signupTxt={SIGNUP_BTN_TEXT.loggedin} />
	</S.SubImwrap>);

const VideoModal = ({ handleVideo, isMobile, videoLink, mybizLogo }) => (
	<Modal
		Modaldata={Modaldata}
		close
		handleModal={handleVideo}
		submodalPos='right'
		subModal={isMobile ? null : <SubPop mybizLogo={mybizLogo} />}
		isResponsive>
		<FlexDiv height='100%' align={isMobile ? 'center' : 'flex-end'}>
			<GenericDiv right_M={isMobile ? '0' : '20'} $width={isMobile ? '100%' : '100%'}>
				<video width='100%' height='100%' controls autoPlay>
					<source src={videoLink} type='video/mp4' />
					<source src={videoLink} type='video/webm' />
					<source src={videoLink} type='video/ogg' />
					Your browser does not support the video tag.
				</video>
			</GenericDiv>
		</FlexDiv>
	</Modal>);

export default VideoModal;
