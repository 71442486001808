import styled from 'styled-components';

import { FlexDiv } from 'commonStyles';

export const StickyWrapper = styled(FlexDiv)`
	height: 80px;
	position: fixed;
	bottom: 0;
	z-index: 1;
	background: #fff;
	width: 100%;
	box-shadow: 0 1px 1px rgb(0 0 0 / 1%), 0 -1px 10px rgb(0 0 0 / 10%);
`;
