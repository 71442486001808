/*
     dispatch(setApiError({
            name: api names,
            data: {
                heading: heading Content,
                content: text content
            }
        }));
        default warning image will be shown for errro
        pass isPopup false if dont want to show popup
        dont pass data for default error popup
     dispatch(setApiSuccess({
        name: 'SHARED', popup image will be based on this value
        isPopup: true,
        data: {
            heading: data.popUp.heading,
            content: data.popUp.text
        }
    }));

    show success popup
*/

import React, { useCallback, useEffect } from 'react';

import Modal from 'common/Modal/Modal';
import { Footer } from './statusStyles';
import { GenericP, FlexDiv } from 'commonStyles';
import { PATHS } from 'AppConstants';
import { ASSET_PATH } from 'assetsPath';
import TimerFooter, { redirectAction } from 'common/TimerFooter/TimerFooter';
import CircularLoader from 'common/CircularLoader/CircularLoader';

const ERROR_CONTENT = 'Something went wrong. Please try again';
const ERROR_HEADING = 'OOPS!';

const timeOut = 10;

const FooterComp = ({ redirectURL, pageName }) => ( <Footer>
	<TimerFooter redirectTime={timeOut} redirectionUrl={redirectURL} pageName={pageName} />
                                                    </Footer>
);

const imgPaths = {
	'SUCCESS': ASSET_PATH.IMAGES + 'shortlist/bgGreentick.png',
	'REQUEST_MORE': ASSET_PATH.IMAGES + 'shortlist/bgGreentick.png',
	'SHARED': ASSET_PATH.IMAGES + 'cartFooter/cartfooter.png',
	'SHARED_ITIN': ASSET_PATH.IMAGES + 'cartFooter/cartfooter.png',
	'REQ_CANCELLED': ASSET_PATH.IMAGES + 'shortlist/bgGreentick.png',
	'ERR_DEFAULT': ASSET_PATH.IMAGES + 'shortlist/attention.png',
	'SERVER_ERR': ASSET_PATH.IMAGES + 'broken.png'
};

const popupSize = {
	'ERR_DEFAULT': '500px',
	'SUCCESS': '500px'
};

const styleImg = {
	'SUCCESS': 'bgtick',
	'SERVER_ERR': 'broken',
	'ERR_DEFAULT': 'errorImg',
	'SHARED': 'cartSucess',
	'REQUEST_MORE': 'bgtick',
	'SHARED_ITIN': 'cartSucess',
	'REQ_CANCELLED': 'bgtick'
};

const StatusPopup = ({ heading = false, content = false, handleModal, apiStatus, isFooter = true, isLoading, imgPath, customClass = '', imgClass, showContent = true, popupData, customFooter, redirectionUrl, pageName }) => {
	const imgUrl = imgPath || (apiStatus ? imgPaths[apiStatus] : imgPaths['ERR_DEFAULT']);
	const imgStyle = imgClass || (apiStatus ? styleImg[apiStatus] : styleImg['ERR_DEFAULT']);
	const size = apiStatus ? popupSize[apiStatus] : popupSize['ERR_DEFAULT'];
	const redirectURL = redirectionUrl ? redirectionUrl : PATHS.MYBIZ_URL;
	let data = {};
	if (!isLoading) {
		data = {
			img: imgUrl || imgPaths['ERR_DEFAULT'],
			imgStyle: imgStyle || styleImg['ERR_DEFAULT'],
			title: heading || ERROR_HEADING,
			titleAlign: 'center',
			customClass: `${customClass} shortlisterror`,
			...popupData
		};
	}

	useEffect(()=>{
		window.scrollTo(0, 0);
	}, []);

	const contentText = (content || ERROR_CONTENT);

	const modalConfig = {
		$width: size || popupSize['ERR_DEFAULT'],
		imgWidth: '60px',
		imgHeight: '54px',
		Modaldata: data,
		btnTxt: isLoading ? false : 'OK',
		btnType: 'Capsule',
		btnName: 'Primary',
		btnWidth: '130px',
		...popupData
	};

	const handleModalClick = useCallback(() => {
		if (isFooter && !customFooter) {
			redirectAction(redirectURL);
		} else {
			handleModal();
		}
	}, []);

	const renderFooter = () => {
		if (isFooter) {
			return customFooter ? customFooter : <FooterComp redirectURL={redirectURL} pageName={pageName} />;
		}
		return '';
	};

	return (
		<Modal
			close={!isLoading}
			{...modalConfig}
			handleClick={handleModalClick}
			handleModal={handleModalClick}
			footer={renderFooter()}>
			{isLoading &&
			<FlexDiv bottom_M='70' top_M='30'>
				<CircularLoader />
			</FlexDiv>}
			{showContent && contentText && <GenericP bottom_M='80' text_align='center' dangerouslySetInnerHTML={{ __html: contentText }} />}
		</Modal>
	);
};

export default StatusPopup;
