import styled, {keyframes} from 'styled-components';

const ldsRing = keyframes`
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
`;
  

export const Loader = styled.div`
    display: inline-flex;
    position: relative;
    width: ${p => p.ldrWidth ? p.ldrWidth : '24px'};
    height: ${p => p.ldrHeight ? p.ldrHeight : '24px'};
    ${p => p.pageLoader && `
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 12;
    `};
    > div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        border: 2px solid #f17531;
        border-radius: 50%;
        animation: ${ldsRing} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: #f17531 transparent transparent transparent;
    }
    > div:nth-child(1) {
        animation-delay: -0.45s;
    }
    > div:nth-child(2) {
        animation-delay: -0.3s;
    }
    > div:nth-child(3) {
        animation-delay: -0.15s;
    }
`;

export const LoaderShadow = styled.div`
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 11;
    background-color: rgba(0,0,0,0.7);
`;

