import React, { useEffect } from 'react';

import Timer from 'UtilityComponents/Timer';

export const redirectAction = (url) => {
	if (url) {
		window.location = url;
	} else {
		window.location.reload();
	}
};

const TimerFooter = ({
	redirectTime = 5,
	redirectionUrl,
	pageName
}) => {
	useEffect(() => {
		let timer;
		if (redirectTime) {
			timer = setTimeout(() => {
				redirectAction(redirectionUrl);
			}, redirectTime * 1000);
		}
		return () => {
			if (timer) {
				clearTimeout(timer);
			}
		};
	}, [redirectTime]);

	const page = pageName ? pageName : 'home';

	return (
		<p>Auto redirecting to {page} in  <span className='latoBold'><Timer startTime={redirectTime} /></span>.</p>
	);
};

export default TimerFooter;
