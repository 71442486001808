import styled, { keyframes } from 'styled-components';

const bouncedelay = keyframes`
    0%, 80%, 100% { -webkit-transform: scale(0) }
    40% { -webkit-transform: scale(1.0) }
`;

export const Wrapper = styled.div`
    display: ${(props) => props.display || 'block'};
    margin: ${(props) => props.margin ? props.margin : '2em auto'};
    text-align: center;
    font-size: ${(props) => props.size ? props.size : '18'}px;
    visibility: ${(props) => props.show ? 'visible' : 'hidden'};
    height: ${(props) => props.height ? props.height : 'auto'};
    overflow: ${(props) => props.show ? 'visible' : 'hidden'};
		background-color: ${(props) => props.$bg ? props.$bg : 'inherit'};
`;
export const Dots = styled.div`
    width: 4em;
    margin: 0 0.3em;
    text-align: center;
    width: 1em;
    height: ${(props) => props.dotsHeight ? props.dotsHeight : '1em'};
    background-color: ${(props) => props.color ? props.color : '#666'};
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: ${bouncedelay} 1.4s infinite ease-in-out both;
    animation: ${bouncedelay} 1.4s infinite ease-in-out both;
    &:nth-child(1) {
        -webkit-animation-delay: -0.32s;
        animation-delay: -0.32s;
    }
      
    &:nth-child(2) {
        -webkit-animation-delay: -0.16s;
        animation-delay: -0.16s;
    }
`;

const progressFirst = keyframes`
	0% {
			left: -100%;
			width: 100%;
	}
	100% {
			left: 100%;
			width: 10%;
	}
`;

const progressSecond = keyframes`
	0% {
			left: -150%;
			width: 100%;
	}
	100% {
			left: 100%;
			width: 10%;
	}
`;

export const LinearLoaderWrapper = styled.div`
	overflow: hidden;
	width: ${(props) => props.width ? props.width : '100%'};
	height: ${(props) => props.height ? props.height : '4px'};
	background-color: ${(props) => props.bgColor ? props.bgColor : '#EFEFEF'};
	margin: ${(props) => props.margin ? props.margin : 'auto'};
	& .indeterminate {
		position: relative;
		width: 100%;
		height: 100%;
	&:before {
		content: '';
		position: absolute;
		height: 100%;
		background-color: ${(props) => props.loaderColor ? props.loaderColor : '#4FC3F7'};
		animation: ${progressFirst} 1.5s infinite ease-out;
	}

	&:after {
		content: '';
		position: absolute;
		height: 100%;
		background-color: ${(props) => props.loaderColor ? props.loaderColor : '#4FC3F7'};
		animation: ${progressSecond} 1.5s infinite ease-in;
	}
}
`;
