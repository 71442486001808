import React from 'react';

import WhyMyBizModal from '../WhyMyBizModal';

function NavModals({
	navModalType,
	closeNavModal,
	navModalData
}) {
	return navModalType === 'whyMyBiz' ?
		<WhyMyBizModal closeNavModal={closeNavModal} businessDetails={navModalData} /> :
		null;
}

export default NavModals;
