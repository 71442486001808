import styled from 'styled-components';

export const ReactangularStrip = styled.div`
	text-decoration: none;
	text-transform: uppercase;
	display: inline-block;
	position: relative;
	min-width:200px;
	height:40px;
	color:#FFFFFF;
	background-color: #864724;
	text-align:center;
	line-height:40px;
	padding: 0px 20px;
	font-weight: 900;
	font-size: 16px;
	z-index: 4;
	&:before {
		content:"";
		position: absolute;
		left: 100%;
		top:0px;
		width:0px;
		height:0px;
		border-top:20px solid transparent;
		border-left:12px solid #864724;
		border-bottom:20px solid transparent;
	}
`;

export const StripArrow = styled.div`
	height: 40px;
	position: absolute;
	top: 0px;
	right: 0px;
	background: inherit;
	&:before, &:after {
		position: absolute;
		right: -20px;
		width: 10px; height: 50%;
		background: inherit;
		content: '';
	}
	&:before {
		top: 0;
		transform: skewX(30deg);
	}

	&:after {
		bottom: 0;
		transform: skewX(-30deg);
	} 
`;
