import Cookies from 'universal-cookie';
import pubSub from 'UtilityComponents/pubSub';
import { getCookie, getOrgId, getProfileDataProps } from 'UtilityComponents/Util';
const cookies = new Cookies();

export const OMNI_EVENT = 'pwaEvent';
export const trackerPubSub = pubSub();
let subscriptionList = [];

const SCRIPT_INTERVAL = 1000;
const SCRIPT_CHECK_LIMIT = 8;

const publishLogEvents = () => {
	trackerPubSub.publish(OMNI_EVENT, true);
	subscriptionList.forEach((subscription) => subscription.unsubscribe());
};

const canFireOmniture = () => {
	const mmtAuth = getCookie('mmt-auth');
	return !!(window.s && (!mmtAuth || (getProfileDataProps('email'))));
};

(function() {
	if (typeof window !== 'undefined' && window) {
		let callCount = 0;
		let isAvailable = canFireOmniture();
		if (!isAvailable) {
			const interval = setInterval(() => {
				isAvailable = canFireOmniture();
				if (isAvailable ) {
					publishLogEvents();
					clearInterval(interval);
				}
				if (++callCount >= SCRIPT_CHECK_LIMIT) {
					clearInterval(interval);
				}
			}, SCRIPT_INTERVAL);
		} else {
			publishLogEvents();
		}
	}
})();

const pageLoadEvent = ({ pageName, events }) => {
	let s = window.s;
	if (events && JSON.stringify(events) !== {}) {
		for (let key in events) {
			if (events.hasOwnProperty(key)) {
				s[key] = events[key];
			}
		}
	}
	const orgId = getOrgId();
	const mmtAuth = getCookie('mmt-auth');

	let corpAllocationInfo = (cookies && cookies.get('corpAllocationInfo'));
	corpAllocationInfo = corpAllocationInfo ? corpAllocationInfo : '';
	s.pageName = 'mybusinessadmin:' + pageName + '|corporate';
	s.eVar1 = window.navigator && window.navigator.userAgent;
	s.eVar47 = corpAllocationInfo + (s.eVar47 ? s.eVar47 : '');
	s.eVar34 = getProfileDataProps('email');
	s.events = 'event504';
	const mmtUuid = getProfileDataProps('mmtUuid');
	if (mmtAuth && mmtUuid) {
		const isAdmin = getProfileDataProps('isAdmin');
		s.eVar20 = mmtUuid;
		s.prop62 = `${orgId}||${isAdmin ? 'y' : 'n'}`;
		s.prop24 = 'mybiz admin';
	}

	let s_code = s.t();
	if (s_code) window.document.write(s_code);
};

export const logPageLoadEvents = (pageName, events) => {
	try {
		if (typeof window === 'undefined') {
			return;
		} else if (!canFireOmniture()) {
			const subscription = trackerPubSub.subscribe(OMNI_EVENT, () => {
				pageLoadEvent({ pageName, events });
			});
			subscriptionList.push(subscription);
		} else {
			pageLoadEvent({ pageName, events });
		}
	} catch (e) {
		// do nothing
	}
};

const clickEvent = ({ clickEventName, omniclickEventParam }) => {
	let s = window.s;

	if (clickEventName && omniclickEventParam) {
		s.linkTrackVars = 'channel,eVar1,eVar15,eVar24,eVar34,prop24,prop54,eVar22';
		for (let key in omniclickEventParam) {
			if (omniclickEventParam.hasOwnProperty(key)) {
				s.linkTrackVars += ',' + key;
				s[key] = omniclickEventParam[key];
			}
		}
		/**
             * Handling variant landing page
             * Will not effect handling from other pages
             */
		s.tl(this, 'o', clickEventName);
	}
};
export const logOmniClick = (clickEventName, omniclickEventParam) => {
	try {
		if (typeof window === 'undefined') {
			return;
		} else if (!canFireOmniture()) {
			trackerPubSub.subscribe(OMNI_EVENT, () => {
				clickEvent({ clickEventName, omniclickEventParam });
			});
		} else {
			clickEvent({ clickEventName, omniclickEventParam });
		}
	} catch (e) {
		console.log('tracking failed', e);
	}
};

export const trackClickEventSimple = (eventType, variant) => {
	const trackObj = { prop54: eventType };
	if (variant) {
		trackObj['eVar47'] = variant;
	}
	logOmniClick('new_landing_page_click_tracker', trackObj);
};

export const simpleErrorTracker = (errorType) => {
	logOmniClick('new_landing_error_tracker', {
		eVar22: errorType
	});
};
