/* eslint-disable react/display-name */

import React, { forwardRef } from 'react';
import { Wrapper, Dots } from './LoaderStyles';

const Loader = ({ forwardedRef, show = true, ...props })=> (
	<Wrapper className='loadwrap' show={show} height={show ? 'auto' : '5px'} ref={forwardedRef} {...props}>
		<Dots dotsHeight={show ? false : '0px'} {...props} />
		<Dots dotsHeight={show ? false : '0px'} {...props} />
		<Dots dotsHeight={show ? false : '0px'} {...props} />
	</Wrapper>
);
export default forwardRef((props, ref) => <Loader {...props} forwardedRef={ref} />);
