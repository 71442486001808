import styled, { css } from 'styled-components';
import { ASSET_PATH } from 'assetsPath';
import { GenericSpan } from 'commonStyles';

export const Button = styled.button`
	position:relative;
	border-radius: 2px;
	font-size: ${(props) => props.btnFont ? props.btnFont : '16px'};
	font-family: ${(props) => props.fontF ? props.fontF : 'inherit'};
	text-align: center;
	cursor: pointer;
	height: ${(props) => props.height ? props.height : 'auto'};
	min-height: ${(props) => props.height ? props.minHeight || props.height : 'auto'};
	width: ${(props) => props.btnWidth ? props.btnWidth : '100%'};
	box-shadow: ${(props) => props.btnShadow ? '0 1px 7px 0 rgba(0, 0, 0, 0.2);' : 'none'};
	font-weight: ${(props) => props.fontWeight ? props.fontWeight : '600'};
	padding:${(props) => props.padding ? props.padding : '12px 0'};
	margin:${(props) => props.margin ? props.margin : '0'};
	&:focus{ outline:0; }
	&.primaryBtnStyle{
	border: solid 1px #f17531;
	background: #f17531;
	color: #fff;
	& .loader{
		border-color:#fff;
		border-top: 2px solid transparent;
	}

	}
	&.secondaryBtnStyle {
		background-color:#ffffff;
		border: ${(props) => props.border ? props.border : '1px solid rgba(241, 117, 49, 0.5)'};
		color:#f17531;
		&:hover{
			background:#fdf1ed;
		}
		& .loader{
			border-color:#f17531;
			border-top: 2px solid transparent;
		}
	}
	&.primaryGradientBtnStyle{
		background: linear-gradient(90deg,#ff4959,#ff684a);
		color:#fff;
		& .loader{
			border-color:#fff;
			border-top: 2px solid transparent;
		}
	}
	&.FlatBtnStyle{
		border-radius:5px;
	}
	&.CapsuleBtnStyle{
		border-radius: 25px;
	}
	&.EllipseBtnStyle {
		padding: 11px 6px 11px 6px;
		border-radius: 96px;
		box-shadow: 0 1px 7px 0 rgba(0, 0, 0, 0.2);
		font-weight: 900;
		text-transform: uppercase;
	}
	&.transparentBtnStyle {
		background-color: #ffffff;
		width: ${(props) => (props.btnWidth ? props.btnWidth : '100%')};
		height: 74px;
		margin: 6px 0 7px 0px;
		font-family: Lato;
		font-size: 16px;
		font-weight: 900;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		color: #038cff;
		border-radius: 6px;
		border: dashed 1px #d7d7d7;
		img {
			width: 32px;
			height: 32px;
			margin-top: -4px;
			margin-right: 0px;
		}
	}
	&.disableBtnStyle{
		pointer-events:none;
		opacity:.3;
	}
	&.disabledwithLoader {
		background: ${(props) => props.disabledBg || '#d3d3d3' };
		opacity: 1;
		border-color: ${(props) => props.disabledBg || '#d3d3d3' };
	}
	&.ripple {
	position: relative;
	overflow: hidden;
	transform: translate3d(0, 0, 0);

	&:after {
		content: "";
		display: block;
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		pointer-events: none;
		background-image: radial-gradient(circle, #fff 10%, transparent 10.01%);
		background-repeat: no-repeat;
		background-position: 50%;
		transform: scale(10, 10);
		opacity: 0;
		transition: transform .5s, opacity 1s;
		}

		&:active:after {
			transform: scale(0, 0);
			opacity: .3;
			transition: 0s;
		}
	}
	a {
		color: inherit;
		font-size: inherit;
		&:hover {
			color: inherit;
			text-decoration: none;
		}
	}
	&.orgDetails {
		margin-top:0;
	}
`;

export const ButtonIcon = styled.img.attrs(({ src }) => ({ src }))`
	width: 20px;
	vertical-align: middle;
	margin-right: 10px;
`;

export const IconSpan = styled(GenericSpan)`
	display:inline-block;
	background: url(${ASSET_PATH.IMAGES}expense/expenseSprite1.png) no-repeat;
	background-size: 250px 250px;
	width: 20px;
	height: 20px;
	${(props) => {
		switch (props.icon) {
			case 'addIcon':
				return css`
						margin-top: -5px;    
						margin-left: -10px;    
						vertical-align: middle;
						background-position: -144px -217px;`;
			case 'addIconBlue': {
				return css`             
						margin-top: -8px;
						margin-left: -10px;
						vertical-align: middle;
						background-position: -217px 6px;`;
			}
			case 'addIconOrange': {
				return css`
						background: #fff;
						border-radius: 50%;
						color: #f1823e;
						position: relative;
						width: 1.5em;
						height: 1.5em;
						display: inline-block;
						vertical-align: middle;
						margin-right: 10px;
						&:after{
							content: '+';
							font-size: 2em;
							display: inline-block;
							font-weight: normal;
							position: absolute;
							top: 48%;
							left: 50%;
							line-height: 0.75em;
							transform: translate(-50%, -50%);
						}`;
			}
			case 'bulkImport':
				return css`
						width: 1.5em;
						height: 1.5em;
						display: inline-block;
						vertical-align: middle;
						margin-right: 10px;
						background: url(${ASSET_PATH.IMAGES}groupSprite.png) no-repeat;
						background-size: 250px 250px;
						background-position: -30px -4px`;
			default:
				break;
		}
	}
}
`;
