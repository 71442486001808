import Axios from 'axios';
import { getCookie, getXrequestId } from 'UtilityComponents/Util';
import { APPLICATION_CONSTANTS, PATHS } from 'AppConstants';

export const getccbAxiosConfig = (passHeaders, customHeaders, withCredentials = true) => {
	let ccbConfig = {
		baseURL: typeof window == 'undefined' ? PATHS.SERVER_CCB_URL : PATHS.CCB_URL,
		timeout: 30000,
		withCredentials: withCredentials
	};

	const requestId = typeof window == 'undefined' ? '' : getXrequestId();
	if (passHeaders) {
		ccbConfig['headers'] = {
			'Content-Type': 'application/json',
			'Accept': 'application/json',
			'X-Request-ID': requestId
		};
	}

	const auth = getCookie('mmt-auth');
	if (!withCredentials && auth) {
		ccbConfig['headers']['mmt-auth'] = auth;
	}

	if (customHeaders && passHeaders) {
		ccbConfig['headers'] = { ...ccbConfig['headers'], ...customHeaders };
	}
	return Axios.create(ccbConfig);
};

export const ccbAxiosApiCaller = getccbAxiosConfig(true, {
	[APPLICATION_CONSTANTS.DEVICE_TYPE_KEY]: APPLICATION_CONSTANTS.DEVICE_TYPE
}, false);
