import React, { memo } from 'react';
import { Helmet } from 'react-helmet';

import { APPLICATION_CONSTANTS } from '../AppConstants';

const MetaTags = (props) => {
	const { pageTitle, pageDesc } = APPLICATION_CONSTANTS;
	const { titleTxt = pageTitle, description = pageDesc } = props;
	return (
		<Helmet>
			<title>{titleTxt}</title>
			<meta name='description' content={description} />
		</Helmet>
	);
};

export default memo(MetaTags);
