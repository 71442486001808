import styled, { css } from 'styled-components';

import Select from 'react-select';
import { GenericSpan } from 'commonStyles';

export const FieldWrapper = styled.div`
    width: ${(props) => props.width ? props.width : '100%'};
    margin-bottom: ${(props) => props.bottom_M ? props.bottom_M : '25'}px;
		margin-right: ${(props) => props.right_M ? props.right_M : '0'}px;
		padding: ${(props) => props.padding ? props.padding : '0'};
    position: relative;
	
    label {
        font-size: 1rem;
        width: 100%;
        margin: 0;
				opacity: ${(p) => p.isDisabled ? '0.65' : 1};
				&.isRemove {
					display: flex;
    			align-items: center;
					.removeAct {
						margin-left: 15px;
					}
				}
				&.prefix-lbl {
					position: relative;
					input {
						padding-left: 45px;
					}
				}
				.prefix{
					position: absolute;
					z-index: 1;
					left: 10px;
					top: 14px;
					font-size: 1.15rem;
				}
    }

    .hasError {
        border: solid 1px #ff2323 !important;
    }
    &.half {
        width: calc(50% - 4px);
        @media screen and (max-device-width: 767px){
            width: 100%;
        }
		}
		&.col-5 {
			width: calc(4.5/12*100% - 10px);
			@media screen and (max-device-width: 767px){
					width: 100%;
			}
		}
		&.col-2 {
			width: calc(3/12*100% - 10px);
			@media screen and (max-device-width: 767px){
					width: 100%;
			}
		}
		&.col-9 {
			width: calc(9/12*100% - 10px);
			@media screen and (max-device-width: 767px){
					width: 100%;
			}
		}
		&.label-left {
			display: flex;
			align-items: center;
			justify-content: space-between;
			.fld-label {
				font-size: 1.15rem;
				min-width: 120px;
				font-weight: normal;
				margin-bottom: 0;
				.redText{
					display: none;
				}
			}
		}
		&.mgr-drop{
			&.label-left {
				.fld-label {
					min-width: 70px;
					font-weight: 900;
				}
			}
		}
		&.oneThird{
			width: calc(33% - 4px);
			@media screen and (max-device-width: 767px){
				width: 100%;
			}
		}
		
		.redText{
			color: #eb2026
		}
		&.phonefield {
			input{
				padding: 12px 12px 12px 90px;
			}
		}
		&.reqflds {
			.float-label {
				font-size: 11px;
				color: #7A6A7D;
				font-weight: 700;
				top: 0;
			}
			margin-right: 8px;
			input {
				font-size: 14px;
				font-weight: 900;
				line-height: 16px;
				padding-bottom: 0;
				padding-bottom: 10px;
				height: 53px;
				color: #28042F;
				border-color: #E7E7E7 !important;
				&:focus {
					background: #fff;
					box-shadow: none;
				}
			}
		}

		&.report{
			display:flex;
			justify-content: flex-start;
			flex-wrap: wrap;
			margin-bottom: 0px;
		}
		
		&.cheaper_flights, &.guest_booking, &.employee_booking_eligibility, &.acknowledge_to_mail_type {
			margin-bottom: 10px;
			label {
				margin-bottom: 10px;
			}
		}

		&.zeroMargin{
			margin-bottom: 0px;
		}
    @media screen and (max-device-width: 767px){
        input {
            border: .1rem solid #e7e7e7;
            background: #f7f7f7;
            font-weight: bold;
        }
    }
		${(props) => props.isOpenInput && css`
		input {
			background: transparent;
			border-width: 0 0 1px 0;
			padding: 8px 0;
			height: 30px;
			:focus {
				background: transparent;
				border-width: 0 0 1px 0;
				border-color: #cacaca;
				box-shadow: none;
			}
			
		}`}
		&.tripTag{
			margin-bottom: -10px;
		}
`;

export const ErrorFld = styled.span`
    font-size: 12px;
    line-height: 1.2;
    display: block;
    color: #ff2323;
    margin: 4px 0 0;
    font-weight: normal;
`;

export const InputBoxStyle = styled.input`
		width: 100%;
		display: inline-block;
		padding: ${(p) => p.isMobile && p.mmtTheme ? '18px 12px 6px 12px' : '12px 12px'};
		border: ${(props) => !props.isOpenInput && props.ifError ? 'solid 1px #ff2323 !important' :
		(props.mmtTheme ? 'solid 1px #f17531' :
			(props.greyTheme ? 'none' : 'solid 1px #cacaca')
		)};
		background-color: ${(p) => p.greyTheme ? '#f7f7f7' : '#ffffff'};
		color: ${(p) => p.greyTheme ? '#000000' : '#4a4a4a'};
		font-weight: ${(p) => p.greyTheme ? '900' : 'normal'};
		outline: none;
		font-size: ${(p) => p.mmtTheme ? '1rem' : '1.15rem'};
		height: ${(p) => p.mmtTheme ? '40px' : '48px'};
		opacity: ${(p) => p.mmtTheme ? '0.85' : '1'};
		border-radius: 4px;
		${(props) => props.greyTheme && css`
			padding: 17px 10px;
			font-size: ${(props) => props.fSize ? props.fSize : '1rem'};
			height: ${(props) => props.height ? props.height : 'auto'};
			outline: none;
			letter-spacing: 0.51px;
			text-overflow: ellipsis;
		`}

    &:focus {
        outline: 0 none;
        opacity: 100%;
				background: ${(p) => p.mmtTheme || p.greyTheme ? '#fff9f7' : 'inherit'};
				border-color: #f17531;
				box-shadow: ${(p) => p.greyTheme ? 'none' : '0 0 0 2px rgb(241 117 49 / 20%)'};;
    }

    &::-webkit-input-placeholder {
        color: #919191;
    }

    @media (max-width: 700px) {
        font-size: 14px;
    }
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
	.phonefield{
		padding : '12px 12px 12px 90px' ;
	}
	.groupCheckbox{
		display: flex;
		flex-wrap: wrap;
	}
`;

export const StyledReactSelect = styled(Select)`
    .custom-styles-select__control {
        padding: 0px 10px;
        border-radius: 2px;
        color: #4a4a4a;
				height: ${(p) => p.mmtTheme ? '40px' : '48px'};
        font-weight: normal;
				font-size: ${(p) => p.mmtTheme ? '1rem' : '1.15rem'};
        box-shadow: none;
        opacity: ${(p) => p.mmtTheme ? '50%' : '100%'};
        border: ${(p) => p.ifError ? 'solid 1px #ff2323 !important' : (p.mmtTheme ? 'solid 1px #f17531' : 'solid 1px #cacaca')};
        &:hover, &:focus {
            border: solid 1px #f17531;
        }
        @media screen and (max-device-width: 767px){
            border-color: #e7e7e7;
            background: #f7f7f7;
            opacity: 1
        }
    }
    .custom-styles-select__control--is-focused {
        opacity: 100%;
    }
    .custom-styles-select__value-container {
        padding: 0;
    }
    .custom-styles-select__single-value {
        color: #4a4a4a;
    }
    .custom-styles-select__placeholder {
        color: #919191;
        font-weight: normal;
    }
    .custom-styles-select__indicator-separator {
        display: none;
    }
    .custom-styles-select__input input {
        opacity: 0 !important;
    }
		.custom-styles-select__input input {
			opacity: 1 !important;
		}
		.custom-styles-select__option {
			cursor: pointer;
			padding: 15px;
		}

		.custom-styles-select__option:hover, .custom-styles-select__option--is-focused {
			background-color: rgba(241,117,49,0.06);
		}
		
		.custom-styles-select__option--is-selected  {
			background-color: rgba(241,117,49,0.15);
			color: #4a4a4a;
		}
	
		.custom-styles-select__indicator {
			padding: 0;
		}
		.custom-styles-select__placeholder{
			white-space: nowrap;
		}
    .custom-styles-select-small__menu,.custom-styles-select__menu {
        bottom: ${(props) => props.isOpenTop ? '100%' : 'auto'};
        top: ${(props) => props.isOpenTop ? 'auto' : '100%'};
        margin-bottom: ${(props) => props.isOpenTop ? '0' : '8px'};
				z-index: 2;
    }
		.trav_bf.custom-styles-select__single-value {
			font-size: 14px;
			font-weight: 900;
			line-height: 16px;
			padding-top: 10px;
			color: #28042F;
		}
		.trav_bf.custom-styles-select__option {
			font-size: 14px;
			font-weight: 900;
			line-height: 16px;
			color: #28042F;
		}
		.orgIdentification.custom-styles-select__control {
			font-size: 16px;
			opacity:100%;
			color:#000;
			border: solid 1px #cacaca;
		}
		.orgIdentification.custom-styles-select__control--is-disabled {
			opacity:50%;
			color:#4a4a4a;
			border: solid 1px #cacaca;
		}
`;

export const FloatLabel = styled(GenericSpan)`
    position: absolute;
    top: ${(props) => props.greyTheme ? '10px' : '0'};
    transform: translateY(0.5em);
		left:  12px;
		text-transform: ${(props) => props.greyTheme ? 'uppercase' : 'none'};
		transition: all 0.2s;
    ${(props) => props.active && css`
        color: #9b9b9b;
        transform: translateY(0);
				font-size:11px;
				${(props) => props.greyTheme && css`
						color: #ff6544;
						text-transform: uppercase;
						top: 0;
				`}
    `}
		${(props) => !props.focus && css`
			color:  ${(props) => props.color ? props.color : '#4a4a4a'};
		`}
`;

export const TextAreaWrapper = styled.div`
		display: inline-block;
		width: 100%;
		padding-bottom: 12px;
		padding-top: 2px;
		position: relative;
		border: ${(p) => p.ifError ? 'solid 1px #ff2323 !important' :
		(p.mmtTheme ? 'solid 1px #f17531' : 'solid 1px #cacaca')};
		background-color: #ffffff;
		border-radius: 4px;
		opacity: ${(p) => p.mmtTheme ? '50%' : '100%'};
		font-size: ${(p) => p.mmtTheme ? '1rem' : '1.15rem'};
		& .limitWrap {
			position: absolute;
			bottom: 0px;
			color: #f17531;
			font-size: 12px;
			display: block;
			width: 100%;
			text-align: right;
			border-radius: 4px;
		}
		&:focus-within {
			opacity: 100%;
			border-color: #f17531;
			box-shadow: 0 0 0 2px rgb(241 117 49 / 20%);
			& .formField-textArea {
				outline: none;
				outline: 0 none;
				background: ${(p) => p.mmtTheme ? '#fff9f7' : 'inherit'};
			}
			& .limitWrap {
				background: ${(p) => p.mmtTheme ? '#fff9f7' : 'inherit'};
			}
    }
		&::-webkit-outer-spin-button,
		&::-webkit-inner-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}
`;

export const TextAreaStyle = styled.textarea`
	resize: none;
	width: 100%;
	display: inline-block;
	background-color: #ffffff;
	color: #4a4a4a;
	font-weight: normal;
	outline: none;
	font-size: ${(p) => p.mmtTheme ? '1rem' : '1.15rem'};;
	border-radius: 4px;
	opacity: ${(p) => p.mmtTheme ? '0.85' : '1'};;
	border: none;
	padding: 12px 12px 0px 12px;

	&::-webkit-input-placeholder {
		color: #919191;
	}
	@media (max-width: 700px) {
		font-size: 14px;
	}
`;

export const InfoTextWithValidation = styled.p`
    font-size: 12px;
    display: flex;
    align-items: baseline;
    color: #919191;
    margin-bottom: 5px;
    .valid {
        color: #35b29f;
    }
    .inValid {
        color: red;
    }
`;

export const PasswordValidations = styled.div`
    margin-top: 8px;
`;

export const Tick = styled.span`
    width: 10px;
    height: 10px;
    transform: rotate(45deg);
    position: relative;
    margin-right: 10px;
    &:before {
        content: '';
        position: absolute;
        width: 2px;
        height: 10px;
        background-color: #35b29f;
        left: 7px;
        top: -2px;
    }
    &:after {
        content: '';
        position: absolute;
        width: 6px;
        height: 2px;
        background-color: #35b29f;
        left: 3px;
        top: 6px;
    }
`;

export const Cross = styled.span`
    width: 10px;
    height: 10px;
    position: relative;
    margin-right: 10px;
    &:before, &:after {
        position: absolute;
        left: 5px;
        content: ' ';
        height: 10px;
        width: 1px;
        background-color: red;
    }
    &:before {
        transform: rotate(45deg);
    }
    &:after {
        transform: rotate(-45deg);
    }
`;

export const InfoText = styled.span`
    font-size: 12px;
    line-height: 1.5;
    display: block;
    color: #919191;
    margin-top: 8px;
`;
