import { PATHS } from 'AppConstants';
import React, { memo, useCallback } from 'react';

import { GenericDiv, FlexDiv, ClickText } from '../../../common/Styles';
import { LandingSpriteIcon } from '../../Styles';
import { trackClickEvents } from '../../utils';
import * as S from './BannerCarouselStyle';

const OfferSection = ({ bgColor, offerDescp, offerTagBgColor, offerTag, iconName, bgGradient = '', link, indexNo, isShowSEO }) => {
	const goTooffer = useCallback((e) => {
		e.preventDefault();
		trackClickEvents('Explore_more_' + (indexNo + 1));
		window.open(link);
	}, [link, indexNo]);

	return (
		<S.OfferSectionContainer bgColor={bgColor} itemProp='offers' itemScope itemType={`${PATHS.SEO_SCHEMA_URL}/Offer`}>
			<FlexDiv justify='flex-start' align='center' $width='auto'>
				<LandingSpriteIcon icon={iconName} />
				<GenericDiv itemProp='description' $width='100%' left_M='5' fsize='16' f_weight='600'>
					{offerDescp}
				</GenericDiv>
				<meta itemProp='price' content='0' />
				<meta itemProp='priceCurrency' content='INR' />
				<S.OfferSectionTag bgGradient={bgGradient} offerTagBgColor={offerTagBgColor}>{offerTag}</S.OfferSectionTag>
			</FlexDiv>
			<ClickText onClick={goTooffer} color='#038cff'><a itemProp='url' href={link} />Explore More</ClickText>
		</S.OfferSectionContainer>
	);
};

export default (OfferSection);
