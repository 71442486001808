import React, { useState } from 'react';
import { useLocation } from 'react-router';

import LandingTemplate from './landingTemplate';
import LandingResource from './landingResource';
import NavModals from './components/NavModals/NavModals';
import { NavigationLinks, SMENavigationLinks } from './config';
import { PATHS } from 'AppConstants';
import { isLoggedIn } from 'UtilityComponents/Util';

const LandingPage = (props) => {
	const { children, pageStripName = '', disableMeta = false, contextData, isCorpSeo, additionalSeoData } = props;

	const [navModalType, setNavModalType] = useState(props.isPreLoad && !isCorpSeo ? 'whyMyBiz' : '');
	const { pathname } = useLocation();

	const isSME = pathname === PATHS.LANDING_SME_FEST;
	const isLanding = pathname === PATHS.LANDING_PAGE || isSME;
	const isBenefit = pathname === PATHS.MYBIZ_BENEFIT;

	const navigations = isSME ? SMENavigationLinks : NavigationLinks;

	const isLoggedInUser = isLoggedIn() || props.isLoggedInUserServer;

	const navOverHandler = (e) => {
		e && e.preventDefault && e.preventDefault();
		const modalType = e.target.dataset.modaltype;
		setNavModalType(modalType);
	};

	const navOutHandler = () => {
		setNavModalType('');
	};

	const navModalRender = (navModalData) => (
		<NavModals
			navModalType={navModalType}
			showNavModal={navOverHandler}
			closeNavModal={navOutHandler}
			navModalData={navModalData}
		/>
	);

	const isSsrData = contextData?.bizDetails?.app?.qrCode;

	return (
		<LandingTemplate
			isLoggedInUser={isLoggedInUser}
			headerLinks={navigations}
			navOverHandler={navOverHandler}
			navOutHandler={navOutHandler}
			headerActions={{ showDemo: true, showSignup: true }}
			headerConfig={{
				scrollOffset: isBenefit ? 0 : 20,
				menuWidth: '950px'
			}}
			showChatBot={isLanding}
			isLanding={isLanding}
			isSME={isSME}
			enableDropff
			{...props}>
			<LandingResource
				disableMeta={disableMeta}
				navModalRender={navModalRender}
				pageStripName={pageStripName}
				isSME={isSME}
				isCorpSeo={isCorpSeo}
				isSsrData={isSsrData}
				additionalSeoData={additionalSeoData}>
				{children}
			</LandingResource>
		</LandingTemplate>
	);
};

export default LandingPage;
