import styled, { keyframes, css } from 'styled-components';

import {
	Container,
	GenericP,
	FlexDiv,
	Heading
} from 'commonStyles';
import { ASSET_PATH } from '../../../../assetsPath';

const subImg = `${ASSET_PATH.IMAGES}landing/subModalBg.png`;

export const MainWrapper = styled.section(
	({ showBgOverlay, top_P, height, theme: { down, breakpoints } }) => `
      width: 100%;
      height: ${height || 666}px;
      margin-top: -80px;
      padding-top: ${top_P || 80}px;
      padding-bottom: 30px;
			background-color: #0f1a2e;
			position:relative;
			.mainImg, .bgOverlay {
				object-fit: cover;
				top: 0;
				width: 100%;
				height: 100%;
			}
			.bgOverlay{
				position: fixed;
				background-image: linear-gradient(to left,rgba(0,0,0,0) 1%,#0b1a2f 98%);
			}
      ${down(breakpoints.md)} {
				.mainImg {
					display: none;
				}
				background-attachment: fixed;
      	background-position: center;
      	background-repeat: no-repeat;
      	background-size: cover;
        background-image: linear-gradient(to bottom,#0f1a2e 0%,#46353e);
        }

				${showBgOverlay && `
				position: relative;
				&:after {
					position: absolute;
					top: 0;
					width: 0;
					width: 100%;
					height: 100%;
					content: '';
					display: block;
					box-shadow: 0 0 0 500px rgb(0 0 0 / 30%) inset;
				}`
}
  `
);

export const MainHeading = styled(Heading)`
	position: relative;
	top: ${(p)=>p.$top || '0'};
`;

export const BannerWrapper = styled(Container)(
	({ isCorpSeo, theme: { down, breakpoints } }) => `
		position: relative;
    padding: 50px 0;
    margin: 0 auto;
    padding: 80px 0 0;
    display: flex;
    justify-content: space-between;
    ${down(breakpoints.md)} {
      padding: 25px 16px;
      position: relative;
    }
    .slick-dots {
      top: ${isCorpSeo ? '-65px' : '-30px'};
      width: 100%;
      margin: 0;
      list-style: none;
      text-align: center;
      position: absolute;
    }
    .slick-dots li {
      position: relative;
      display: inline-block;
      width: 20px;
      margin: 0 5px;
      padding: 0;
      cursor: pointer;
      background-color: rgba(255,255,255,0.2)
  }
  .slick-next.slick-disabled:before,
  .slick-prev.slick-disabled:before {
      opacity: 0.25;
  }
  .slick-dots li button {
    font-size: 0;
    line-height: 0;
    display: block;
    width: 20px;
    height: 20px;
    padding: 5px;
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: 0;
    background: 0 0;
  }
    `
);

export const HeaderWrapper = styled.div(
	({ theme: { down, breakpoints } }) => `
    ${down(breakpoints.md)} {
      max-height: 200px
      height: 200px;
    }
  `
);

export const HomepageBannerContainer = styled.div(
	({ theme: { down, breakpoints } }) => `
    display:flex !important;
    align-items: center;
    justify-content: space-between;
    outline: 0;
    .desc{
      min-height: 100px;
    }
    ${down(breakpoints.md)} {
      flex-direction: column;
      align-items: flex-start;
      .desc{
        min-height: 85px;
      }
    }
  `
);

export const HomepageBannerLeft = styled.div(
	({ $width, isBannerImage, theme: { down, breakpoints } }) => `
	display: inline-block;
	width: ${$width ? $width : 'auto'};
  ${down(breakpoints.tb)} {
    width: ${$width ? $width : '50%'};
    margin-right: 25px;
  }
  ${down(breakpoints.md)} {
      width: 100%;
      button{
        margin-top: ${isBannerImage ? '70%' : '0px'};
      }
  }
  `
);

export const HomepageBannerRight = styled.div(
	({ isCorpSeo, theme: { down, breakpoints } }) => `
	width: 43%;
	display : ${isCorpSeo ? 'flex' : 'block'};
	justify-content: center;
  ${down(breakpoints.md)} {
    width: 100%;
    position: absolute;
    top:215px;
    left: 50%;
    z-index: 2;
		transform: translateX(-50%);
  }

`
);

export const PlayBtn = styled.button`
  width: 60px;
  height: 60px;
  background: #ffffff;
  margin-bottom: 8px;
  &:focus {
    outline: none;
  }
  border: none;
  border-radius: 100%;
  cursor: pointer;
  &::after {
    content: "";
    display: inline-block;
    position: relative;
    top: 1px;
    left: 3px;
    border-style: solid;
    border-width: 10px 0 10px 16px;
    border-color: transparent transparent transparent #ff664b;
  }
`;

export const VideoText = styled.p`
  color: #ffffff;
  font-weight: 900;
`;

export const VideoOverlayContent = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  text-align: center;
`;

export const OverlayWrap = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgb(0 0 0 / 20%);
  top: 0;
`;

export const VideoPreviewWrap = styled.div`
  position: relative;
	z-index: 1;
  cursor: pointer;
  &:hover ${OverlayWrap} {
    background: rgb(0 0 0 / 0%);
    transition: background 1000ms linear;
  }
  &:hover ${PlayBtn} {
    background: #ff664b;
    transition: background 500ms linear;
    &::after {
      border-color: transparent transparent transparent #ffffff;
      transition: border-color 500ms linear;
    }
  }
  &:hover ${VideoText} {
    color: #000000;
    transition: color 500ms linear;
  }
`;

export const VideoOverlayWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  border-radius: 8px;
  position: relative;
`;

export const CloseIcon = styled.span`
	position:absolute;
	top: 50px;
	right: -30px;
	width: 50px;
	height: 50px;
	cursor: pointer;
	&:before, &:after {
		position: absolute;
		content: ' ';
		height: 18px;
		width: 2px;
		background-color: #fff;
	}
	&:before {
		transform: rotate(45deg);
	}
	&:after {
		transform: rotate(-45deg);
	}
	&:hover {
		cursor: pointer;
	}
`;

export const BannerBtnWrap = styled.div(
	({ theme: { down, breakpoints } }) => `

      ${down(breakpoints.md)} {
         flex-direction: column;
         padding: 30px 0 25px 0;
      }
      display: flex;
      padding: 55px 0 70px 0;
      > button {
        &:first-child {
          ${down(breakpoints.md)} {
            margin-bottom: 16px;
            margin-right:0;
         }
          margin-right: 20px;
        }
      }
  
    `
);

export const BannerDotsWrap = styled.div(
	({ theme: { down, breakpoints } }) => `
  > ul > li {
    &.slick-active {
      width: 50px;
      background-color: #ff664b;
    }
    width: 20px;
    height: 8px;
    margin: 0 5px 0 0;
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.06);
    > button {
      &::before {
        display: none;
      }
    }
  }
  ${down(breakpoints.md)} {
    display:none !important;
  }
`
);

export const FadeIn = keyframes`
    0% {opacity:0;}
    100% {opacity:1;}`;

export const VideoPlayerAnimate = styled.div`
  animation: ${FadeIn} 0.5s ease-in-out forwards;
`;

/** ****** Offer Section wrapper styles  *********/

export const OfferSectionContainer = styled.div(
	({ bgColor, theme: { down, breakpoints } }) => `
      background-color: ${bgColor || '#d3fff5'};
      color:#0c3b39;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px;
      border-radius: 2px;
      position:relative;
      font-weight: 600;
      width: 100%;
      margin-top: 30px;
      ${down(breakpoints.tb)} {
        width: 100%;
				padding: 10px;
      }
    `
);

export const OfferSectionTag = styled.div `
      background: ${(props) => props.bgGradient ? props.bgGradient : 'linear-gradient(80deg, #43e1a8, #28beb2)'};
      display: flex;
      align-items: center;
      padding: 0 8px;
      border-radius: 8px;
      height: 15px;
      position: absolute;
      transform: translateY(-50%);
      top: 0;
      font-size: 10px;
      color: #fff;
      font-weight: 700;
`;

export const BannerImgWrap = styled.div(
	({ width, theme: { down, between, breakpoints } }) => `
		display: inline-block;
		vertical-align:top;
    height: 355px;
    img {
      height: auto;
    }
    ${down(breakpoints.tb)} {
      width: 42%;
      min-height: 200px;
      height: 280px;
    }
    ${down(breakpoints.md)} {
      img {
        height: 250px;
      }
			position: absolute;
			top:168px;
			left: 50%;
			z-index: 2;
      width: 100%;
      height: auto;
      margin-right: 0;
			transform: translateX(-50%);
    }
    ${between( breakpoints.s, breakpoints.md)} {
      img {
        height: auto;
      }
    }
    width:${width ? width : '43%' };
    margin-right: 0;
    min-height: 310px;
    padding-left: 3%;

  `
);

export const SubImwrap = styled(FlexDiv)`
	background-image: url(${subImg});
	background-size: cover;
	background-repeat: no-repeat;
	color: #fff;
	max-width: 200px;
	button {
		width: 100%;
		height: 45px;
		font-size: 16px;
		margin-bottom: 10px;
	}
`;
