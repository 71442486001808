import React, { useContext } from 'react';

import * as S from './SignUpFooterStyles';
import DisplayContext from '../../../UtilityComponents/displayContext';
import ActionButtons from '../actionButtons/actionButtons';
import { Heading, GenericDiv } from '../../../common/Styles';
import landingContext from '../../landingContext';
import { LANDING_CONSTS } from 'AppConstants';

const SignUpFooter = ({ onSignUpClicked, bg }) => {
	const displayName = useContext(DisplayContext).isMobile;
	const { isLoggedInUser } = useContext(landingContext) || {};
	const isMobile = displayName == 'mobile';

	return (
		<GenericDiv as='section' position='relative' overflow='hidden'>
			<S.BenefitsCurve $bg={bg} />
			<S.SignUpFooterWrapper>
				<S.Conatiner isFlex justify='space-between'>
					<Heading as='h3' marBt={isMobile ? '20px' : 0} font_size={`${isMobile ? '22' : '40'}`} color='#fff'>
						Start Saving on Your Travel Budget.
					</Heading>
					<ActionButtons
						show={isLoggedInUser ? LANDING_CONSTS.SIGNUP : LANDING_CONSTS.ALL}
						signupTxt={isLoggedInUser ? 'GET STARTED' : 'SIGN UP NOW'}
						onSignUpClicked={onSignUpClicked}
						$width='41%'
						indexNo='3'
					/>
				</S.Conatiner>
			</S.SignUpFooterWrapper>
		</GenericDiv>
	);
};

export default SignUpFooter;
