import React, { useEffect, useState } from 'react';
import { convertTime } from 'UtilityComponents/Util';

const Timer = ({ startTime }) => {
	const [time, setTime] = useState(Number(startTime));

	useEffect(()=> {
		const interval = setInterval(() => {
			setTime((time) => time > 0 ? time - 1 : time);
		}, 1000);

		return () => {
			clearInterval(interval);
		};
	}, []);
	const showTime = convertTime(time);
	const { hr, min, sec } = showTime;
	return (
		<span>
			{hr > 0 && <span>{hr + (hr > 1 ? ' hrs' : ' hr')}</span>}
			{min > 0 && <span>{min + (min > 1 ? ' mins' : ' min')}</span>}
			<span>{sec + (sec > 1 ? ' secs' : ' sec')}</span>
		</span>
	);
};

export default Timer;
