/**
 * props :
 * Modaldata: {
 * title: modal header
 * img: modal imgurl
 * imgstyle: custom style class
 * handleClick: modal submit action
 * handleModal: modal handling
 * btnTxt: modal button text
 * }
 */

import React, { useEffect } from 'react';
import Button from '../Button/Button';
import { ErrorText, GenericP, GenericDiv, UtilitySpriteIcon } from '../Styles';
import {
	ButtonWrapper, ModalMainWrapper, ImgWrapper, ModalWrapper, Tilte, Disc, ModalBg, Subtitle,
	ModalFooter, MobileHeader, BackButton, ContentWrapper
} from './ModalStyles';
import { CloseButton } from 'common/CloseButtonStyle';
import { getXrequestId } from 'UtilityComponents/Util';

const Modal = (props) => {
	const { Modaldata: modalData = {}, isResponsive, type, height, $width, handleModal, isMobile, btnTxt, btnType,
		btnDisabled, btnName, handleClick, btnWidth, footer, footerBtm, errorMsg, close, submodalPos, subModal, noModalBg = false } = props;

	const handleModalClick = () => {
		if (handleModal) {
			handleModal(false);
		}
	};

	useEffect(()=>{
		document.body.style.overflow = 'hidden';
		return () => document.body.style.overflow = 'auto';
	}, []);

	return (
		<ModalMainWrapper isResponsive={isResponsive} type={type} className={modalData.customClass}>
			{!noModalBg && <ModalBg className='modalBg' onClick={handleModalClick} />}

			<ModalWrapper
				submodalPos={submodalPos}
				isSub={subModal}
				type={type}
				$width={$width}
				height={height}
				className={'modalWrapper ' + modalData.customWrapperClass}>
				{close && !(isMobile && modalData.mobileTitle) && <CloseButton className={modalData.closeBtnClass || 'close-ico'} onClick={handleModalClick} />}
				{subModal && <GenericDiv className='submodal custom-scroll hide_hr-scroll'>{subModal}</GenericDiv>}
				<ContentWrapper className='content custom-scroll'>
					{modalData.mobileTitle && isMobile &&
						<MobileHeader className='modal-mobileHeader' align='flex-end' justify='flex-start'>
							<BackButton onClick={handleModalClick}>
								<UtilitySpriteIcon className='backArrow back-arrow' />
							</BackButton>
							<GenericP fsize='18px' f_weight='bold'>{modalData.mobileTitle}</GenericP>
						</MobileHeader>}
					{modalData.img &&
						<ImgWrapper
							{...props}
							className={'imgWrap ' + modalData.imgStyle}
							style={{ backgroundImage: `url(${modalData.img})` }}><span />
						</ImgWrapper>}
					{modalData.title && <Tilte className='modal-title'>{modalData.title}</Tilte>}
					{errorMsg &&
						<ErrorText
							className='err-msg'
							margin='0 0 15px 0'
							f_size='12' color=''>{errorMsg} {getXrequestId() ? ` (error:${getXrequestId()})` : ''}
						</ErrorText>}
					{modalData.subTitle && <Subtitle className='subtitle'>{modalData.subTitle}</Subtitle>}
					{modalData.disc && <Disc className='desc'>{modalData.disc}</Disc>}
					{props.children}
					{btnTxt &&
						<ButtonWrapper btnWidth={btnWidth} className='buttonWrapper'>
							<Button
								disabled={btnDisabled}
								btnType={btnType}
								btnTxt={btnTxt}
								btnName={btnName}
								handleClick={handleClick}
								btnWidth={btnWidth}
							/>
						</ButtonWrapper>}
					{footer && <ModalFooter footerBtm={footerBtm}>{footer}</ModalFooter>}
				</ContentWrapper>
			</ModalWrapper>

		</ModalMainWrapper>

	);
};
export default Modal;
