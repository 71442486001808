import styled from 'styled-components';

import { FlexDiv } from '../../../common/Styles';

export const BtnWrapper = styled(FlexDiv)(
	({ zIndex, theme: { down, breakpoints } }) => `
    z-index: ${zIndex || '0'};
		button {
      &:first-child {
        margin-right: 20px;
      }
    }
    ${down(breakpoints.tb)} {
      width: 100%;
      button {
        width: 38%;
        &:first-child {
          width: calc(61% - 20px);
        }
      }
    }
    ${down(breakpoints.md)} {
      width: 100%;
      button {
        width: 100%;
        &:first-child {
          width: 100%;
          margin-bottom: 10px;
        }
      }
    }
  `
);
