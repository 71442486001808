import React, { useEffect, useState } from 'react';

export default function useDeviceDetect() {
	const [isMobile, setMobile] = useState(typeof window === 'undefined' ? false : window.innerWidth <= 768);
	const [isLandScape, setisLandScape] = useState(false);
	const updateIsmobile = () => {
		try {
			const userAgent =
			typeof window.navigator === 'undefined' ? '' : navigator.userAgent;
			const mobile = Boolean(
				userAgent.match(
					/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
				)
			);
			setMobile(mobile);
			setisLandScape(window.innerWidth > 769 && mobile);
		} catch (error) {
			setMobile(false);
			setisLandScape(false);
		}
	};
	useEffect(() => {
		updateIsmobile();
		window.addEventListener('resize', updateIsmobile);
		return (()=>{
			window.removeEventListener('resize', updateIsmobile);
		});
	}, []);
	return { isMobile, isLandScape };
}

export const isMobileContext = (context) => (context === 'mobile');
