export default {
    colors:{
        blackText: "#000000",
        darkText: "#4a4a4a",
        greyText: "#9b9b9b",
        lightGreyText: "#747474",
        whiteText: "#ffffff",
        blueText: "#008cff",
        liteBlueText: "#a9d4f6",
        greenText: "#249995",
        lightGreenText: "#33d18f",
        redText: "#eb2026",
        yellowText: "#f0991a",
        orangeText: "#f17531",
        pastelGreen: '#d3fff5',
        pastelYellow: '#fff4d3',
        pastelBlue: '#d3f2ff'
    },
    gradientColor:{
        lighGreen: 'linear-gradient(80deg, #43e1a8, #28beb2)',
        lightYellow: 'linear-gradient(260deg, #f09819 57%, #f3d452)',
        lightBlue: 'linear-gradient(260deg, #3a7bd5, #00d2ff);'
    },
    breakpoints:{
        xs:'360px',
        md: '768px',
        s:'767px',
        tb: '1024px',
        lg: '1200px',
    },
    up: (breakpoints, vertical = false) => `@media (min-${vertical ? 'height' : 'width'}: calc(${breakpoints} + 0.02px))`,
    down: (breakpoints, vertical = false) => `@media (max-${vertical ? 'height' : 'width'}: ${breakpoints})`,
    between: (breakpointMin, breakpointMax, vertical = false) => `@media (max-${vertical ? 'height' : 'width'}: ${breakpointMax}) and (min-${vertical ? 'height' : 'width'}: calc(${breakpointMin} + 0.02px))`,
};

