import React, { useContext, useRef, useEffect, memo, useMemo, useState } from 'react';
import Slider from 'react-slick';

import * as S from './buisnessPartnerStyles';
import DisplayContext from '../../../UtilityComponents/displayContext';
import { formatCurrency } from '../../../UtilityComponents/Util';

import {
	ResponsiveContainer,
	FlexDiv,
	GenericP,
	GenericSpan
} from '../../../common/Styles';
import { LandingSpriteIcon } from '../../Styles';
import { loadNextComponent } from '../../utils';
import { serverPersuation } from '../../config';

const PERS_TIME = 4;

const BuisnessPartners = ({ showNextComp, nextComp, orgDets, updateCurrElem }) => {
	const [sliderSettings, setSliderSettings] = useState({
		infinite: false,
		autoplay: false
	});

	const [persuationIndex, setPersuationIndex] = useState(0);
	const displayName = useContext(DisplayContext).isMobile;
	const isMobile = displayName == 'mobile';

	const { counts, topOrgs = [], persuation = [] } = orgDets || {};

	const persuations = orgDets ? persuation : serverPersuation;

	const refHtml = useRef();
	const sliderRef = useRef();

	useEffect(()=>{
		setTimeout(() => {
			setSliderSettings((state) => ({
				...state,
				infinite: true,
				autoplay: true
			}));
			sliderRef && sliderRef.current && sliderRef.current.slickPlay();
		}, PERS_TIME * 1000);
	}, []);

	const settings = useMemo(()=>({
		...sliderSettings,
		dots: false,
		speed: 2000,
		cssEase: 'linear',
		autoplaySpeed: 2000,
		variableWidth: true
	}), [sliderSettings]);

	useEffect(() => {
		loadNextComponent(refHtml, showNextComp, nextComp);
		updateCurrElem(refHtml);
	}, []);

	useEffect(() => {
		if (persuations && persuations.length > 1) {
			setInterval(() => {
				setPersuationIndex((index) => {
					let retIndex = 0;
					if (index < persuations.length - 1) {
						retIndex = index + 1;
					}
					return retIndex;
				});
			}, PERS_TIME * 1000);
		}
	}, [persuations]);

	const pers = persuations && persuations[persuationIndex];

	return (
		<S.BuisnessPartnerContainerWrap ref={refHtml}>
			<ResponsiveContainer>
				<FlexDiv align='center' justify='space-between' directionInMobile='column'>
					<FlexDiv
						fsize={22}
						align='flex-start'
						directionInMobile='column'
						className='latoBlack'>
						<FlexDiv align='baseline' justify='flex-start'>
							<GenericP
								f_weight='900'
								right_M='10'
								color='#b5b5b5'
								fsize={isMobile ? '16px' : '22px'}>
								Partner to
							</GenericP>

							<GenericSpan
								fsize={isMobile ? '22px' : '40px'}
								color='#3b385e'
								right_M='5'>
								{formatCurrency(counts)}
							</GenericSpan>
							<GenericSpan
								fsize={isMobile ? '16px' : '22px'}
								color='#3b385e'>
									&nbsp; &nbsp;Businesses
							</GenericSpan>
						</FlexDiv>
					</FlexDiv>
					{topOrgs && topOrgs.length > 0 && (
						<S.PartneredCompaniesWrap>
							<Slider {...settings} ref={sliderRef}>
								{topOrgs.map((org) =>
									<div key={org.name}>
										<img
											className='partnerImg'
											$width='auto'
											height='24'
											src={org.url}
											alt={org.name}
											loading='lazy'
										/>
									</div>)}
							</Slider>
						</S.PartneredCompaniesWrap>
					)}
				</FlexDiv>
				<FlexDiv directionInMobile='column' justify='flex-start'>
					{pers && (
						<S.BuisnessPartnerAnnouncement isAnimate={persuations && persuations.length > 1} time={PERS_TIME}>
							{pers.icon ? <img crossOrigin='anonymous' $width='48' height='48' src={pers.icon} alt='persuation icon' /> : <LandingSpriteIcon icon='icSpeaker' />}
							<GenericP fsize='16px' left_M='14' color='#0c3b39'>
								<GenericSpan right_M='5' f_weight='900' color='#f17531'>{pers.text}</GenericSpan>
								{pers.text1}
							</GenericP>
						</S.BuisnessPartnerAnnouncement>
					)}
				</FlexDiv>
			</ResponsiveContainer>
		</S.BuisnessPartnerContainerWrap>
	);
};
export default memo(BuisnessPartners);
